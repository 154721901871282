import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { UpdatePhoneNumberInput } from 'types/userMethods';

import { Grid } from '@mui/material';

import { phoneValidator } from './helpers';
import { Container, ModalContent } from './styles';
import {
  DynamicButton,
  TkCodeInput,
  TkIcon,
  TkPhoneInputNumberWithFlag,
  TkTypography,
} from '@components/index';
import { ApiResultsKind } from '@consts/api';
import {
  MIXPANEL_EVENTS,
  useAuth,
  useMixpanel,
  useModal,
  useSnackbar as useTkSnackbar,
} from '@contexts/index';
import { useReCaptchaVerify } from '@hooks/index';
import { useLoadingOvertime } from '@hooks/useLoadingOvertime';
import api from '@services/api';
import { validatePhoneNumberValidationCode } from '@services/userMethods';

const TkPhoneVerification = (): JSX.Element => {
  const { setUserPhoneValidated } = useAuth();
  const { t } = useTranslation();
  const [stage, setStage] = useState(0);
  const { fireTSuccess, fireTError, handleClose } = useTkSnackbar();
  const { resumeModalQueue } = useModal();
  const [error, setError] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const handleReCaptchaVerify = useReCaptchaVerify();
  const [codeStatus, setCodeStatus] = useState({
    error: false,
    success: false,
  });
  const VALIDATE_PHONE_NUMBER_REQUEST_TIME = 1000;

  const updateUserPhoneNumberMutation = useMutation(
    async (requestData: UpdatePhoneNumberInput) => {
      const { data } = await api.post(
        'user/generatePhoneNumberValidationCode',
        {
          phone_number: `+${requestData.phoneNumber}`,
          recaptcha: requestData.recaptcha,
        }
      );

      return data;
    }
  );

  useLoadingOvertime({
    isLoading: updateUserPhoneNumberMutation.isLoading,
    interval: VALIDATE_PHONE_NUMBER_REQUEST_TIME,
    onOvertime() {
      fireTSuccess(
        'validatingPhoneNumberPleaseWait',
        {
          ns: 'phoneVerification',
        },
        VALIDATE_PHONE_NUMBER_REQUEST_TIME
      );
    },
  });

  //
  const { track } = useMixpanel();

  useEffect(() => {
    track(MIXPANEL_EVENTS.PROMPTED_TO_CONFIRM_PHONE_NUMBER);
  }, []);

  useEffect(() => {
    if (error) setError(false);
  }, [phoneNumber]);

  const handleChangeStage = (newStage: number, resend = false) => {
    if (stage === 0 || resend) {
      phoneValidator
        .validate(phoneNumber)
        .then(async (phoneNumber) => {
          const recaptcha = await handleReCaptchaVerify('phoneValidateCode');

          if (!recaptcha) return;

          updateUserPhoneNumberMutation.mutate(
            {
              phoneNumber: phoneNumber,
              recaptcha,
            },
            {
              onSuccess: () => {
                handleClose();
                if (resend) {
                  track(MIXPANEL_EVENTS.CLICK_ON_RESEND_CONFIRMATION_SMS);
                  setCodeStatus({ success: false, error: false });
                  fireTSuccess('codeResentSuccessfully', {
                    ns: 'common',
                  });
                } else {
                  track(MIXPANEL_EVENTS.CLICK_ON_ADD_PHONE_NUMBER);
                }
                setStage(newStage);
              },
              onError: (error: any) => {
                fireTError(error.response?.data?.message, {});
              },
            }
          );
        })
        .catch(() => {
          setError(true);
        });
      return;
    }

    if (newStage === 0) {
      setCodeStatus({ success: false, error: false });
    }

    setStage(newStage);
  };

  const handleVerifyCode = (code: string) => {
    validatePhoneNumberValidationCode({ code: code })
      .then((res) => {
        if (res.kind === ApiResultsKind.OK) {
          track(MIXPANEL_EVENTS.PHONE_NUMBER_CONFIRMED);
          setUserPhoneValidated();
          resumeModalQueue();
          fireTSuccess('phoneVerifiedSuccessfully', {
            ns: 'phoneVerification',
          });
          setCodeStatus({
            success: true,
            error: false,
          });
        }
      })
      .catch(() => {
        setCodeStatus({
          success: false,
          error: true,
        });
      });
  };

  const stages = [
    <>
      <TkTypography fontFamily="Muli" fontWeight="bold" fontSize="24px">
        {t('pleaseEnterYourPhoneNumber', { ns: 'phoneVerification' })}
      </TkTypography>
      <TkTypography fontSize="16px" marginTop="5px" marginBottom="25px">
        {t('weNeedItInCaseYourLoseAccount', { ns: 'phoneVerification' })}
      </TkTypography>
      <Grid container justifyContent="center">
        <TkPhoneInputNumberWithFlag
          /** @ts-ignore */
          hideButton
          mt={2.5}
          isError={error}
          phoneNumber={phoneNumber}
          errorMessagePosition="flex-end"
          setPhoneNumber={setPhoneNumber}
        />
      </Grid>
      <Grid container alignItems="center" marginTop="10px">
        <TkIcon
          className="fa fa-info-circle"
          iconSize={14}
          color="#4952A9"
          marginRight={5}
          verticalAlign="center"
        />
        <TkTypography fontSize="14px" userselect="none">
          {t('wontShareWithAnyone', { ns: 'phoneVerification' })}
        </TkTypography>
      </Grid>
      <Grid container justifyContent="flex-end" marginTop="35px">
        <DynamicButton
          variant="contained"
          bgcolor="primary"
          loading={updateUserPhoneNumberMutation.isLoading}
          onClick={() => handleChangeStage(1)}
          style={{ minWidth: '175px' }}
        >
          {t('addPhoneNumber', { ns: 'phoneVerification' })}
        </DynamicButton>
      </Grid>
    </>,
    <>
      <TkTypography fontFamily="Muli" fontWeight="bold" fontSize="24px">
        {t('verifyPhoneNumber', { ns: 'phoneVerification' })}
      </TkTypography>
      <TkTypography
        fontSize="16px"
        marginTop={1.25}
        style={{ maxWidth: '90%' }}
      >
        <Trans
          i18nKey="weSentYouASmsMessage"
          ns="phoneVerification"
          values={{ number: phoneNumber }}
        >
          <strong />
        </Trans>
      </TkTypography>
      <TkTypography fontSize="16px">
        {t('pleaseEnterBelow', { ns: 'phoneVerification' })}
      </TkTypography>
      <Grid container margin="20px 0" justifyContent="center">
        <TkCodeInput
          checkCode={handleVerifyCode}
          isError={codeStatus.error}
          isSuccess={codeStatus.success}
        />
      </Grid>
      <Grid container alignItems="center">
        <TkTypography fontSize="16px" marginBottom={2.5}>
          <Trans i18nKey="didNotReceiveCodeResend" ns="common">
            <DynamicButton
              color="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => handleChangeStage(1, true)}
            />
          </Trans>
        </TkTypography>
      </Grid>
      <Grid container alignItems="center">
        <TkTypography fontSize="16px">
          <Trans i18nKey="isNotYourNumberChangeIt" ns="common">
            <DynamicButton
              color="primary"
              style={{ fontWeight: 'bold' }}
              onClick={() => {
                track(MIXPANEL_EVENTS.CLICK_ON_CHANGE_NUMBER);
                handleChangeStage(0);
              }}
            />
          </Trans>
        </TkTypography>
      </Grid>
    </>,
  ];

  return (
    <Container>
      <ModalContent style={{ padding: '20px 20px' }}>
        {stages[stage]}
      </ModalContent>
    </Container>
  );
};

export default TkPhoneVerification;
