import styled from 'styled-components';

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  background: #0000004d 0% 0% no-repeat padding-box;
`;
