import styled from 'styled-components';

import {
  DialogActions,
  DialogContent,
  Card,
  Grid as MaterialGrid,
  Button,
} from '@mui/material';

export const Grid = styled(MaterialGrid)`
  display: flex;
`;

export const ColumnGrid = styled(Grid)`
  width: 100%;
  padding: 0 36px;
  flex-direction: column;
`;

export const IntegrationTextLogo = styled.img`
  max-height: 36px;
  align-self: center;
`;

export const TkButton2 = styled.div`
  align-self: center;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  &.MuiButton-contained {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const TkButton = styled(Button)`
  align-self: center;
  border-radius: 4px;
  padding-left: 0;
  padding-right: 0;
  &.MuiButton-contained {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const IntegrationCard = styled(Card)`
  height: 100%;
  border-radius: 7px;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #fff;
  opacity: 1;
  display: flex;
  flex: 1;
  width: 100%;
  height: calc(100vh - 64px);
`;

export const ContentWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-top: 1.5rem;
  margin-right: 420px;
`;

export const IntegrationCardScroll = styled(Grid)`
  overflow-y: scroll;
  padding: 1.5rem;
`;
export const ModalContent = styled(DialogContent)`
  width: 600px;
  padding-bottom: 72px;
`;

export const ModalActions = styled(DialogActions)`
  padding: 16px !important;
`;

export const ModalCalendarItem = styled(Grid)`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
  .MuiCheckbox-root {
    padding: 0;
    margin-right: 8px;
  }
  &.calendarRequiredError .MuiCheckbox-root {
    color: #fd3b2f;
  }
`;

export const ModalCalendarsWrapper = styled(Grid)`
  position: relative;
  min-height: 48px;
`;

export const CalendarItem = styled(Grid)`
  margin-bottom: 16px;
`;

export const CalendarsWrapper = styled(Grid)`
  margin-top: 40px;
  min-height: 128px;
  margin-bottom: 8px;
`;

export const TextModal = styled(Grid)`
  padding: 10px 0 5px 0;
`;

export const SubTextModal = styled(Grid)`
  padding-bottom: 10px;
`;

export const IconModal = styled(Grid)`
  padding: 10px;
`;

export const AllSyncedWarningWrapperModal = styled.div`
  .MuiDialogContent-root {
    width: 400px;
  }
  .MuiButton-contained {
    width: 100%;
  }
`;
