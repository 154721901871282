import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import TkAssigneeListItem from './TkAssigneeListItem';
import TkCrewListItem from './TkCrewListItem';
import {
  TkExtendedWrapperTabs,
  TabCustom,
  TabsCustom,
  SearchBox,
  TkSelectAllItem,
  TkListWrapper,
} from './styles';
import { TkExtendedDrawerSearch } from '@components/ExtendedDrawers';
import TkCheckBox from '@components/TkCheckBox';
import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { Grid } from '@material-ui/core';
import {
  getFilteredCrews,
  getFilteredAssignees,
  searchFilterCrewData,
  searchFilterAssigneeData,
  selectAllFilterCrews,
  selectAllFilterAssignees,
  isAllFilterAssigneesSelected,
  isAllFilterCrewsSelected,
  setUnassignedStatus,
} from '@recoilData/index';

const TkAssigneeList = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [value, setValue] = useState<number>(0);
  const listFilteredAssignee = useRecoilValue(searchFilterAssigneeData(search));
  const listFilteredCrew = useRecoilValue(searchFilterCrewData(search));
  const filterAssignees = useRecoilValue(getFilteredAssignees);
  const filterCrews = useRecoilValue(getFilteredCrews);
  const selectAllAssigneeItems = useSetRecoilState(selectAllFilterAssignees);
  const selectAllCrewItems = useSetRecoilState(selectAllFilterCrews);
  const [isUnassigned, setUnassigned] = useRecoilState(setUnassignedStatus);
  const [selectAllCrew, setSelectAllCrew] = useRecoilState(
    isAllFilterCrewsSelected
  );
  const [selectAllAssignee, setSelectAllAssignee] = useRecoilState(
    isAllFilterAssigneesSelected
  );

  useEffect(() => {
    const res = filterCrews.every((c) => c.checked === true);
    if (res) setSelectAllCrew(true);
  }, [filterCrews]);

  useEffect(() => {
    const res = filterCrews.some((c) => c.checked === false);
    if (res) setSelectAllCrew(false);
  }, [filterCrews]);

  useEffect(() => {
    const res = filterAssignees.every((c) => c.checked === true);
    if (res) setSelectAllAssignee(true);
  }, [filterAssignees]);

  useEffect(() => {
    const res = filterAssignees.some((c) => c.checked === false);
    if (res) setSelectAllAssignee(false);
  }, [filterAssignees]);

  const handleSelectAllCrew = (checked: boolean) => {
    if (isUnassigned && checked) {
      setUnassigned(false);
    }
    selectAllCrewItems(checked);
  };

  const handleSelectAllAssignee = (checked: boolean) => {
    if (isUnassigned && checked) {
      setUnassigned(false);
    }
    selectAllAssigneeItems(checked);
  };

  const handleTabChange = (newValue: number) => {
    setValue(newValue);
    setSearch('');
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <TkExtendedWrapperTabs
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </TkExtendedWrapperTabs>
    );
  };

  return (
    <>
      <TabsCustom
        value={value}
        onChange={(_, number) => handleTabChange(number)}
        aria-label="ant example"
      >
        <TabCustom
          label={
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="bold"
              color={value === 0 ? '#4952A9' : '#011F41'}
            >
              Teammates
            </TkTypography>
          }
        />
        <TabCustom
          label={
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="bold"
              color={value === 1 ? '#4952A9' : '#011F41'}
            >
              Crews
            </TkTypography>
          }
        />
      </TabsCustom>
      <SearchBox container alignItems="flex-end">
        <Grid item>
          <TkIcon className="fa fa-search" color="#4952A9" />
        </Grid>
        <Grid item sm={10}>
          <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
        </Grid>
      </SearchBox>
      {value === 0 ? (
        <TabPanel value={value} index={0}>
          <Grid container>
            <TkSelectAllItem
              checked={selectAllAssignee}
              control={
                <TkCheckBox
                  checked={selectAllAssignee}
                  onChange={(_, checked) => handleSelectAllAssignee(checked)}
                  name="check-alert-assignee"
                />
              }
              label={
                <TkTypography fontFamily="Lato" fontSize="14px">
                  {selectAllAssignee
                    ? t('unselect_all', { ns: 'common' })
                    : t('select_all', { ns: 'common' })}
                </TkTypography>
              }
            />
          </Grid>
          <TkListWrapper>
            {listFilteredAssignee.map((assignee: IAssignee) => (
              <TkAssigneeListItem key={assignee.id} assignee={assignee} />
            ))}
          </TkListWrapper>
        </TabPanel>
      ) : (
        <TabPanel value={value} index={1}>
          <Grid container>
            <TkSelectAllItem
              checked={selectAllCrew}
              control={
                <TkCheckBox
                  checked={selectAllCrew}
                  onChange={(_, checked) => handleSelectAllCrew(checked)}
                  name="check-alert-assignee"
                />
              }
              label={
                <TkTypography fontFamily="Lato" fontSize="14px">
                  {selectAllCrew
                    ? t('unselect_all', { ns: 'common' })
                    : t('select_all', { ns: 'common' })}
                </TkTypography>
              }
            />
          </Grid>
          <TkListWrapper>
            {listFilteredCrew.map((crew: ICrew) => (
              <TkCrewListItem key={crew.id} crew={crew} />
            ))}
          </TkListWrapper>
        </TabPanel>
      )}
    </>
  );
};

export default TkAssigneeList;
