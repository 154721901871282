import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Container, InputContent } from './styles';
import { TkInput, TkIcon } from '@components/index';
import { useSearch } from '@contexts/index';

type TkSearchBarProps = {
  placeholder?: string;
  maxWidth?: string;
};

enum HotKey {
  Enter = 'Enter',
}

const SearchBar = ({
  placeholder,
  maxWidth,
}: TkSearchBarProps): JSX.Element => {
  const { setTerm, term, setTermLoading } = useSearch();
  const [localTerm, setLocalTerm] = useState('');
  const { t } = useTranslation();
  const handleKeyDown = (event) => {
    if (event.key === HotKey.Enter) {
      setTermLoading(true);
      setTerm(event.target.value);
    }
    setLocalTerm(event.target.value);
  };

  const handleClear = () => {
    setLocalTerm('');
    setTerm('');
  };

  useEffect(() => {
    setLocalTerm(term);
  }, [term]);

  return (
    <Container>
      <InputContent style={{ maxWidth }}>
        <div className="Iconclass">
          <TkIcon className="fa fa-search" color="primary" iconSize={14} />
        </div>
        <div className="Inputclass">
          <TkInput
            onKeyPress={(event) => handleKeyDown(event)}
            placeholder={placeholder ?? t('search', { ns: 'common' })}
            onChange={(event) => setLocalTerm(event.target.value)}
            value={localTerm}
            endAdornment={
              localTerm || term ? (
                <TkIcon
                  onClick={handleClear}
                  className="fa fa-times-circle"
                  color="primary"
                />
              ) : null
            }
          />
        </div>
      </InputContent>
    </Container>
  );
};

export default SearchBar;
