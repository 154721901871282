import React from 'react';

import { TkExtendedDrawerWrapper } from './styles';

const TkExtendedDrawer = ({
  children,
  open,
  anchor = 'right',
  variant = 'persistent',
}: TkDrawerProps & { children: React.ReactNode }): JSX.Element => {
  return (
    <TkExtendedDrawerWrapper open={open} anchor={anchor} variant={variant}>
      {children}
    </TkExtendedDrawerWrapper>
  );
};

export default TkExtendedDrawer;
