import { DateTime, DateTimeUnit } from 'luxon';

import { LocalStorage } from '@consts/index';

export const verifyNotificationSetting = () => {
  const today = DateTime.now();
  const flagLocalStorage =
    localStorage.getItem(LocalStorage.SEND_NOTIFICATION) ?? false;
  const setDateLocalStorage =
    localStorage.getItem(LocalStorage.SEND_NOTIFICATION_TIMESTAMP) ?? false;
  const lastSetDate = !setDateLocalStorage
    ? DateTime.fromSeconds(Number(setDateLocalStorage))
    : false;
  const flagNotify = !flagLocalStorage
    ? flagLocalStorage === 'true'
    : flagLocalStorage === 'false';

  if (!lastSetDate || !flagNotify) return false;
  if (lastSetDate.day === today.day && flagNotify) return true;
  return false;
};

export const isTimeLess = (time: Date, flag: DateTimeUnit): boolean => {
  const types = {
    day: DateTime.fromJSDate(time).startOf(flag) < DateTime.now().startOf(flag),
    hour:
      DateTime.fromJSDate(time).startOf(flag) < DateTime.now().startOf(flag),
    minute:
      DateTime.fromJSDate(time).startOf(flag) <= DateTime.now().startOf(flag),
  };
  return types[flag];
};
