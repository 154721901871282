import React from 'react';

import { ThemeProvider } from '@mui/material/styles';

import { themeV5 } from '@styles/theme';

const Mui5StyleProvider: React.FC = ({ children }) => {
  return <ThemeProvider theme={themeV5}>{children}</ThemeProvider>;
};

export default Mui5StyleProvider;
