import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ptBR from './pt-BR';
import en from './en';
import es from './es';
import fr from './fr';

const nameSpaces = [
  'app',
  'availability',
  'blockResponsive',
  'bookingWidget',
  'calendar',
  'chat',
  'common',
  'dates',
  'days',
  'daysOnboarding',
  'demoCall',
  'events',
  'integrationsPage',
  'modalAlert',
  'notifications',
  'offers',
  'phoneVerification',
  'profile',
  'teamsPage',
  'unavailableFeature',
  'webOnboarding',
  'phoneVerification',
  'dashboard',
];

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    lng: 'en',
    ns: nameSpaces,
    defaultNS: 'app',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        ...en,
      },
      'pt-BR': {
        ...ptBR,
      },
      es: {
        ...es,
      },
      fr: {
        ...fr,
      },
    },
  });

export default i18n;
