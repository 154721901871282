import { SetterOrUpdater } from 'recoil';

import api from './api';
import {
  setDefaultCrews,
  setDefaultCustomers,
  setDefaultAssignees,
  setDefaultLocations,
  setDefaultChecklists,
  setDefaultInvitations,
} from '@helpers/apiData';

const loadCustomerApiData = async (
  accountId: number,
  setCustomers: SetterOrUpdater<ICustomerTask[]>
) => {
  if (accountId) {
    await api
      .get(`account/${accountId}/customer`)
      .then(({ data }) => setCustomers(setDefaultCustomers(data)));
  }
};

const loadAssigneeApiData = async (
  accountId: number,
  setAssignees?: SetterOrUpdater<IAssignee[]>,
  term?: string
) =>
  await api
    .get(`account/${accountId}/member`, {
      params: {
        search_term: term,
      },
    })
    .then(({ data }) => {
      const defaultMembers = setDefaultAssignees(data);

      if (setAssignees) setAssignees(defaultMembers);

      return defaultMembers;
    })
    .catch(() => Promise.reject([]));

const loadInvitationApiData = async (
  accountId: number,
  setInvitations: SetterOrUpdater<IUserInvite[]>
) => {
  if (accountId) {
    await api
      .get(`account/${accountId}/pendingInvites`)
      .then(({ data }) => setInvitations(setDefaultInvitations(data)));
  }
};

const loadLocationsApiData = async (
  accountId: number,
  setLocations: SetterOrUpdater<ILocationTask[]>
) => {
  if (accountId) {
    await api
      .get(`account/${accountId}/locations`)
      .then(({ data }) => setLocations(setDefaultLocations(data)));
  }
};

const loadCrewsApiData = async (
  accountId: number,
  setCrews: SetterOrUpdater<ICrew[]>,
  term?: string
) => {
  if (accountId) {
    await api
      .get(`account/${accountId}/crew`, {
        params: {
          search_term: term,
        },
      })
      .then(({ data }) => setCrews(setDefaultCrews(data)));
  }
};

const loadChecklistsApiData = async (
  accountId: number,
  setChecklists: SetterOrUpdater<IChecklistTask[]>
) => {
  if (accountId) {
    await api
      .get(`account/${accountId}/checklists`)
      .then(({ data }) => setChecklists(setDefaultChecklists(data)));
  }
};

export {
  loadAssigneeApiData,
  loadChecklistsApiData,
  loadCrewsApiData,
  loadCustomerApiData,
  loadInvitationApiData,
  loadLocationsApiData,
};
