import { atom } from 'recoil';

import { Drawer, DrawerOptions } from '@hooks/drawer/drawer.types';

const drawersData = atom<Drawers>({
  key: 'drawersData',
  default: {
    editTask: false,
    newTask: false,
    offer: false,
    filter: false,
    listOffers: false,
  },
});

const mainDrawerState = atom<Drawer | null>({
  key: 'mainDrawerState',
  default: null,
});

const extendedDrawerState = atom<Drawer | null>({
  key: 'extendedDrawerState',
  default: null,
});

const drawerOptionsState = atom<DrawerOptions | null>({
  key: 'drawerOptionsState',
  default: null,
});

export {
  drawersData,
  mainDrawerState,
  extendedDrawerState,
  drawerOptionsState,
};
