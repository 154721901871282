import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { TkModalAlert } from '@components/index';
import {
  LocalStorage,
  TaskbirdIntegrationsEnum,
  TypeEditionTask,
} from '@consts/index';
import { useModal } from '@contexts/index';
import {
  DeleteTaskModal,
  EditTaskCustomerModal,
} from '@features/editTask/components/EditTaskModal';
import { fetchUserSettings } from '@services/userMethods';

interface TaskOperation {
  type: 'drop' | 'customerVisibility' | 'deleteTask' | 'editTaskToBefore';
}

export const useEditTaskValidation = () => {
  const history = useHistory();
  const { setupModal, handleCloseModal } = useModal();
  const handleRedirectToTeamSettings = () => {
    history.push('/settings/team');
  };
  const contentStyle = {
    width: '388px',
    height: '212px',
  };
  const okButtonStyle = {
    width: '142px',
    height: '36px',
  };
  const dontShowModal = localStorage.getItem(
    LocalStorage.DONT_SHOW_CUSTOMER_MODAL_AGAIN
  );
  const lastCustomerModalShown = localStorage.getItem(
    LocalStorage.LAST_CUSTOMER_MODAL_SHOWN
  );
  const [showCustomerModal, setShowCustomerModal] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const {
        settings: { show_customer_modal },
      } = await fetchUserSettings();
      setShowCustomerModal(show_customer_modal);
    };
    fetchSettings();
  }, []);

  const handleEditTask = (
    task = null,
    type: TaskOperation['type'],
    callback = null
  ) => {
    if (type === TypeEditionTask.DROP) {
      const newStartDate = Date.parse(task.event.start) / 1000;
      const importedStartDate =
        task.event.extendedProps.original_start_time_epoch;
      const isBeforeThanImportedDate = newStartDate < importedStartDate;
      setupModal({
        content: (
          <TkModalAlert
            handleCloseModal={handleCloseModal}
            type="taskTime"
            contentStyle={contentStyle}
            okButtonStyle={okButtonStyle}
          />
        ),
      });
      return isBeforeThanImportedDate;
    }
    if (type === TypeEditionTask.CUSTOMER_VISIBILITY) {
      //if lastCustomerModalShown as date is more than 1 day ago
      const today = new Date();
      const lastCustomerModalShownDate = new Date(lastCustomerModalShown);
      const diffTime = Math.abs(
        today.getTime() - lastCustomerModalShownDate.getTime()
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (
        dontShowModal === 'true' ||
        showCustomerModal === false ||
        diffDays <= 1
      ) {
        callback && callback();
        return;
      }
      if (diffDays > 1) {
        setupModal({
          content: (
            <EditTaskCustomerModal
              callback={callback}
              handleCloseModal={handleCloseModal}
              handleRedirectToTeamSettings={handleRedirectToTeamSettings}
            />
          ),
        });
      }
    }
    if (type === TypeEditionTask.EDIT_TASK_TO_BEFORE) {
      setupModal({
        content: (
          <TkModalAlert
            handleCloseModal={handleCloseModal}
            type="taskTime"
            contentStyle={contentStyle}
            okButtonStyle={okButtonStyle}
          />
        ),
      });
    }
    if (type === TypeEditionTask.DELETE_TASK) {
      if (task.platform === TaskbirdIntegrationsEnum.TURNOVERBNB)
        setupModal({
          content: <DeleteTaskModal handleCloseModal={handleCloseModal} />,
        });
      else callback && callback();
    }
  };

  return {
    handleEditTask,
  };
};
