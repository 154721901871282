import styled from 'styled-components';

import {
  PaletteProps, //eslint-disable-line
} from '@material-ui/system';

interface LogoContent {
  onClick?: () => void;
  width?: string;
}

export const Container = styled.div<LogoContent & PaletteProps>`
  display: flex;
  color: #011f41;
  align-items: center;
  width: ${(props) => (props.width ? props.width : '12%')};
  background: ${(props) => (props.color ? props.color : '#f5f6fb')};
  cursor: pointer;

  > img {
    min-height: 35px;
  }
`;

export const IconContent = styled.div<PaletteProps>`
  width: 50px;
  min-width: 30px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;

export const LogoContent = styled.div`
  width: 90%;
  padding: 0px;
  display: flex;
  max-width: 139px;
  align-items: center;

  > img {
    width: 100%;
  }
`;
