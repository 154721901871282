import React from 'react';
import { Grid } from '@mui/material';
import { TkTypography } from '@components/index';
import { useTranslation } from 'react-i18next';
import {
  SelectableOptionWrapper,
  SelectableOptionIconWrapper,
  SelectableOptionTextWrapper,
} from '../TkLogin/TkWelcomeSetupModal/styles';
import { IOptionsSelect } from './types';

interface ISelectableOption {
  option: any;
  selectedOption: IOptionsSelect;
}

const SelectableOption: React.FC<ISelectableOption> = ({
  option,
  selectedOption,
}) => {
  const { t } = useTranslation();

  if (option === undefined) {
    return <></>;
  }

  return (
    <SelectableOptionWrapper
      onClick={() => option.handleClick(option.id)}
      itemID={option.id === selectedOption ? 'selected' : ''}
    >
      <SelectableOptionIconWrapper>
        <img src={option.icon} alt={option.id} />
      </SelectableOptionIconWrapper>
      <SelectableOptionTextWrapper direction={'column'}>
        <Grid container>
          <TkTypography
            marginBottom={'10px'}
            fontFamily={'Muli'}
            fontSize={20}
            fontWeight={'bold'}
          >
            {t(option.textToken)}
          </TkTypography>
        </Grid>
        <Grid container>
          <TkTypography fontFamily={'Lato'} fontSize={16}>
            {t(option.subTextToken)}
          </TkTypography>
        </Grid>
      </SelectableOptionTextWrapper>
    </SelectableOptionWrapper>
  );
};

export default SelectableOption;
