import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Deleted, Moveout, TurnoverBNB, BookingWidget } from './ContentRenders';
import TkExtendedOfferDetailsDrawer from './TkExtendedOffersDetailsDrawer';
import { TkOffersDetailsWrapper, TkTaskActions } from './styles';
import { DynamicButton, TkBaseDrawer, TkTypography } from '@components/index';
import { TaskbirdIntegrationsEnum } from '@consts/index';
import { ProjectOfferRequestAction } from '@features/projectOffers/components/projectOffersList';
import {
  LocationAction,
  useAccountProjectOffers,
  useProjectOfferMutation,
} from '@features/projectOffers/hooks';
import { useOfferDrawer } from '@pages/Home/hooks';
import {
  selectedOffer,
  selectedOfferId,
  offersDetailsExtendedDrawer,
  editEventExtendedDrawer,
} from '@recoilData/index';

const TkOfferDetailsDrawer = ({ drawers }: ITkDrawerFunctions): JSX.Element => {
  const { t } = useTranslation();
  const offer = useRecoilValue(selectedOffer);
  const { openProjectOfferList, closeProjectOfferList } = useOfferDrawer();
  const resetOfferId = useResetRecoilState(selectedOfferId);
  const [extendedDrawer, setExtendedDrawer] = useRecoilState(
    offersDetailsExtendedDrawer
  );
  const [extendedObject, setExtendedObject] = useRecoilState(
    editEventExtendedDrawer
  );
  const { projectOffers } = useAccountProjectOffers();
  const projectOfferRequestsCount = projectOffers
    ? Object.entries(projectOffers)
        ?.map((project) => project[1].map((p) => p.id))
        .flat().length
    : 0;

  const { handleProjectAction, isMutating } = useProjectOfferMutation();

  const handleBack = () => {
    resetOfferId();
    setExtendedDrawer({
      ...extendedDrawer,
      open: false,
    });
    setExtendedObject({
      ...extendedObject,
      open: false,
    });

    if (projectOfferRequestsCount > 1) {
      openProjectOfferList();
      return;
    }

    closeProjectOfferList();
  };

  const handleClose = () => closeProjectOfferList();

  const contentDispatcherHandler = () => {
    const contentRenders = {
      moveout: (
        <Moveout
          offer={offer}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      ),
      turnoverbnb: (
        <TurnoverBNB
          offer={offer}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      ),
      deleted: <Deleted offer={offer} handleClose={handleClose} />,
      bookingWidget: (
        <BookingWidget
          offer={offer}
          handleBack={handleBack}
          handleClose={handleClose}
        />
      ),
    };
    const platforms = [
      TaskbirdIntegrationsEnum.MOVEOUT,
      TaskbirdIntegrationsEnum.TURNOVERBNB,
      TaskbirdIntegrationsEnum.BOOKING_WIDGET,
    ];
    const { platform } = offer;
    if (!platforms.includes(platform)) {
      return null;
    }
    return contentRenders[platform];
  };

  return (
    <>
      <TkBaseDrawer open={drawers.offer ?? false}>
        <TkOffersDetailsWrapper>
          {contentDispatcherHandler()}
        </TkOffersDetailsWrapper>

        {!isMutating && (
          <TkTaskActions>
            <DynamicButton
              variant={isMutating ? 'contained' : 'outlined'}
              disabled={isMutating}
              onClick={() => {
                handleProjectAction(
                  offer.id,
                  ProjectOfferRequestAction.Reject,
                  false,
                  LocationAction.offerDetail
                );
              }}
              color="babyBlue"
            >
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                {t('reject', { ns: 'common' })}
              </TkTypography>
            </DynamicButton>
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              disabled={isMutating}
              onClick={() =>
                handleProjectAction(
                  offer.id,
                  ProjectOfferRequestAction.Accept,
                  true
                )
              }
            >
              <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
                {t('accept', { ns: 'common' })}
              </TkTypography>
            </DynamicButton>
          </TkTaskActions>
        )}
      </TkBaseDrawer>
      <TkExtendedOfferDetailsDrawer />
    </>
  );
};

export default TkOfferDetailsDrawer;
