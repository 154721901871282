import React from 'react';
import AppleSignIn from 'react-apple-signin-auth';
import { AppleButton } from '@components/TkAuthButtons';
import { useAuth } from '@contexts/AuthContext/AuthProvider';
import { APPLE_APP_ID, APPLE_REDIRECT_URL } from '@consts/index';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import TkSocialLoginModal from '@components/TkLogin/TkSocialLoginModal';
import { useModal } from '@contexts/ModalContext/ModalProvider';
import { useTranslation } from 'react-i18next';
import { IExternalLoginEnum } from '@contexts/AuthContext/AuthContext';

const AppleLoginButton = (): JSX.Element => {
  const { t } = useTranslation();
  const { socialMediaSignUp } = useAuth();
  const { addAlert } = useSnackbar();

  const { setupModal } = useModal();

  const fireAlert = () => {
    addAlert({
      message: t('login.socialLoginError', { social: 'Apple' }),
      severity: 'error',
    });
  };

  const handleAppleLogin = async (response: any) => {
    try {
      if (response.authorization) {
        const userData = {
          socialMediaId: response.authorization.code,
          socialMediaProvider: IExternalLoginEnum.APPLE,
          id_token: response.authorization.id_token,
        };

        const res = await socialMediaSignUp(userData);

        if (!res.success && res.errorMessage === 'accountNotFund') {
          setupModal({
            content: (
              <TkSocialLoginModal socialPlatform={IExternalLoginEnum.APPLE} />
            ),
          });
        }
      } else {
        fireAlert();
      }
    } catch (e: any) {
      console.log(e);
      fireAlert();
    }
  };

  return (
    <AppleSignIn
      authOptions={{
        clientId: APPLE_APP_ID,
        response_mode: 'form_post',
        scope: 'email name',
        redirectURI: APPLE_REDIRECT_URL,
        usePopup: true,
      }}
      onSuccess={(response) => handleAppleLogin(response)}
      onError={(error) => console.error(error)}
      render={(renderProps) => (
        <div onClick={renderProps.onClick}>
          <AppleButton />
        </div>
      )}
    />
  );
};

export default AppleLoginButton;
