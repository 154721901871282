import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FormControlLabel, Grid } from '@mui/material';

import {
  ModalContent,
  ModalActions,
  TextModal,
  SubTextModal,
} from '../Drawers/TkEditTaskDrawer/styles';
import { DynamicButton, TkTypography, TkCheckBox } from '@components/index';
import { LocalStorage } from '@consts/index';
import { generateListAssigneeString, getDateToEpoch } from '@helpers/index';

interface TkAssigneeAlertModalProps {
  handleCloseModal: (flagNotify: boolean) => void;
  eventTitle: string;
  assignees: IAssignee[];
}

const TkAssigneeAlertModal = ({
  eventTitle,
  assignees,
  handleCloseModal,
}: TkAssigneeAlertModalProps): JSX.Element => {
  const [checked, setChecked] = useState<boolean>(false);
  const { t } = useTranslation();

  const getNamesAssignee = (assignees: IAssignee[] | undefined) => {
    if (assignees !== undefined) {
      const len = assignees.length;
      const names = assignees.map((i) => i.full_name);
      return generateListAssigneeString(names, len);
    } else {
      return '';
    }
  };

  const handleCheckChange = () => {
    setChecked((prevState) => !prevState);
    localStorage.setItem(LocalStorage.SEND_NOTIFICATION, (!checked).toString());
    localStorage.setItem(
      LocalStorage.SEND_NOTIFICATION_TIMESTAMP,
      getDateToEpoch(new Date()).toString()
    );
  };

  return (
    <>
      <ModalContent>
        <div>
          <TextModal
            container //@ts-ignore
            justifyContent="center"
            alignItems="center"
            color="default"
          >
            <TkTypography
              fontFamily="Muli"
              fontWeight="bold"
              fontSize={24}
              color="#011F41"
            >
              {t('alerts.sendNotifications', {
                ns: 'modalAlert',
              })}
            </TkTypography>
          </TextModal>
          <SubTextModal container alignItems="center">
            <Grid container alignItems="center">
              <TkTypography
                fontFamily="Lato"
                fontWeight="normal"
                fontSize={16}
                color="#011F41"
                marginTop="15px"
                marginBottom="10px"
              >
                {t('alerts.doYouWantToNotify', {
                  ns: 'modalAlert',
                })}{' '}
                <strong>{getNamesAssignee(assignees)}</strong>{' '}
                {t('about', {
                  ns: 'common',
                })}{' '}
                <strong>{eventTitle}</strong>?
              </TkTypography>
            </Grid>
            <Grid container alignItems="center">
              <FormControlLabel
                control={
                  <TkCheckBox
                    checked={checked}
                    onChange={handleCheckChange}
                    name="check-alert-assignee"
                  />
                }
                label={t('alerts.doNotShowAgainAndSendAllNotifications', {
                  ns: 'modalAlert',
                })}
              />
            </Grid>
          </SubTextModal>
        </div>
      </ModalContent>
      <ModalActions>
        <Grid container>
          <Grid item xs={6}>
            <DynamicButton
              color="primary"
              onClick={() => handleCloseModal(false)}
            >
              {t('dontSend', {
                ns: 'common',
              })}
            </DynamicButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              onClick={() => handleCloseModal(true)}
              disableElevation
            >
              {t('send', {
                ns: 'common',
              })}
            </DynamicButton>
          </Grid>
        </Grid>
      </ModalActions>
    </>
  );
};

export default TkAssigneeAlertModal;
