import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const TkGoogleReCaptchaContext: React.FC = ({ children }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PROD_SITE_KEY ?? ''}
      language="en"
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default TkGoogleReCaptchaContext;
