import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';
import { AppLocationURLs } from '@consts/appUrls';

const HomePage = lazy(() => import('@pages/Home'));

const CalendarRoutes = [
  {
    path: AppLocationURLs.CALENDAR,
    component: withAuth(HomePage),
  },
];

export default CalendarRoutes;
