import { useEffect, useState } from 'react';

import { StreamChat, TokenOrProvider, User } from 'stream-chat';

import { CHAT_API_KEY } from '@consts/index';
import { useAuth } from '@contexts/index';
import { isChatEnabled } from '@helpers/environment';

const apiKey = process.env.REACT_APP_CHAT_API_KEY ?? CHAT_API_KEY;

export type UseClientOptions = {
  user: User;
  tokenOrProvider: TokenOrProvider;
};

export const useClient = ({
  user,
  tokenOrProvider,
}: UseClientOptions): StreamChat | undefined => {
  const [chatClient, setChatClient] = useState<StreamChat | undefined>(
    undefined
  );
  const { signed } = useAuth();

  useEffect(() => {
    let didUserConnectInterrupt = false;
    const initChat = async () => {
      const client = new StreamChat(apiKey);
      await client.connectUser(user, tokenOrProvider).then(() => {
        if (!didUserConnectInterrupt) {
          setChatClient(client);
        }
      });
    };

    if (isChatEnabled && signed) {
      initChat().catch((error) => {
        console.log('[Chat] Error connecting', error);
      });
    }

    return () => {
      didUserConnectInterrupt = true;
      chatClient?.disconnectUser();
      setChatClient(undefined);
    };
  }, [user.id, signed, tokenOrProvider]);

  return chatClient;
};
