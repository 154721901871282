import React from 'react';

import { useTranslation } from 'react-i18next';
import { ProjectOffer } from 'types/projectOffer.type';

import { Grid } from '@mui/material';

import { TkTaskWrapperItem } from '../styles';
import { AdditionalInfoItem } from '@components/Drawers/TkEditTaskDrawer/ExtendedDrawers/TkDrawerTurnoAdditionalInfo/AdditionalInfoDrawer';
import { TkIcon, TkTypography } from '@components/index';
import { getOfferAdditionalItems } from '@helpers/offers';

export const TkDrawerTurnoAdditionalInfo = ({
  offer,
}: {
  offer: ProjectOffer | IEvent | undefined;
}): JSX.Element => {
  const { t } = useTranslation();

  const { firstSection, restItemsCount } = getOfferAdditionalItems(
    offer,
    false
  );

  return (
    <>
      <TkTaskWrapperItem>
        <Grid container direction="row">
          <TkIcon
            className="fa fa-hashtag"
            iconSize={14}
            width={13}
            marginTop={7}
            marginRight={7}
          />
          <TkTypography fontSize={16} fontFamily="Lato">
            {t('additionalInfo', { ns: 'offers' })}
          </TkTypography>
        </Grid>
      </TkTaskWrapperItem>
      <TkTaskWrapperItem marginTop="5px">
        <Grid container justifyContent="space-between">
          {firstSection.map((item, index) => {
            return (
              <Grid display="flex" key={index} item>
                <AdditionalInfoItem offer={offer} item={item} />
              </Grid>
            );
          })}
          {restItemsCount > 0 && (
            <Grid justifyContent="flex-end">
              <TkTypography
                fontSize={16}
                fontFamily="Lato"
                marginLeft="5px"
                fontWeight="bold"
              >
                {t('moreXQuestions', {
                  ns: 'offers',
                  count: restItemsCount,
                })}
              </TkTypography>
            </Grid>
          )}
        </Grid>
      </TkTaskWrapperItem>
    </>
  );
};
