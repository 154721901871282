import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { accountSchema } from '@pages/Profile/schema';
import { getUserAccountId } from '@recoilData/index';
import api from '@services/api';

export const useGetAccountSettings = () => {
  const accountId = useRecoilValue(getUserAccountId);
  return useQuery(['accountSettings', accountId], async () => {
    const { data } = await api.get(`/account/${accountId}`);

    return accountSchema.parse(data.account);
  });
};
