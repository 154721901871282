import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { TkModalAlert } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { useModal } from '@contexts/index';
import {
  event as eventRecoil,
  isEventEdit,
  editEventEnd,
  editEventStart,
  isEventDeleted,
} from '@recoilData/index';

export const useStatusAndTimeDisplay = ({
  allDayEnd,
  allDayStart,
  setAllDayEnd,
  setAllDayStart,
}) => {
  const { t } = useTranslation();
  const { setupModal, handleCloseModal } = useModal();
  const [hourView, setHourView] = useState<boolean>(false);
  const [isRepeat, setIsRepeat] = useState<boolean>(false);

  const [alertTrigger, setAlertTrigger] = useState({
    setUpAlert: false,
    alertTriggered: false,
  });

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const [end, setEnd] = useRecoilState(editEventEnd);
  const [start, setStart] = useRecoilState(editEventStart);
  const setEdited = useSetRecoilState(isEventEdit);
  const setDeleted = useSetRecoilState(isEventDeleted);
  const importedStartDate = event?.systemNotes?.original_start_time_epoch;
  const minDate = new Date(importedStartDate * 1000);

  const statusIsToDoOrInProgress =
    event.status === TaskStatusEnum.DONE ||
    event.status === TaskStatusEnum.IN_PROGRESS;

  useEffect(() => {
    setAllDayStart(event.allDay);
    setAllDayEnd(event.allDay);
    setDeleted(false);
    setStart(event.start);
    setEnd(event.end);
    setEdited(false);

    if (event.externalId !== null) {
      setAlertTrigger({ alertTriggered: false, setUpAlert: true });
    }

    setHourView(event.isRecurring);
    setIsRepeat(event.isRecurring);
  }, [event]);

  const triggerAlert = () => {
    if (alertTrigger.setUpAlert && !alertTrigger.alertTriggered) {
      setAlertTrigger({ alertTriggered: true, setUpAlert: true });

      const contentStyle = {
        width: '388px',
        height: '212px',
      };
      const okButtonStyle = {
        width: '142px',
        height: '36px',
      };
      setupModal({
        content: (
          <TkModalAlert
            handleCloseModal={handleCloseModal}
            type="wontReflectOnTurnoverBNB"
            contentStyle={contentStyle}
            okButtonStyle={okButtonStyle}
          />
        ),
      });
    }
  };

  const taskStatusIndicator = (
    status: string,
    type: 'start' | 'end'
  ): string => {
    if (type === 'start') {
      if (status === TaskStatusEnum.IN_PROGRESS)
        return t('status', { ns: 'common' });

      if (status === TaskStatusEnum.DONE) return t('started', { ns: 'common' });

      if (status === TaskStatusEnum.TODO)
        return t('start_by', { ns: 'common' });
    }

    if (status === TaskStatusEnum.DONE) return t('finished', { ns: 'common' });

    return t('finish_by', { ns: 'common' });
  };

  const handleSetStart = (newDate: Date) => {
    if (allDayStart) setAllDayStart(false);
    setStart(newDate);
  };

  const handleSetEnd = (newDate: Date) => {
    if (allDayEnd) setAllDayEnd(false);
    setEnd(newDate);
  };

  return {
    t,
    end,
    event,
    start,
    hourView,
    isRepeat,
    statusIsToDoOrInProgress,
    minDate,
    triggerAlert,
    handleSetEnd,
    handleSetStart,
    taskStatusIndicator,
  };
};
