import styled from 'styled-components';

import { Grid, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import DynamicButton from '@components/DynamicButton';

interface IContainer {
  width: string;
  justify?: string;
}

export const SettingsActions = styled(DialogActions)`
  width: 100%;
  &.MuiDialogActions-spacing {
    padding: 10px 35px 10px 23px;
  }
`;

export const BookingModalIllustration = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(315deg, #4952a9 0%, #647eed 100%) 0% 0% no-repeat padding-box;
`;

export const BookingModalSection = styled.div`
  height: 300px;
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
`;

export const ButtonContent = styled.div<IContainer>`
  width: ${(props) => props.width};
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
`;

export const BookingModalContent = styled(DialogContent)`
  &.MuiDialogContent-root {
    width: 800px;
    height: 380px;
    max-height: 380px;
    max-width: 800px;
    border: none;
    overflow-y: hidden;
    display: flex;
  }
  &.MuiDialogContent-dividers {
    padding: 0;
  }
  &.MuiDialog-paper {
    overflow-y: none;
  }
`;
export const SettingsTitle = styled(DialogTitle)`
  text-align: center;
  color: #3d4465;
`;

export const BookingModalTitleStart = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BookingStated = styled.div`
  width: 100%;
  height: 100vh;
  background-color: red;
`;

export const BookingModalContentStart = styled.ul`
  margin-left: 15px;
  margin-top: 25px;

  li {
    margin-top: 15px;
  }
`;

export const ContentChevron = styled.div`
  display: flex;
`;

export const ModalBookingButtons = styled(DynamicButton)`
  :hover {
    background: none;
  }
`;
