import { useQuery } from 'react-query';
import {
  IBookingAdditionalInfo,
  IBookingServiceDetails,
  ProjectOffer,
} from 'types/projectOffer.type';

import { TaskbirdIntegrationsEnum } from '@consts/integrations';
import camelize from '@helpers/camelize';
import api from '@services/api';

function getProjectOfferServiceDetails(
  accountId: number,
  projectOfferId: number
) {
  return api
    .get(
      `/projectOffer/${projectOfferId}/account/${accountId}/get-service-details`
    )
    .then((response) => response.data.data as IBookingServiceDetails);
}

function getProjectOfferAdditionalInfo(
  accountId: number,
  projectOfferId: number
) {
  return api
    .get(
      `/projectOffer/${projectOfferId}/account/${accountId}/get-additional-info`
    )
    .then((response) => camelize<IBookingAdditionalInfo>(response.data));
}

export function useProjectOfferServiceDetails(
  accountId: number,
  offer: ProjectOffer
) {
  return useQuery(
    ['getProjectOfferServiceDetails', accountId, offer.id],
    () => getProjectOfferServiceDetails(accountId, offer.id),
    {
      enabled: offer.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET,
      placeholderData: {
        base_price: 0,
        duration_in_minutes: 0,
        name: '',
        service_attributes: [],
        total_estimated_price: 0,
      },
    }
  );
}

export function useProjectOfferAdditionalInfo(
  accountId: number,
  offer: ProjectOffer
) {
  return useQuery(
    ['getProjectOfferAdditionalInfo', accountId, offer.id],
    () => getProjectOfferAdditionalInfo(accountId, offer.id),
    {
      enabled: offer.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET,
      placeholderData: {
        data: [],
      },
    }
  );
}
