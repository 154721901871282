import api from './api';
import { ApiResultsKind } from '@consts/api';

const fetchNotifications = ({
  size,
}: GetUserNotificationsInput): Promise<GetUserNotificationsResult> =>
  api
    .get(`notifications?size=${size}`)
    .then((response) => ({
      kind: ApiResultsKind.OK,
      notifications: response.data,
    }))
    .catch(() =>
      Promise.reject({ kind: ApiResultsKind.ERROR, notifications: [] })
    );

const markNotificationAsRead = ({
  id,
}: MarkNotificationAsReadInput): Promise<MarkNotificationAsReadResult> =>
  api
    .put('notifications/markAsRead', {
      id,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const markAllNotificationsAsRead =
  (): Promise<MarkAllNotificationsAsReadResult> =>
    api
      .put('notifications/markAllAsRead', {})
      .then(() => ({
        kind: ApiResultsKind.OK,
      }))
      .catch((e) => Promise.reject({ kind: ApiResultsKind.OK }));

export {
  markAllNotificationsAsRead,
  markNotificationAsRead,
  fetchNotifications,
};
