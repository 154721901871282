import React, { useState } from "react";

import {
  Container,
  SwipeableDrawer,
  useTheme,
  useMediaQuery,
  Modal,
} from "@material-ui/core";
import {
  AppStoreWhiteLogo,
  PlayStoreWhiteLogo,
  TaskbirdLogo,
  TaskbirdWhiteLogo,
} from "@assets/index";

import { TkIcon } from "..";
import TkFooter from "../TkFooter";
import SendMeTheAppModal from "./SendMeTheAppModal";

import {
  TkIconButton,
  TkToolbar,
  TkAppBar,
  TkContainer,
  TkLogo,
  TkStoreBadge,
  TkAppBarButtonsContainer,
  TkContentContainer,
  TkResponsiveTypography,
  TkResponsiveButton,
  MobileMenuContainer,
  MobileMenuOptionList,
  BlueGradient,
  AppStoresContainer,
  AppRootContainer,
} from "./styles";

import {
  APP_STORE_LINK,
  PLAY_STORE_LINK,
  MARKETING_SITE_BASE_URI,
} from "@consts/index";

import TkTypography from "../TkTypography";

const redirectTo = (url: string) => window.location.assign(url);

type HeaderProps = {
  logo: string;
};

type StoreLinkProps = {
  link: string;
  srcImage: string;
};

const Header: React.FC<HeaderProps> = ({ logo, children }) => {
  return (
    <Container>
      <TkToolbar>
        <TkLogo
          src={logo}
          onClick={() => redirectTo(MARKETING_SITE_BASE_URI)}
        />
        <TkAppBarButtonsContainer>{children}</TkAppBarButtonsContainer>
      </TkToolbar>
    </Container>
  );
};

const MobileMenu: React.FC = ({ children }) => {
  const [isShowingDrawer, setIsShowingDrawer] = useState(false);

  const openDrawer = () => setIsShowingDrawer(true);
  const closeDrawer = () => setIsShowingDrawer(false);

  return (
    <>
      <TkIconButton onClick={openDrawer}>
        <TkIcon className="fa fa-bars" color="navyBlue" iconSize={22} />
      </TkIconButton>
      <SwipeableDrawer
        anchor="top"
        disableBackdropTransition
        open={isShowingDrawer}
        onOpen={openDrawer}
        onClose={closeDrawer}
      >
        <MobileMenuContainer>
          <Header logo={TaskbirdWhiteLogo}>
            <TkIconButton onClick={closeDrawer}>
              <TkIcon className="fal fa-times" color="white" iconSize={22} />
            </TkIconButton>
          </Header>
          <MobileMenuOptionList>{children}</MobileMenuOptionList>
        </MobileMenuContainer>
      </SwipeableDrawer>
    </>
  );
};

const StoreLink: React.FC<StoreLinkProps> = ({ srcImage, link }) => (
  <a href={link}>
    <TkStoreBadge src={srcImage} />
  </a>
);

const AppBarButtons: React.FC = () => {
  const [isSendMeTheAppModalOn, setIsSendMeTheAppModalOn] = useState<boolean>(
    false
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const blogButtonColor = isSmallScreen ? "white" : "black";

  return (
    <>
      <TkResponsiveButton
        pl={0}
        variant="text"
        onClick={() => redirectTo(`${MARKETING_SITE_BASE_URI}/blog/`)}
      >
        <TkResponsiveTypography
          variant="body2"
          fontWeight="bold"
          color={blogButtonColor}
        >
          Blog
        </TkResponsiveTypography>
      </TkResponsiveButton>
      {!isSmallScreen && (
        <TkResponsiveButton
          color="white"
          bgcolor="navyBlue"
          variant="contained"
          style={{ marginRight: 22 }}
          onClick={() => redirectTo("/")}
        >
          <TkTypography fontWeight="bold" variant="body2">
            Log in
          </TkTypography>
        </TkResponsiveButton>
      )}
      {isSmallScreen ? (
        <>
          <TkResponsiveButton variant="text" pl={0} disabled>
            <TkResponsiveTypography
              fontWeight="bold"
              variant="body2"
              color="white"
            >
              Get the App
            </TkResponsiveTypography>
          </TkResponsiveButton>
          <AppStoresContainer>
            <StoreLink link={APP_STORE_LINK} srcImage={AppStoreWhiteLogo} />
            <StoreLink link={PLAY_STORE_LINK} srcImage={PlayStoreWhiteLogo} />
          </AppStoresContainer>
        </>
      ) : (
        <TkResponsiveButton
          bgcolor="navyBlue"
          color="white"
          variant="contained"
          onClick={() => setIsSendMeTheAppModalOn(true)}
        >
          <TkTypography fontWeight="bold" variant="body2">
            Text me the App
          </TkTypography>
        </TkResponsiveButton>
      )}
      <Modal
        open={isSendMeTheAppModalOn}
        onClose={() => setIsSendMeTheAppModalOn(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: 10,
        }}
      >
        <SendMeTheAppModal onClose={() => setIsSendMeTheAppModalOn(false)} />
      </Modal>
    </>
  );
};

const PublicPageContainer: React.FC = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <AppRootContainer>
      <TkAppBar position="static">
        <Header logo={TaskbirdLogo}>
          {isSmallScreen ? (
            <MobileMenu>
              <AppBarButtons />
            </MobileMenu>
          ) : (
            <AppBarButtons />
          )}
        </Header>
      </TkAppBar>
      <BlueGradient />
      <TkContainer>
        <TkContentContainer elevation={0}>{children}</TkContentContainer>
      </TkContainer>
      <TkFooter />
    </AppRootContainer>
  );
};

export default PublicPageContainer;
