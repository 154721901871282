import React from 'react';

import { useTranslation } from 'react-i18next';
import { ProjectOffer } from 'types/projectOffer.type';

import { OfferDrawerTags } from '../MicroComponents';
import { TkTaskWrapperItem, HorizontalDivider } from '../styles';
import {
  TkDrawerHeader,
  TkDrawerTaskName,
  TkDrawerRequestedTime,
  TkDrawerCustomWarning,
  TkDrawerCustomerName,
} from '@components/index';

interface ContentRendersDeletedProps {
  offer: ProjectOffer;
  handleClose: () => void;
}

const Deleted = ({ handleClose, offer }: ContentRendersDeletedProps) => {
  const { t } = useTranslation();

  return (
    <>
      <TkDrawerHeader
        id={offer.id}
        handlers={{
          handleClose,
        }}
        dismissIcon="fa fa-times"
        showBackButton
      />

      <TkDrawerTaskName
        name={offer.title}
        setName={() => null}
        setEdited={() => null}
        displayOnly
      />

      <OfferDrawerTags offer={offer} />

      <TkTaskWrapperItem padding="1rem 1rem 0">
        <TkDrawerRequestedTime
          date={offer.start}
          timezone={offer.location.timezone}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="0 1rem 5px">
        <TkDrawerCustomWarning
          message={t('canceled_warning', { ns: 'offers' })}
          icon="fa fa-trash"
          type="warning"
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="0 1rem">
        <HorizontalDivider />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerCustomerName customerName={offer.customerName} />
      </TkTaskWrapperItem>
    </>
  );
};

export default React.memo(Deleted);
