export const WEB_APP_BASE_URI = 'https://web.taskbird.com/';
export const MARKETING_SITE_BASE_URI = 'https://taskbird.com/';
export const APP_STORE_LINK =
  'https://apps.apple.com/us/app/taskuro/id1509333442?ls=1';
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.taskuro.app&hl=en_US&gl=US';
export const FACEBOOK_APP_ID = '2584546385092021';
export const APPLE_APP_ID =
  process.env.REACT_APP_APPLE_KEY ?? 'com.taskbird.web';
export const APPLE_REDIRECT_URL =
  process.env.REACT_APP_APPLE_REDIRECT_URL ?? 'https://web.taskbird.com/';
export const INTERCOM_APP_ID = 'ybkg75h5';
export const CHAT_API_KEY = 'usr88zbsdm63';

export * from './notifications';
export * from './integrations';
export * from './localStorage';
export * from './calendar';
export * from './events';
export * from './drawers';
export * from './common';
export * from './notifications';
export * from './booking';
export * from './api';
export * from './appUrls';
