export default {
  tkModalAlertIntegrated:
    "This task is from an external calendar and the date can’t be changed",
  tkModalAlertRepeat:
    "This task is instance of a repeating series and can’t be moved to another date",
  tkModalAlertTurnoverbnb:
    "This task is from Turno and the customer can’t be changed",
  tkModalAlertInProgress:
    "You can't edit dates for tasks in progress or completed",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Any changes made to the task will not be reflected in Turno",
  tkModalAlertTaskInPast:
    "You're creating a task in the past",
  alerts: {
    sendNotifications: "Send notifications?",
    doYouWantToNotify: "Do you want to notify?",
    doNotShowAnymore: "Don't show this anymore",
    doNotShowAgainAndSendAllNotifications: "Don't show again and send all notifications",
  },
  taskTime: 'The task’s “start time” cannot be set earlier than the “requested start time” from Turno.',
  customerAlert: {
    title: 'Want to hide your customer’s name?',
    description1: "<strong>Your customer’s name is not visible to your teammates.</strong> If this icon <0></0> appears next to any information on your tasks, <strong>it means it’s only visible to admins.</strong><br/><br/> Decide what information you want to share with your teammates in <1>Team Settings</1>",
    description2: "If this icon <0></0> appears next to any information on your tasks, <strong>it means it’s only visible to admins.</strong><br/><br/> Decide what information you want to share with your teammates in <1>Team Settings</1>",
    warning: 'Don’t show this message again',
    buttonEditTeamSettings: 'Edit Team Settings',
    skip: 'Skip'
  },
  deleteTaskFomTurno: {
    title: "Tasks imported from Turno cannot be deleted on Taskbird, please ask to be removed on Turno",
    ok: "OK"
  }
};
