import { useRecoilState, useSetRecoilState } from 'recoil';
import { Project, ProjectOffer } from 'types/projectOffer.type';

import { CalendarDrawerTypes } from '@consts/index';
import { useDrawer } from '@hooks/index';
import { getFormattedOffer } from '@pages/Home/helpers';
import {
  selectedOffer,
  selectedOfferId,
  setDrawerData,
  selectedEventId,
  selectedOfferFromList,
} from '@recoilData/index';

interface UseOfferDrawerHook {
  openProjectOfferList: () => void;
  closeProjectOfferList: () => void;
  toggleProjectOfferList: () => void;
  openProjectOfferFromList: (offer: Project) => void;
  openProjectOfferCalendarEvent: (offer: ProjectOffer) => void;
}

export const useOfferDrawer = (): UseOfferDrawerHook => {
  const { closeDrawers } = useDrawer();
  const setEventId = useSetRecoilState(selectedEventId);
  const [drawers, setDrawerOpen] = useRecoilState(setDrawerData);
  const setOfferId = useSetRecoilState(selectedOfferId);
  const setSelectedOffer = useSetRecoilState(selectedOffer);
  const setSelectedOfferFromList = useSetRecoilState(selectedOfferFromList);

  const openProjectOfferCalendarEvent = (offer: ProjectOffer) => {
    handleOpenProjectOffer(offer);
  };

  const openProjectOfferFromList = (offer: Project) => {
    setSelectedOfferFromList(true);
    handleOpenProjectOffer(offer);
  };

  const handleOpenProjectOffer = (offer: ProjectOffer | Project) => {
    setOfferId(`offer-${offer.id}}`);
    setSelectedOffer(getFormattedOffer(offer));
    setDrawerOpen({ isOpen: true, drawer: CalendarDrawerTypes.offer });
  };

  const handleProjectOfferList = (isOpen: boolean) => {
    if (isOpen) {
      closeDrawers();
      setEventId(0);
    } else {
      setOfferId(`offer-0`);
      setSelectedOfferFromList(false);
    }
    setDrawerOpen({ isOpen, drawer: CalendarDrawerTypes.listOffers });
  };

  const openProjectOfferList = () => handleProjectOfferList(true);

  const closeProjectOfferList = () => handleProjectOfferList(false);

  const toggleProjectOfferList = () =>
    handleProjectOfferList(!drawers.listOffers && !drawers.offer);

  return {
    openProjectOfferList,
    closeProjectOfferList,
    toggleProjectOfferList,
    openProjectOfferFromList,
    openProjectOfferCalendarEvent,
  };
};
