import React, { useEffect } from 'react';

import TKSection from './TKSection';
import { ISection } from './navbar.types';
import { AppLocationURLs } from '@consts/index';
import { MIXPANEL_EVENTS, useMixpanel } from '@contexts/index';
import { SNAPSHOTS_PAGE_NAMES, useCrazyEggTrackByName } from '@hooks/index';

interface SidebarProps {
  sidebar: boolean;
  locations: ISection[];
  closeSidebar: () => void;
}

export const Sidebar = ({
  locations,
  closeSidebar,
  sidebar,
}: SidebarProps): JSX.Element => {
  const { removeScript } = useCrazyEggTrackByName(
    SNAPSHOTS_PAGE_NAMES.CALENDAR_SIDE_MENU
  );
  const { track } = useMixpanel();
  useEffect(() => {
    if (!sidebar) removeScript();
    return () => {
      removeScript();
    };
  }, []);
  return (
    <>
      {locations.map((location, index) => {
        return (
          <TKSection
            key={`path-${index}`}
            id={location.id}
            icon={location.icon}
            path={location.path}
            title={location.title}
            onClick={() => {
              if (location.path === AppLocationURLs.TEAM) {
                track(MIXPANEL_EVENTS.CLICK_ON_TEAM_BUTTON_ON_MENU);
              }
              closeSidebar();
            }}
          />
        );
      })}
    </>
  );
};
