import { atom } from 'recoil';
import { ProjectOffer } from 'types/projectOffer.type';

import {
  EventBillingTypeEnum,
  TaskFrequencyEnum,
  TaskStatusEnum,
  TaskFrequencyIntervalEnum,
} from '@consts/index';

const offersData = atom<ProjectOffer[]>({
  key: 'offersData',
  default: [],
});

const offersDetailsExtendedDrawer = atom<ITkExtendedObject>({
  key: 'offersDetailsExtendedDrawer',
  default: {
    open: false,
    type: '',
  },
});

const selectedOfferFromList = atom<boolean>({
  key: 'selectedOfferFromList',
  default: false,
});

const selectedOfferId = atom<string>({
  key: 'selectedOfferId',
  default: '',
});

const selectedOffer = atom<ProjectOffer>({
  key: 'selectedOffer',
  default: {
    guestArrivesSameDay: false,
    id: 0,
    platform: null,
    currentStatus: 1,
    externalProjectOffers: [],
    jobDuration: 0,
    price: '0',
    assignmentId: 0,
    title: '',
    notes: '',
    checklists: [],
    customer: undefined,
    timezone: null,
    start: new Date(),
    end: new Date(),
    location: undefined,
    taskType: 'single-task',
    taskRepeat: {
      frequency: TaskFrequencyIntervalEnum.DAILY,
      interval: 1,
      weeklyDaysRepetition: [],
      monthlyType: TaskFrequencyEnum.BY_DAY_TYPE,
      monthlyEachRepetition: [],
      monthlyOnTheRepetition: ['first', 'day'],
      yearlyMonthRepetition: [],
      yearlyOnTheRepetition: [],
    },
    assignedUsers: [],
    crewAssignmentsNotes: [],
    crewAssignmentsImages: [],
    imagesFile: [],
    images: [],
    projectOfferPaymentType: 'HOURLY',
    projectOfferSystemNotes: null,
    status: TaskStatusEnum.TODO,
    assignmentImages: [],
    projectImages: [],
    assignmentNotes: null,
    isRecurring: false,
    recurringProjectRuleId: null,
    externalId: null,
    systemNotes: null,
    accountCalendarId: null,
    calendarName: null,
    client: '',
    allDay: false,
    rate: null,
    billingType: EventBillingTypeEnum.HOURLY,
    travelDistance: 0,
    travelDistanceUnit: 'mi',
    deletedAt: null,
  },
});

export {
  offersData,
  selectedOffer,
  selectedOfferId,
  selectedOfferFromList,
  offersDetailsExtendedDrawer,
};
