import React from 'react';
import { ActivatorSlot } from './styles';
import TkIcon from '@components/TkIcon';
import { DateTime } from 'luxon';
import { ICalendarViewEnum } from '@consts/index';
import { selectWeekFromDate } from '@helpers/dateHelpers';
import { useUserSettings } from '@hooks/user/useUserSettings';

const CalendarDatePickerActivator = (props: any) => {
  const { userSettings } = useUserSettings();
  const startWeekDayIsSunday = userSettings.first_day === 'sunday';

  const memoizedTitles = React.useMemo(() => {
    let title = '';
    const selectedDate = DateTime.fromFormat(props.value, 'M/d/y');
    if (props.gridView === ICalendarViewEnum.MONTH_VIEW) {
      title = selectedDate.toFormat('LLLL y');
    } else if (props.gridView === ICalendarViewEnum.WEEK_VIEW) {
      const weekInterval = selectWeekFromDate(selectedDate, startWeekDayIsSunday);
      const startMonthDay = weekInterval.start.toFormat('LLLL d');
      const endDayYear = weekInterval.end.minus({ days: 1 }).toFormat('d, y');
      title = `${startMonthDay}-${endDayYear}`;
    }
    return title;
  }, [props.value, startWeekDayIsSunday]);

  return (
    <ActivatorSlot>
      <span>
        {memoizedTitles}
        <TkIcon className="fas fa-angle-down" color="primary" />
      </span>
    </ActivatorSlot>
  );
};

export default CalendarDatePickerActivator;
