import { useQuery } from 'react-query';
import {
  IBookingAdditionalInfo,
  IBookingServiceDetails,
} from 'types/projectOffer.type';

import { TaskbirdIntegrationsEnum } from '@consts/integrations';
import camelize from '@helpers/camelize';
import api from '@services/api';

function getProjectServiceDetails(accountId: number, projectId: number) {
  return api
    .get(`/project/${projectId}/account/${accountId}/get-service-details`)
    .then((response) => response.data.data as IBookingServiceDetails);
}

function getProjectAdditionalInfo(accountId: number, projectId: number) {
  return api
    .get(`/project/${projectId}/account/${accountId}/get-additional-info`)
    .then((response) => {
      return camelize<IBookingAdditionalInfo>(response.data);
    });
}

export function useProjectServiceDetails(accountId: number, event: IEvent) {
  return useQuery(
    ['getProjectServiceDetails', accountId, event.id],
    () => getProjectServiceDetails(accountId, event.id),
    {
      enabled: event.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET,
      placeholderData: {
        base_price: 0,
        duration_in_minutes: 0,
        name: '',
        service_attributes: [],
        total_estimated_price: 0,
      },
    }
  );
}

export function useProjectAdditionalInfo(accountId: number, event: IEvent) {
  return useQuery(
    ['getProjectAdditionalInfo', accountId, event.id],
    () => getProjectAdditionalInfo(accountId, event.id),
    {
      enabled: event.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET,
      placeholderData: {
        data: [],
      },
    }
  );
}
