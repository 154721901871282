import styled from 'styled-components';

export const TkTaskWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  flex-direction: column;
  padding: 1rem;
  position: relative;
`;

export const TkTaskActions = styled.div`
  border-top: 1px solid #999999;
  display: flex;
  min-height: 65px;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  background-color: white;
`;
