/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { FormControlLabel, Grid } from '@mui/material';

import {
  DeleteIconModal,
  ModalActions,
  ModalContent,
  TextModal,
} from '@components/TkModalAlert';
import {
  DynamicButton,
  TkCheckBox,
  TkIcon,
  TkTypography,
} from '@components/index';
import { ApiResultsKind, LocalStorage } from '@consts/index';
import { useGetAccountSettings } from '@pages/Profile/hooks';
import { updateUserNeverShowCustomerModal } from '@services/userMethods';

type EditTaskCustomerModalProps = {
  callback?: () => void;
  handleCloseModal: () => void;
  handleRedirectToTeamSettings: () => void;
};

export const EditTaskCustomerModal = ({
  callback = null,
  handleCloseModal,
  handleRedirectToTeamSettings,
}: EditTaskCustomerModalProps): JSX.Element => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const { data } = useGetAccountSettings();

  useEffect(() => {
    async function setDateShown() {
      await localStorage.setItem(
        LocalStorage.LAST_CUSTOMER_MODAL_SHOWN,
        new Date().toISOString()
      );
    }
    setDateShown();
  }, []);

  const handleDontShowAgain = async () => {
    await localStorage.setItem(
      LocalStorage.DONT_SHOW_CUSTOMER_MODAL_AGAIN,
      checked.toString()
    );
    if (checked) {
      const { kind } = await updateUserNeverShowCustomerModal();
      if (kind === ApiResultsKind.ERROR) {
        await localStorage.setItem(
          LocalStorage.DONT_SHOW_CUSTOMER_MODAL_AGAIN,
          'false'
        );
      }
    }
  };

  const handleSkip = () => {
    handleDontShowAgain();
    callback && callback();
    handleCloseModal();
  };

  const showCustomerName = data?.settings?.showCustomerName;
  const descriptionsTokens = showCustomerName
    ? 'modalAlert:customerAlert.description1'
    : 'modalAlert:customerAlert.description2';

  return (
    <>
      <ModalContent
        sx={{
          width: '521px',
          height: '400px',
        }}
      >
        <Grid container>
          <DeleteIconModal
            container
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
          >
            <TkIcon
              className="fal fa-eye-slash"
              iconSize={72}
              color="primary"
              style={{ width: 'auto' }}
            />
          </DeleteIconModal>
          <TextModal
            container
            justifyContent="center"
            color="default"
            maxWidth="521px"
          >
            <TkTypography
              fontFamily="Muli"
              fontWeight="bold"
              fontSize={24}
              color="#011F41"
            >
              {t('customerAlert.title', { ns: 'modalAlert' })}
            </TkTypography>
          </TextModal>
          <TextModal
            container
            color="default"
            justifyContent="left"
            alignItems="left"
            maxWidth="521px"
          >
            <Grid container justifyContent="left" direction="column">
              <Grid>
                <TkTypography
                  fontFamily="Lato"
                  fontWeight="normal"
                  fontSize={16}
                  textAlign="left"
                  color="#011F41"
                >
                  <Trans i18nKey={descriptionsTokens}>
                    <TkIcon
                      className="fa fa-eye-slash"
                      iconSize={16}
                      color="#011F41"
                      style={{ width: 'auto' }}
                    />
                    <a
                      href="/settings/team"
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: '#4952A9',
                      }}
                    />
                  </Trans>
                </TkTypography>
              </Grid>
            </Grid>
          </TextModal>
          <FormControlLabel
            checked={checked}
            sx={{ marginRight: 'auto', marginTop: '1rem' }}
            control={
              <TkCheckBox
                checked={checked}
                onChange={(_, checked) => setChecked(checked)}
                name="check-project-offers-warning"
              />
            }
            label={`${t('customerAlert.warning', { ns: 'modalAlert' })}`}
          />
        </Grid>
      </ModalContent>
      <ModalActions
        sx={{
          padding: '20px',
          placeContent: showCustomerName ? 'space-around' : 'space-between',
        }}
      >
        {showCustomerName ? (
          <DynamicButton
            variant="contained"
            color="primary"
            onClick={handleSkip}
            disableElevation
            sx={{ padding: '0 40px' }}
          >
            {t('ok', { ns: 'common' })}
          </DynamicButton>
        ) : (
          <>
            <DynamicButton
              color="primary"
              onClick={handleSkip}
              disableElevation
            >
              {t('skip', { ns: 'common' })}
            </DynamicButton>
            <DynamicButton
              variant="contained"
              color="primary"
              onClick={() => {
                handleDontShowAgain();
                handleRedirectToTeamSettings();
                handleCloseModal();
              }}
              disableElevation
            >
              {t('customerAlert.buttonEditTeamSettings', { ns: 'modalAlert' })}
            </DynamicButton>
          </>
        )}
      </ModalActions>
    </>
  );
};
