import styled from 'styled-components';

import { TkDatePickerWrapper } from '@components/TkDatePicker/styles';

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0 1rem 0;
`;

export const WeekItem = styled.div<{ checked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  border-radius: 4px;
  padding: 5px;
  width: 40px;
  height: 35px;
  font: normal normal bold 13px 'Lato';
  cursor: pointer;

  color: ${({ theme, checked }) =>
    checked ? theme.palette.white : theme.palette.primary.main};

  border: 2px solid
    ${({ theme, checked }) =>
      checked ? theme.palette.secondary.main : theme.palette.primary.main};
  background-color: ${({ theme, checked }) =>
    checked ? theme.palette.secondary.main : theme.palette.white};

  transition: all 0.2s ease-in-out;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  justify-content: space-between;
  flex-direction: row;
  font-family: 'Lato';
`;

export const DatePickersWrapper = styled(TkDatePickerWrapper)`
  .react-datepicker {
    box-shadow: none;
    width: 100%;
    min-height: auto; // remove height

    .react-datepicker__header {
      padding-top: 0;
      user-select: none;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        height: 40px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
      }

      .react-datepicker__day-names {
        padding: 10px 0 0;
      }

      .react-datepicker__day-names,
      .react-datepicker__week {
        display: flex;
        flex: 1;
        justify-content: space-between;
      }
    }

    .react-datepicker__month {
      display: none; // hide dates in month
      margin: 0;
      padding: 0.4rem 0;

      .react-datepicker__day--outside-month {
        opacity: 0;
      }

      .react-datepicker__day-names,
      .react-datepicker__week {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;

        .react-datepicker__day {
          transition: all 0.2s ease-in-out;
        }

        .react-datepicker__day--today {
          color: ${({ theme }) => theme.palette.primary.main};
        }

        .react-datepicker__day--highlighted,
        .react-datepicker__month-text--highlighted,
        .react-datepicker__quarter-text--highlighted,
        .react-datepicker__year-text--highlighted {
          color: ${({ theme }) => theme.palette.primary.main};
          background: linear-gradient(
              45deg,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 45%,
              ${({ theme }) => theme.palette.secondary.main} 45%,
              ${({ theme }) => theme.palette.secondary.main} 55%,
              rgba(0, 0, 0, 0) 55%,
              rgba(0, 0, 0, 0) 100%
            ),
            linear-gradient(
              135deg,
              #fff 0%,
              #fff 45%,
              ${({ theme }) => theme.palette.secondary.main} 45%,
              ${({ theme }) => theme.palette.secondary.main} 55%,
              #fff 55%,
              #fff 100%
            );
          border-radius: 10px;
        }
      }
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  padding-top: 5px;
  gap: 10px 28px;
  transition: all 0.2s ease-in-out;
`;

export const GridItem = styled.div<{
  selected?: boolean;
  today?: boolean;
  empty?: boolean;
}>`
  font-family: 'Lato';
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  width: 30px;
  height: 30px;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 10px;
  cursor: ${({ empty }) => (empty ? 'default' : 'pointer')};

  color: ${({ theme, selected, today }) =>
    selected || today ? theme.palette.primary.main : '#000'};
  background: ${({ theme, selected, empty }) => {
    if (!selected || empty) return 'transparent';

    return `linear-gradient(
      45deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 45%,
      ${theme.palette.secondary.main} 45%,
      ${theme.palette.secondary.main} 55%,
      rgba(0, 0, 0, 0) 55%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      135deg,
      #fff 0%,
      #fff 45%,
      ${theme.palette.secondary.main} 45%,
      ${theme.palette.secondary.main} 55%,
      #fff 55%,
      #fff 100%
    )`;
  }};

  transition: all 0.2s ease-in-out;
`;

export const Circle = styled.div<{ type: 'unassignedTasks' | 'assignedTasks' }>`
  display: flex;
  width: 16px;
  height: 16px;
  user-select: none;
  background-color: ${({ theme, type }) =>
    type === 'unassignedTasks'
      ? theme.palette.alert.main
      : theme.palette.primary.main};
  border-radius: 50%;
  color: ${({ theme }) => theme.palette.white};
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  font-weight: bold;
`;
