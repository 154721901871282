import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';
import { AppLocationURLs } from '@consts/appUrls';

const DashboardPage = lazy(() => import('@pages/Dashboard'));

const DashboardRoutes = [
  {
    path: AppLocationURLs.DASHBOARD,
    component: withAuth(DashboardPage),
  },
];

export default DashboardRoutes;
