import { TkTypography } from '@components/index';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type ProjectOfferSnackbarMessageProps = {
  translationToken?: string;
};

export const ProjectOfferSnackbarMessage = ({
  translationToken,
}: ProjectOfferSnackbarMessageProps) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <TkTypography
        color="white"
        fontWeight="normal"
        fontFamily="Lato"
        fontSize={16}
      >
        {t(`${translationToken}`, {
          ns: 'offers',
        })}
      </TkTypography>
    </Box>
  );
};
