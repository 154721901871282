import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TkTypography, TkIcon } from '@components/index';

interface TkDrawerLocationProps {
  location: string;
}

const TkDrawerEmail = ({ location }: TkDrawerLocationProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <Grid container direction="row" style={{ margin: '0 0 5px 0' }}>
        <TkIcon
          className="fa fa-map-marker-alt"
          iconSize={14}
          width={14}
          marginTop={4}
          marginRight={7}
        />
        <Box maxWidth={'94%'}>
          <TkTypography marginLeft={'5px'} fontFamily={'Lato'} fontSize={16}>
            {location}
          </TkTypography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TkDrawerEmail;
