import React from 'react';

import { Grid } from '@mui/material';

import { MainWrapper, IconWrapper } from './styles';
import { TkTaskWrapperSubItem } from '@components/TkDrawerComponents/TkTaskWrapperItem/types';
import { TkIcon, TkTooltip } from '@components/index';

const TkTaskWrapperItem = ({
  type = 'standard',
  icon,
  iconStyle,
  children,
  childrenStyle,
  Actions,
  actionsStyle,
  style,
  innerStyle,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: TkTaskWrapperSubItem & { children: React.ReactNode }): JSX.Element => {
  return (
    <MainWrapper
      container
      style={style}
      bgcolor={type}
      justifyContent="space-between"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Grid
        container
        direction="row"
        maxWidth={
          innerStyle?.maxWidth ? innerStyle?.maxWidth : Actions ? '80%' : '100%'
        }
      >
        {icon && (
          <IconWrapper style={{ alignItems: iconStyle?.verticalAlign }}>
            <TkTooltip title={iconStyle?.tooltipText ?? ''}>
              <TkIcon
                className={icon}
                {...iconStyle}
                marginRight={iconStyle?.marginRight ?? 5}
              />
            </TkTooltip>
          </IconWrapper>
        )}
        <div style={childrenStyle}>{children}</div>
      </Grid>
      {Actions && <IconWrapper style={actionsStyle}>{Actions}</IconWrapper>}
    </MainWrapper>
  );
};

export default TkTaskWrapperItem;
