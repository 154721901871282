import { Project } from 'types/projectOffer.type';

export type ProjectOffers = {
  [key: string]: Project[];
};

export enum ProjectOfferBulkAction {
  AcceptAll = 'acceptMultiple',
  RejectAll = 'rejectMultiple',
}
