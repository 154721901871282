import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TkTypography } from '@components/index';
import { PLAY_STORE_LINK, APP_STORE_LINK } from '@consts/index';
import {
  TaskbirdLogo,
  AppStoreBlackBadge,
  GooglePlayBlackBadge,
  BookingSiteBlockResponsive,
} from '@assets/index';
import {
  TaskbirdLogoImage,
  GetMobileAppButton,
  BlockResponsiveCard,
  BlockResponsiveBackground,
} from './styles';
import TkBookinSiteBlockResponsiveSubTitle from './TkBookinSiteBlockResponsiveSubTitle';

interface IBlockResponsive {
  active?: boolean;
  isBookingSite?: boolean;
  children: React.ReactNode;
}

const BlockResponsive = ({
  active = true,
  isBookingSite = false,
  children,
}: IBlockResponsive): JSX.Element => {
  const { t } = useTranslation(['blockResponsive']);
  const isResponsive = useMediaQuery('(max-width:600px)');
  const downloadMobileAppLink = `${process.env.REACT_APP_API_URL}download-taskbird`;

  if (!active || !isResponsive) {
    return <>{children}</>;
  }

  return (
    <BlockResponsiveBackground>
      <BlockResponsiveCard>
        <TaskbirdLogoImage src={TaskbirdLogo} />
        <TkTypography
          marginTop={8}
          fontSize={20}
          textAlign="left"
          fontFamily="Muli"
          fontWeight="bold"
        >
          {isBookingSite ? t('titleBookingSite') : t('title')}
        </TkTypography>
        <TkTypography marginY={3} textAlign="left">
          {isBookingSite ? (
            <TkBookinSiteBlockResponsiveSubTitle />
          ) : (
            t('subTitle')
          )}
        </TkTypography>
        {isBookingSite ? (
          <Grid
            container
            padding={10}
            justifyContent="center"
            alignItems={'center'}
          >
            <img
              src={BookingSiteBlockResponsive}
              alt={'booking-Site-BlockResponsive.svg'}
            />
          </Grid>
        ) : (
          <>
            <Grid container marginY={4} justifyContent="space-evenly">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={APP_STORE_LINK}
              >
                <img src={AppStoreBlackBadge} alt={'app-store.svg'} />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={PLAY_STORE_LINK}
              >
                <img src={GooglePlayBlackBadge} alt={'google-play-store.svg'} />
              </a>
            </Grid>
            <a href={downloadMobileAppLink} target="_blank" rel="noreferrer">
              <GetMobileAppButton
                fullWidth
                marginTop={3}
                disableElevation
                bgcolor="primary"
                variant="contained"
              >
                {t('callToAction')}
              </GetMobileAppButton>
            </a>
          </>
        )}
      </BlockResponsiveCard>
    </BlockResponsiveBackground>
  );
};

export default BlockResponsive;
