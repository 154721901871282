import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { FormControlLabel, Grid } from '@mui/material';

import { Container, ModalContent, ModalActions } from './styles';
import { getProjectOfferWarningStatus } from '../../helpers';
import {
  TkIcon,
  TkCheckBox,
  TkTypography,
  DynamicButton,
} from '@components/index';
import { LocalStorage } from '@consts/index';
import { useModal } from '@contexts/index';
import { useLocalStorage } from '@hooks/index';
import { useOfferDrawer } from '@pages/Home/hooks';
import { getUserAccountId } from '@recoilData/index';

interface ITkProjectOfferWarningModal {
  projectOffersCount: number;
}

const TkProjectOfferWarningModal: React.FC<ITkProjectOfferWarningModal> = ({
  projectOffersCount,
}) => {
  const { t } = useTranslation();
  const { openProjectOfferList } = useOfferDrawer();
  const [checked, setChecked] = useState<boolean>(false);
  const accountId = useRecoilValue(getUserAccountId);
  const { handleCloseModal } = useModal();

  const [projectOfferWarning, setProjectOfferWarning] =
    useLocalStorage<ProjectOfferWarningLocalStorage>(
      LocalStorage.PROJECT_OFFERS_WARNING,
      {
        dismissWarning: false,
        accounts: [],
      }
    );

  useEffect(() => {
    setChecked(
      getProjectOfferWarningStatus(projectOfferWarning, Number(accountId))
    );
  }, [getProjectOfferWarningStatus]);

  const handleSeeRequests = (): void => {
    const id = Number(accountId);
    openProjectOfferList();

    setProjectOfferWarning({
      dismissWarning: checked,
      accounts:
        !checked || projectOfferWarning.accounts.includes(id)
          ? projectOfferWarning.accounts
          : [...projectOfferWarning.accounts, id],
    });

    handleCloseModal();
  };

  return (
    <Container>
      <ModalContent style={{ padding: '20px 20px 10px' }}>
        <Grid container justifyContent="center">
          <TkIcon
            iconSize={72}
            width={72}
            color="#4952A9"
            className="fal fa-exclamation-circle"
          />
        </Grid>
        <Grid container justifyContent="center" padding="15px 0 5px">
          <TkTypography fontSize="24px" fontWeight="bold" fontFamily="Muli">
            {t('youHaveXRequests', { ns: 'offers', count: projectOffersCount })}
          </TkTypography>
        </Grid>
        <Grid container justifyContent="center">
          <TkTypography fontSize="16px" fontWeight="bold" fontFamily="Lato">
            {t('doYouWantToCheckThemNowQuestion', {
              ns: 'offers',
            })}
          </TkTypography>
        </Grid>
        <Grid container paddingTop="25px">
          <FormControlLabel
            checked={checked}
            control={
              <TkCheckBox
                checked={checked}
                onChange={(_, checked) => setChecked(checked)}
                name="check-project-offers-warning"
              />
            }
            label={`${t('dontShowAgain', { ns: 'offers' })}`}
          />
        </Grid>
      </ModalContent>
      <ModalActions>
        <Grid container justifyContent="space-between" padding="5px">
          <DynamicButton color="primary" onClick={handleCloseModal}>
            {t('remindMeLater', { ns: 'common' })}
          </DynamicButton>
          <DynamicButton
            style={{ width: '145px' }}
            variant="contained"
            bgcolor="primary"
            onClick={handleSeeRequests}
          >
            {t('seeRequests', { ns: 'offers' })}
          </DynamicButton>
        </Grid>
      </ModalActions>
    </Container>
  );
};

export default TkProjectOfferWarningModal;
