import { ApiResultsKind } from '@consts/api';
import {
  GetAvailabilityRequest,
  GetAvailabilityResult,
  GetAvailabilityWeekDayRequest,
  GetAvailabilityWeekDayResult,
  GetScheduleRequest,
  GetScheduleResult,
  UpdateAvailabilityRequest,
} from '@hooks/availability/types';
import api from '@services/api';

const getAvailability = async ({
  accountId,
  currentMonth,
  userId,
}: GetAvailabilityRequest): Promise<GetAvailabilityResult> =>
  await api
    .get(`account/${accountId}/availability`, {
      params: {
        userId: userId,
        start_date: currentMonth,
      },
    })
    .then(({ data }) => ({
      kind: ApiResultsKind.OK,
      days: data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const getAvailabilityWeekRules = async ({
  accountId,
  userId,
}: GetAvailabilityWeekDayRequest): Promise<GetAvailabilityWeekDayResult> =>
  await api
    .get(`account/${accountId}/availability/weekday-rules`, {
      params: {
        userId,
      },
    })
    .then(({ data }) => ({
      kind: ApiResultsKind.OK,
      weekDays: data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const updateAvailabilityRequest = async ({
  accountId,
  updatePackage,
}: UpdateAvailabilityRequest): Promise<void> =>
  await api.post(`account/${accountId}/availability`, updatePackage);

const getSchedule = async ({
  accountId,
  currentMonth,
  userId,
}: GetScheduleRequest): Promise<GetScheduleResult> =>
  await api
    .get(`account/${accountId}/schedule/month`, {
      params: {
        userId,
        start_date: currentMonth,
      },
    })
    .then(({ data }) => ({
      kind: ApiResultsKind.OK,
      schedule: data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

export {
  getSchedule,
  getAvailability,
  getAvailabilityWeekRules,
  updateAvailabilityRequest,
};
