import React from 'react';

import DelayLink from 'react-delay-link';
import styled from 'styled-components';

import './Navbar.css';
import { ISection } from './navbar.types';
import { TkTypography, TkIcon } from '@components/index';
interface Section extends ISection {
  onClick: () => void;
}

const NavbarContent = styled.div`
  width: 100%;
  height: 100%;
  height: 60px;
  display: flex;
  padding: 0 15px;
  list-style: none;
  align-items: center;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    background-color: #ffffff30;
  }
`;

const TKSection: React.FC<Section> = ({ title, path, icon, id, onClick }) => {
  return (
    <section style={{ width: '100%' }} id={id}>
      <DelayLink to={path} clickAction={onClick} delay={400}>
        <NavbarContent>
          <TkIcon
            className={icon}
            iconSize={16}
            width={20}
            verticalAlign="center"
            color="#FFFFFF"
            marginRight={13}
          />
          <TkTypography
            textAlign="left"
            fontFamily="Lato"
            fontSize={16}
            color="#FFFFFF"
          >
            {title}
          </TkTypography>
        </NavbarContent>
      </DelayLink>
    </section>
  );
};

export default React.memo(TKSection);
