export const getProjectOfferWarningStatus = (
  projectOfferWarning: ProjectOfferWarningLocalStorage,
  accountId: number
): boolean => {
  if (
    !projectOfferWarning.dismissWarning ||
    (projectOfferWarning.dismissWarning &&
      !projectOfferWarning.accounts.includes(accountId))
  ) {
    return false;
  }

  return true;
};
