export default {
  tkModalAlertIntegrated:
    "Esta tarefa é de um calendário externo e a data não pode ser alterada",
  tkModalAlertRepeat:
    "Esta tarefa é um exemplo de uma série de repetições e não pode ser transferida para outra data.",
  tkModalAlertTurnoverbnb:
    "Esta tarefa é da Turno e o cliente não pode ser alterado",
  tkModalAlertInProgress:
    "Você não pode editar datas para tarefas em andamento ou concluídas",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Quaisquer mudanças feitas na tarefa não serão refletidas na Turno",
  tkModalAlertTaskInPast:
    "Você está criando uma tarefa no passado.",
  alerts: {
    sendNotifications: "Enviar notificação?",
    doYouWantToNotify: "Você deseja notificar?",
    doNotShowAnymore: "Não mostrar novamente",
    doNotShowAgainAndSendAllNotifications: "Não mostrar novamente e enviar todas as notificações",
  },
  taskTime: 'A hora de início da tarefa no Taskbird não pode ser anterior à hora de início que foi agendada no Turno.',
  customerAlert: {
    title: 'Você quer ocultar o nome de seu cliente?',
    description1: "O nome do seu cliente não é visível para os diaristas da sua equipe. Quando você vê um ícone de olho <0></0> ao lado de alguma informação nos detalhes de suas tarefas, significa que essa informação é somente visível para os administradores.<br/><br/> Você pode escolher as informações que deseja compartilhar com sua equipe na sua guia <1>“Configurações da equipe”</1>.",
    description2: "Quando você vê um ícone de olho <0></0> ao lado de alguma informação nos detalhes de suas tarefas, significa que essa informação é somente visível para os administradores.<br/><br/> Você pode escolher as informações que deseja compartilhar com sua equipe na sua guia <1>“Configurações da equipe”</1>.",
    warning: "Não mostrar esta mensagem novamente",
    buttonEditTeamSettings: '"Ir para "Configurações da equipe"',
    skip: 'Pular',
  },
  deleteTaskFomTurno: {
    title: "As tarefas importadas do Turno não podem ser excluídas no Taskbird. Por favor, peça para ser removido(a) no seu aplicativo Turno.",
    ok: "OK"
  }
};
