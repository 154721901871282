import {
  UpdatePhoneNumberInput,
  UpdatePhoneNumberResult,
  UpdateUserSettingsInput,
  ValidatePhoneNumberCodeInput,
  ValidatePhoneNumberCodeResult,
} from 'types/userMethods';

import api from './api';
import { ApiResultsKind } from '@consts/index';

const updateUserSettings = ({
  settings,
}: UpdateUserSettingsInput): Promise<UpdateUserSettingsResult> =>
  api
    .post('user-settings', settings)
    .then((response) => ({
      kind: ApiResultsKind.OK,
      settings: response.data.data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        settings: null,
      })
    );

const updateUserNeverShowCustomerModal =
  (): Promise<UpdateUserSettingsResult> =>
    api
      .post('user-settings', {
        show_customer_modal: false,
      })
      .then((response) => ({
        kind: ApiResultsKind.OK,
        settings: response.data.data,
      }))
      .catch(() =>
        Promise.reject({
          kind: ApiResultsKind.ERROR,
          settings: null,
        })
      );

const fetchUserSettings = (): Promise<UserSettingsResult> =>
  api
    .get('user-settings')
    .then((res) => ({
      kind: ApiResultsKind.OK,
      settings: res.data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        settings: null,
      })
    );

const fetchUserInfo = (): Promise<UserInfoResult> =>
  api
    .get('session/getUserInfo')
    .then((res) => ({
      kind: ApiResultsKind.OK,
      user: res.data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        user: null,
      })
    );

const disableRemovedIntegrationNotification = (
  accountId: string,
  notificationId: string
): Promise<DisableRemovedIntegrationNotificationResult> =>
  api
    .post(
      `account/${accountId}/integrations/stopNotifyRemoval/${notificationId}`
    )
    .then((res) => ({
      kind: ApiResultsKind.OK,
      ...res.data,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const saveNewPassword = ({
  code,
  email,
  password,
  confirmPassword,
}: SaveNewPasswordInput): Promise<SaveNewPasswordResult> =>
  api
    .post('user/saveNewPassword', {
      code,
      email,
      password,
      confirmPassword,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch((e) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const recoverPassword = ({
  email,
}: RecoverPasswordInput): Promise<RecoverPasswordResult> =>
  api
    .post('user/recoverPassword', {
      email,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch((e) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        errors: e.response.data.errors,
      })
    );

const validatePhoneNumberValidationCode = ({
  code,
}: ValidatePhoneNumberCodeInput): Promise<ValidatePhoneNumberCodeResult> =>
  api
    .post('user/validatePhoneNumberValidationCode', {
      code,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const validateRecoveryPasswordCode = ({
  code,
}: ValidateRecoveryPasswordCodeInput): Promise<ValidateRecoveryPasswordCodeResult> =>
  api
    .post('user/validateRecoveryPasswordCode', {
      code,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

const updateUserPhoneNumber = ({
  phoneNumber,
  recaptcha,
}: UpdatePhoneNumberInput): Promise<UpdatePhoneNumberResult> =>
  api
    .post('user/generatePhoneNumberValidationCode', {
      phone_number: `+${phoneNumber}`,
      recaptcha,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
      })
    );

export {
  validatePhoneNumberValidationCode,
  validateRecoveryPasswordCode,
  updateUserPhoneNumber,
  updateUserSettings,
  updateUserNeverShowCustomerModal,
  fetchUserSettings,
  disableRemovedIntegrationNotification,
  saveNewPassword,
  recoverPassword,
  fetchUserInfo,
};
