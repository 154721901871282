import React from 'react';
import { createGenerateClassName, StylesProvider } from '@mui/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'v5',
});

export const TkStyleProvider: React.FC = ({ children }) => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      {children}
    </StylesProvider>
  );
};
