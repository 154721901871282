import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { InputAdornment } from '@mui/material';

import TkInput from '@components/TkInput';
interface ITkDatePickerActivatorProps {
  EndAdornment: any;
  StartDate: Date;
  Format: string;
  Disabled?: boolean;
  notSet?: boolean;
  timezone: string | null;
  activatorTrigger?: () => void;
}

const CustomInput = styled(TkInput)`
  width: 63%;
  color: #011f41;
  font-family: 'Muli';
  &.MuiInputBase-root {
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
  }
  .MuiInputBase-input {
    padding: 6px 0 2px;
    text-align: right;
    margin-right: 7px;
  }
`;

const TkDatePickerActivator = ({
  EndAdornment,
  Format,
  StartDate,
  Disabled,
  activatorTrigger,
  notSet = false,
  timezone,
}: ITkDatePickerActivatorProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <CustomInput
      onClick={activatorTrigger}
      value={
        notSet
          ? t('timeNotSet', { ns: 'events' })
          : DateTime.fromJSDate(StartDate).setZone(timezone).toFormat(Format)
      }
      aria-describedby="component-error-text"
      disableUnderline
      disabled={!!Disabled}
      endAdornment={
        !notSet && (
          <InputAdornment position="end">
            <EndAdornment fontWeight="bold" />
          </InputAdornment>
        )
      }
    />
  );
};

export default TkDatePickerActivator;
