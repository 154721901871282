// ** Routes Import
import ChatRoutes from './chat.routes';
import TeamRoutes from './team.routes';
import PublicRoutes from './public.routes';
import CalendarRoutes from './calendar.routes';
import { BookingPrivateRoutes, BookingPublicRoutes } from './booking.routes';
import CustomersRoutes from './customers.routes';
import IntegrationsRoutes from './integrations.routes';
import DashboardRoutes from './dashboard.routes';
import ProfileRoute from './profile.routes';

// ** Merging Routes
const routes = [
  ...ChatRoutes,
  ...PublicRoutes,
  ...CalendarRoutes,
  ...TeamRoutes,
  ...CustomersRoutes,
  ...ProfileRoute,
  ...IntegrationsRoutes,
  ...BookingPrivateRoutes,
  ...BookingPublicRoutes,
  ...DashboardRoutes,
];

export { routes };
