import { DynamicButton, TkTypography } from "@components/index";
import { ModalActions, ModalContent } from "@components/TkModalAlert";
import { Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { Container } from "./styles";

interface ITkEmailTakenModal {
  handleUseOtherEmail: () => void;
  handleLogin: () => void;
}

const TkEmailTakenModal: React.FC<ITkEmailTakenModal> = ({
  handleLogin,
  handleUseOtherEmail,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ModalContent>
        <Container direction={"column"}>
          <TkTypography
            fontFamily={"Muli"}
            fontWeight={"bold"}
            fontSize={"24px"}
            textAlign={"center"}
          >
            {t("login.emailAlreadyInUse")}
          </TkTypography>
          <TkTypography
            fontFamily={"Lato"}
            fontSize={"16px"}
            marginTop={"10px"}
          >
            {t("login.loginWithOtherAccountMessage")}
          </TkTypography>
        </Container>
      </ModalContent>
      <ModalActions>
        <Grid container justifyContent={"space-between"} margin={"10px 0"}>
          <DynamicButton color={"primary"} onClick={handleUseOtherEmail}>
            <TkTypography fontFamily={"Lato"} fontSize={"16px"}>
              {t("login.useDifferentEmail")}
            </TkTypography>
          </DynamicButton>
          <DynamicButton
            bgcolor={"primary"}
            variant={"contained"}
            onClick={handleLogin}
          >
            <TkTypography fontFamily={"Lato"} fontSize={"16px"}>
              {t("login.logIn")}
            </TkTypography>
          </DynamicButton>
        </Grid>
      </ModalActions>
    </>
  );
};

export default TkEmailTakenModal;
