import React from 'react';

import { ThemeProvider } from 'styled-components';

import { themeV5 } from '@styles/theme';

const StyledComponentsThemeProvider: React.FC = ({ children }) => {
  return <ThemeProvider theme={themeV5}>{children}</ThemeProvider>;
};

export default StyledComponentsThemeProvider;
