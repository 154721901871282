import React from 'react';
import { Avatar } from '@mui/material';

import { generateInitials } from '@helpers/index';

type Props = {
  photoUrl: string | null | undefined;
  teammateName: string;
};

const AVATAR_SIZE = 36;

const TkSimpleAvatar = (props: Props): JSX.Element => {
  const { photoUrl, teammateName } = props;

  if (photoUrl) {
    return (
      <Avatar
        src={photoUrl}
        style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
      />
    );
  }

  return (
    <Avatar style={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
      {generateInitials(teammateName)}
    </Avatar>
  );
};

export default TkSimpleAvatar;
