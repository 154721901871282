import { string, object } from "yup";

export const emailValidator = string().email().required();

export const passwordValidator = string().min(8).required();

export const accountValidator = object({
  firstName: string().required("firstName"),
  lastName: string().required("lastName"),
  email: string().email().required("email"),
  password: string().min(8).required("password"),
});

export const stringValidator = string().required();
