import React, { useState } from 'react';

import { withRouter } from 'react-router-dom';

import { Backdrop } from './styles';
import { TkBaseDrawer, TkExtendedDrawer } from '@components/index';
import { useDrawer } from '@hooks/index';

const TkGlobalDrawer = ({ history }): JSX.Element => {
  const {
    currentDrawer: { main, extended },
    drawerOptions,
    closeDrawers,
  } = useDrawer();

  const [uri, setUri] = useState(history.location.pathname);

  history.listen((location) => {
    if (location.pathname !== uri) {
      setUri(location.pathname);
      closeDrawers();
    }
  });

  const { Component: MainComponent, props: propsMain } = main;
  const { Component: ExtendedComponent, props: propsExtended } = extended;

  return (
    <div>
      {drawerOptions.showBackdrop && (
        <Backdrop
          onClick={
            drawerOptions.backdropClickClose ? () => closeDrawers() : () => null
          }
        />
      )}
      <div>
        <TkBaseDrawer open={!!main.id}>
          {MainComponent && <MainComponent {...propsMain} />}
        </TkBaseDrawer>
      </div>
      <div>
        <TkExtendedDrawer open={!!extended?.id}>
          {ExtendedComponent && <ExtendedComponent {...propsExtended} />}
        </TkExtendedDrawer>
      </div>
    </div>
  );
};

export default withRouter(TkGlobalDrawer);
