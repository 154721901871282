import React, { useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { Paper, Grid } from "@material-ui/core";

import {
  ModalContainer,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  TextContainer,
  HeaderText,
  SubtitleText,
  PhoneInputButton,
  MessageCloseButton,
  Message,
  MessageText,
  MessageContainer,
} from "./styles";

import { TkIcon } from "@components/index";

import { apiMethods } from "@services/index";
import { useSnackbar } from "@components/TkSnackbar/useSnackbar";
import { useReCaptchaVerify } from '@hooks/index';
import { ApiResultsKind } from "@consts/api";

type SendMeTheAppModalProps = {
  onClose(): void;
};

type ResponseMessageProps = {
  error: boolean;
  onClose(): void;
};

const ResponseMessage: React.FC<ResponseMessageProps> = ({
  error,
  onClose,
}) => (
  <MessageContainer>
    {error ? (
      <Message>
        <TkIcon iconSize={22} className="fas fa-times-circle" color="red" />
        <MessageText>Something went wrong. Please try again</MessageText>
      </Message>
    ) : (
      <Message>
        <TkIcon iconSize={22} className="fas fa-check-circle" color="green" />
        <MessageText>The app was successfully send to your phone!</MessageText>
      </Message>
    )}
    <MessageCloseButton onClick={onClose}>
      <TkIcon iconSize={22} className="fas fa-times" color="gray" />
    </MessageCloseButton>
  </MessageContainer>
);

const SendMeTheAppModal: React.FC<SendMeTheAppModalProps> = ({ onClose }) => {
  const { sendMeTheApp } = apiMethods;
  const [phone, setPhone] = useState("");
  const { fireTSuccess, fireTError } = useSnackbar();
  const handleReCaptchaVerify = useReCaptchaVerify();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(true);


  const handleSend = async (): Promise<void> => {
    setIsLoading(true);

    if (phone.length > 0) {
      try {
        const recaptcha = await handleReCaptchaVerify('sendTheAppToSmS');
    
        if (!recaptcha) return;
    
        sendMeTheApp(phone, recaptcha)
          .then((data) => {
            if (data.kind === ApiResultsKind.OK) {
              fireTSuccess(data.token, {});
              return;
            }
            fireTError(data.token, {});
          });
        setShowMessage(true);
        setError(false);
        setIsLoading(false);
      } catch (e) {
        setShowMessage(true);
        setError(true);
        setIsLoading(false);
      }
    } else {
      setShowMessage(true);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalCloseButton onClick={onClose}>
          <TkIcon iconSize={22} className="fas fa-times" color="navyBlue" />
        </ModalCloseButton>
      </ModalHeader>
      <ModalContent>
        <TextContainer>
          <HeaderText>Get started right now!</HeaderText>
          <SubtitleText>
            We will send you a SMS message with a link to your phone
          </SubtitleText>
        </TextContainer>
        <Grid container spacing={0} xs={12} direction="row">
          <Paper
            elevation={3}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "row",
            }}
          >
            {showMessage ? (
              <ResponseMessage
                error={error}
                onClose={() => setShowMessage(false)}
              />
            ) : (
              <>
                <Grid item sm={9} md={10}>
                  <PhoneInput
                    inputStyle={{
                      height: 40,
                      width: "100%",
                      border: 0,
                    }}
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{ border: 0 }}
                    placeholder="Your number"
                    value={phone}
                    onChange={setPhone}
                  />
                </Grid>
                <Grid item sm={3} md={2}>
                  <PhoneInputButton
                    bgcolor="babyBlue"
                    color="white"
                    variant="contained"
                    onClick={handleSend}
                  >
                    {isLoading ? "Texting..." : "Text me the App!"}
                  </PhoneInputButton>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </ModalContent>
    </ModalContainer>
  );
};

export default SendMeTheAppModal;
