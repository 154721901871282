import React, { useState, forwardRef, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid } from '@material-ui/core';
import TkIcon from '@components/TkIcon';
import { DateTime, Interval } from 'luxon';

import { TkDatePickerWrapper } from './styles';
import { useUserSettings } from '@hooks/index';
import { selectWeekFromDate } from '@helpers/dateHelpers';

const ActivatorSlot = styled.div`
  width: 100%;
`;

const ComponentInput = forwardRef((props: any, ref) => {
  const activatorClone = React.cloneElement(props.activator, {
    value: props.value,
  });
  return (
    <ActivatorSlot
      onClick={() => {
        props.setCurrentView('false');
        props.onClick();
      }}
    >
      {activatorClone}
    </ActivatorSlot>
  );
});

const TkDatePicker = (props: any) => {
  const { startDate, handleDateChange, activator } = props;

  const { userSettings } = useUserSettings();
  const [currentView, setCurrentView] = useState('false');

  const startWeekDayIsSunday = userSettings.first_day === 'sunday';

  const memoizedHighlightDates = useMemo(() => {
    if (props.weekly) {
      const selectedDate = DateTime.fromJSDate(startDate);
      const weekInterval = selectWeekFromDate(selectedDate, startWeekDayIsSunday);

      return weekInterval.splitBy({ days: 1 }).map((interval) => interval.start.toJSDate());
    }
    return false;
  }, [startDate, startWeekDayIsSunday]);

  const CustomHeader = (props: any) => {
    let [month, year] = props.title.split(' ');
    return (
      <Grid
        container
        justify="space-between"
        className="react-datepicker__custom-header"
      >
        <Grid item xs={2}>
          <span hidden={!props.showPrevMonth} onClick={props.decreaseMonth}>
            <TkIcon className="fas fa-chevron-left" color="primary" />
          </span>
        </Grid>
        <Grid item xs={8}>
          {currentView === 'false' ? (
            <>
              <span onClick={() => setCurrentView('month')}>{month}</span>&nbsp;
            </>
          ) : (
            ''
          )}
          <span onClick={() => setCurrentView('year')}>{year}</span>
        </Grid>
        <Grid item xs={2}>
          <span hidden={!props.showNextMonth} onClick={props.increaseMonth}>
            <TkIcon className="fas fa-chevron-right" color="primary" />
          </span>
        </Grid>
      </Grid>
    );
  };

  const viewChangeMapping: any = {
    year: 'month',
    month: 'false',
  };

  const handleViewChangeBasedOnCurrentView = (view: string): string => {
    return viewChangeMapping[view] ?? undefined;
  };

  useEffect(() => {
    if (typeof currentView != 'undefined') {
      setCurrentView(handleViewChangeBasedOnCurrentView(currentView));
    }
  }, [startDate]);

  return (
    <TkDatePickerWrapper>
      <DatePicker
        {...props}
        selected={startDate}
        timeFormat="HH:mm"
        onChange={(date: any, _) => handleDateChange(date)}
        customInput={
          props.activator && (
            <ComponentInput
              setCurrentView={(value: string) => setCurrentView(value)}
              activator={activator}
            />
          )
        }
        renderCustomHeader={(props: any) => {
          if (currentView !== 'year') {
            let title = DateTime.fromJSDate(props.date).toFormat('LLLL y');
            return (
              <CustomHeader
                title={title}
                increaseMonth={props.increaseMonth}
                decreaseMonth={props.decreaseMonth}
                showPrevMonth={!props.prevMonthButtonDisabled}
                showNextMonth={!props.nextMonthButtonDisabled}
              />
            );
          }

          return '';
        }}
        highlightDates={[
          {
            'react-datepicker__day--highlighted_week': memoizedHighlightDates,
          },
        ]}
        showPopperArrow={false}
        adjustDateOnChange={false}
        showYearPicker={currentView == 'year'}
        showMonthYearPicker={currentView == 'month'}
        shouldCloseOnSelect={currentView == 'false'}
        calendarStartDay={startWeekDayIsSunday ? 0 : 1}
      />
    </TkDatePickerWrapper>
  );
};

export default TkDatePicker;
