import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { HttpStatusCode } from '@consts/api';
import { LocalStorage } from '@consts/index';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const authAccessToken = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
  const authHeader = authAccessToken
    ? {
        Authorization: `Bearer ${authAccessToken}`,
      }
    : {};
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...authHeader,
    },
  };

  return newConfig;
};

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => handleResponseError(error)
);
const handleResponseError = (error: AxiosError): AxiosError => {
  const { response } = error;
  if (checkIsUnauthorized(response)) {
    handleLogout();
    throw Error;
  }
  throw error;
};

export const handleLogout = (): void => {
  const localStorageItemsToDelete = [
    LocalStorage.TASKBIRD_SESSION,
    LocalStorage.ACCESS_TOKEN,
    LocalStorage.ACCOUNT_ID,
    LocalStorage.PROJECT_IN_PROGRESS,
    LocalStorage.COLLAPSE_PROJECTS,
    LocalStorage.FIRST_DAY,
    LocalStorage.TIME_FORMAT,
    LocalStorage.SEND_NOTIFICATION,
    LocalStorage.SEND_NOTIFICATION_TIMESTAMP,
    LocalStorage.BOOKING_TOKEN,
    LocalStorage.BOOKING_BUSINESS_HASH,
    LocalStorage.BOOKING_BUSINESS_FIRST_ACCESS,
    LocalStorage.BOOKING_SLUG,
    LocalStorage.BOOKING_SLUG_UPDATED,
    LocalStorage.USER_CHAT_PROFILE,
  ];

  for (const item of localStorageItemsToDelete) {
    localStorage.removeItem(item);
  }
};

const checkIsUnauthorized = (response: AxiosResponse): boolean => {
  const isUnauthorized = response?.data?.message === 'Unauthenticated.';
  return response?.status === HttpStatusCode.UNAUTHORIZED && isUnauthorized;
};

export default api;
