import { atom } from 'recoil';

export const emptyUserData: IUser = {
  user: {
    id: 0,
    badges: [],
    created_at: '',
    device_name: null,
    email: '',
    first_name: '',
    full_name: '',
    full_photo_url: null,
    last_name: '',
    locale: 'en',
    password: '',
    phone_number: null,
    phone_validated_at: null,
    phone_validation_required: false,
    social_media_id: null,
    social_media_provider: null,
    street_address: '',
  },
  account: {
    badges: [],
    hasIntegration: false,
    id: 0,
    moveoutOptIn: false,
    canOptInMoveout: false,
    isMandatoryChecklistDefault: false,
    name: '',
    settings: {
      editAvailability: false,
      showContactInformation: false,
      showCustomerName: false,
      showTaskSource: false,
      showWebOnboarding: false,
      showOnboarding: false,
    },
    website: null,
    websiteHash: null,
  },
  expires_at: '',
  token_type: '',
  is_new_user: false,
  permission_id: 0,
  access_token: '',
  chat_profile: {
    id: null,
    user_token: null,
  },
};

const userData = atom<IUser>({
  key: 'userData',
  default: emptyUserData,
});

export { userData };
