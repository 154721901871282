import React, { lazy, useEffect } from 'react';

import { Settings } from 'luxon';
import { Switch, useLocation } from 'react-router-dom';

import { TkRoute } from './TkRoute';
import { routes } from './routes';
import {
  AppLocationURLs,
  LocalStorage,
  AppLocationURLsPageNames,
} from '@consts/index';
import { withAuth } from '@contexts/AuthContext/hocs';
import { ChatProvider, useMixpanelDetectPage } from '@contexts/index';
import { useUserSettings } from '@hooks/index';

const DashboardPage = lazy(() => import('@pages/Dashboard'));

const Router = (): JSX.Element => {
  const { userSettings } = useUserSettings();

  const location = useLocation();
  const pageName = AppLocationURLsPageNames[location.pathname];
  useMixpanelDetectPage(pageName);

  useEffect(() => {
    Settings.defaultLocale =
      userSettings?.time_format === '24' ? 'en-GB' : 'en-US';

    if (window.location.pathname === AppLocationURLs.CALENDAR_SELECTION) {
      localStorage.setItem(LocalStorage.GOOGLE_CALENDAR_SELECTION, 'true');
      window.close();
    } else {
      localStorage.removeItem(LocalStorage.GOOGLE_CALENDAR_SELECTION);
    }
  }, [userSettings?.time_format]);

  useEffect(() => {
    Settings.defaultLocale =
      userSettings?.time_format === '24' ? 'en-GB' : 'en-US';
  }, [userSettings?.time_format]);

  const AppRoutes = (routes) => {
    return routes.map((route) => (
      <TkRoute
        key={route.path}
        path={route.path}
        component={route.component}
        exact={route.exact}
        blockResponsive={route.blockResponsive}
        blockResponsiveBookingSite={route.blockResponsiveBookingSite}
      />
    ));
  };

  return (
    <Switch>
      <ChatProvider>
        <TkRoute
          path={AppLocationURLs.HOME}
          component={withAuth(DashboardPage)}
          exact
        />
        {AppRoutes(routes)}
      </ChatProvider>
    </Switch>
  );
};

export default Router;
