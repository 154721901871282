import React from 'react';
import { IconButton, Avatar } from '@mui/material';
import { generateInitials } from '@helpers/index';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { getSessionUserState } from '@contexts/AuthContext/state';

interface IAvatar {
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ScTkAvatarWrapper = styled.div`
  position: relative;
  margin-left: 0.75rem;
`;

const TkAvatar = (props: IAvatar): JSX.Element => {
  const user = useRecoilValue(getSessionUserState);

  return (
    <ScTkAvatarWrapper>
      <IconButton
        onClick={props.openMenu}
        edge="end"
        aria-label="account of current user"
      >
        {user.full_photo_url === null ? (
          <Avatar>{generateInitials(user.full_name)}</Avatar>
        ) : (
          <Avatar src={user.full_photo_url} />
        )}
      </IconButton>
    </ScTkAvatarWrapper>
  );
};

export default TkAvatar;
