import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ProjectOffer } from 'types/projectOffer.type';

import { Grid } from '@mui/material';

import { OfferDrawerTags } from '../MicroComponents';
import { ITkExtendedOfferDetailsDrawerEnum } from '../TkExtendedOffersDetailsDrawer';
import {
  TkTaskWrapperItem,
  TkTaskWrapperItemFilled,
  TkTaskWrapperSubItem,
  HorizontalDivider,
} from '../styles';
import {
  TkIcon,
  TkTypography,
  TkDrawerHeader,
  TkDrawerTaskName,
  TkDrawerTimeInfo,
  TkDrawerCustomWarning,
  TkDrawerCustomerName,
  TkDrawerPrice,
  TkDrawerAdditionalInfoMoveout,
} from '@components/index';
import { formatDistanceFromPlace } from '@helpers/index';
import { offersDetailsExtendedDrawer } from '@recoilData/index';

interface IContentRendersMoveout {
  offer: ProjectOffer;
  handleClose: () => void;
  handleBack: () => void;
}

// Deprecated - Moveout is not used anymore
const Moveout: React.FC<IContentRendersMoveout> = ({
  offer,
  handleClose,
  handleBack,
}) => {
  const { t } = useTranslation();

  const [extendedDrawer, setExtendedDrawer] = useRecoilState(
    offersDetailsExtendedDrawer
  );

  const handleExtendedDrawer = (type: string) => {
    setExtendedDrawer({
      open: extendedDrawer.open && extendedDrawer.type === type ? false : true,
      type,
    });
  };

  return (
    <>
      <TkDrawerHeader
        id={offer.id}
        handlers={{
          handleClose,
          handleBack,
        }}
        dismissIcon="fa fa-times"
        showBackButton
      />

      <TkDrawerTaskName
        name={offer.title}
        setName={() => null}
        setEdited={() => null}
        displayOnly
      />

      <OfferDrawerTags offer={offer} />

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerTimeInfo start={offer.start} finish={offer.end} />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="0 1rem 5px">
        <TkDrawerCustomWarning
          message={t('contactCustomerWarning', { ns: 'offers' })}
          icon="fa fa-info-circle"
          type="message"
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItemFilled>
        <TkTaskWrapperSubItem>
          <TkDrawerCustomerName
            customerName={offer.customerName}
            firstNameOnly
          />
        </TkTaskWrapperSubItem>

        <Grid container style={{ padding: '0 1rem' }}>
          <HorizontalDivider color="solid" />
        </Grid>

        <TkTaskWrapperSubItem
          onClick={() =>
            handleExtendedDrawer(ITkExtendedOfferDetailsDrawerEnum.LOCATION)
          }
          color={
            extendedDrawer.open &&
            extendedDrawer.type === ITkExtendedOfferDetailsDrawerEnum.LOCATION
              ? 'selected'
              : ''
          }
        >
          <Grid container direction="row" alignItems="center">
            <TkIcon
              className="fa fa-map-marker-alt"
              iconSize={14}
              width={12}
              marginRight={10}
            />
            <TkTypography fontFamily="Lato" fontSize={16}>
              {`${t('location', { ns: 'common' })}: `}
            </TkTypography>
            <TkTypography
              marginLeft="5px"
              marginRight="5px"
              fontFamily="Lato"
              fontSize={16}
              fontWeight="bold"
            >
              {formatDistanceFromPlace(
                offer.travelDistance,
                offer.travelDistanceUnit
              )}
            </TkTypography>
            <TkTypography fontFamily="Lato" fontSize={16}>
              {t('fromYourServiceArea', { ns: 'offers' })}
            </TkTypography>
          </Grid>
        </TkTaskWrapperSubItem>
      </TkTaskWrapperItemFilled>

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerPrice
          price={offer.price}
          paymentType={offer.projectOfferPaymentType}
          client={offer.client}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem style={{ padding: '0 1rem' }}>
        <HorizontalDivider />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem
        padding="0.5rem"
        margin="0.5rem"
        onClick={() =>
          handleExtendedDrawer(
            ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_MOVEOUT
          )
        }
        bgcolor={
          extendedDrawer.open &&
          extendedDrawer.type ===
            ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_MOVEOUT
            ? 'filled'
            : ''
        }
      >
        <TkDrawerAdditionalInfoMoveout location={offer.location} />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem style={{ padding: '0 1rem' }}>
        <HorizontalDivider />
      </TkTaskWrapperItem>
    </>
  );
};

export default React.memo(Moveout);
