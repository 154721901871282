import { isValidPhoneNumber } from 'libphonenumber-js';
import { z } from 'zod';

export const profileSchema = z
  .object({
    first_name: z.string().min(1).max(100),
    last_name: z.string().min(1).max(100),
    email: z.string().email(),
    phone_number: z.string().refine((val) => isValidPhoneNumber(val)),
    street_address: z.string().optional().nullable(),
    timezone: z.string(),
    locale: z.string(),
    password: z.string().min(8, 'mustBeAtLeast8Char').optional(),
    confirm_password: z.string().min(8, 'mustBeAtLeast8Char').optional(),
    full_photo_url: z.string().or(z.instanceof(File)).optional().nullable(),
    company_name: z.string().min(1),
  })

  .refine((data) => data.password === data.confirm_password, {
    message: 'Passwords do not match',
    path: ['confirm_password'],
  });

export const accountSchema = z.object({
  id: z.number(),
  name: z.string(),
  website: z.string().nullable(),
  websiteHash: z.string().nullable(),
  badges: z.array(z.string()),
  hasIntegration: z.boolean(),
  hasProject: z.boolean(),
  hasUserInvite: z.boolean(),
  isMandatoryChecklistDefault: z.boolean(),
  settings: z.object({
    showCustomerName: z.boolean(),
    showTaskSource: z.boolean(),
    editAvailability: z.boolean(),
    showContactInformation: z.boolean(),
    showWelcomeGuide: z.boolean(),
  }),
});

export type UpdateUserData = z.infer<typeof profileSchema>;
