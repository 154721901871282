export default {
  moveout_cleaning: 'Move-out cleaning',
  requested_date: 'Requested Date',
  contactCustomerWarning:
    'Contact the customer to arrange times after accepting',
  canceled_warning: 'This task has been canceled',
  requests_plural: 'Requests',
  requests_singular: 'Request',
  offer_plural: 'Offers',
  offer_singular: 'Offer',
  offer_price_person: 'Price: {{price}}/hour per person',
  offer_price_project: 'Price: {{price}} per project',
  pricePerHour: '{{price}} per hour per person',
  pricePerHourTurno: '{{price}} per hour',
  pricePerProject: '{{price}} per project',
  acceptAll: 'Accept All',
  rejectAll: 'Reject All',
  confirmAccept: 'Are you sure you want to accept all requests?',
  confirmReject: 'Are you sure you want to reject all the requests?',
  acceptedSuccessfully: '{{offerCount}} requests accepted successfully',
  acceptedSuccessfullyWithErrors: `{{offerCount}} requests accepted successfully. {{offerCountError}} requests were not available.`,
  rejectedSuccessfully: '{{offerCount}} requests rejected successfully',
  rejectedSuccessfullyWithErrors: `{{offerCount}} requests rejected successfully. {{offerCountError}} requests were not available.`,
  errorMultiple: 'Error on updating the requests',
  offersSingular: '{{projectOffersCount}} Request',
  offersPlural: '{{projectOffersCount}} Requests',
  acceptMultiplePlural: 'Accept {{projectOffersCount}} requests',
  acceptMultipleSingular: 'Accept {{projectOffersCount}} request',
  rejectMultiplePlural: 'Reject {{projectOffersCount}} requests',
  rejectMultipleSingular: 'Reject {{projectOffersCount}} request',
  OfferAlreadyAccepted: 'This request is no longer available',
  yes: 'Yes',
  no: 'No',
  assign: 'Assign',
  undo: 'Undo',
  accept: 'Request Accepted',
  reject: 'Request Rejected',
  error: 'This request is not available',
  pending: 'Pending',
  requests: 'Requests',
  additionalInfo: 'Additional info',
  bedroom_one: 'Bedroom:',
  bedroom_other: 'Bedrooms:',
  bathroom_one: 'Bathroom:',
  bathroom_other: 'Bathrooms:',
  unit_size: 'Unit Size:',
  requestError: 'This request is not available',
  requestPending: 'Pending',
  sq_ft: 'sq. ft.',
  fromYourServiceArea: 'from your service area',
  onlyAdminsCanSeePrice: 'Only admins can see this price',
  priceNotSet: 'Price not set',
  seeRequests: 'See requests',
  youHaveXRequests: 'You have {{count}} requests',
  doYouWantToCheckThemNowQuestion: 'Do you want to check them now?',
  dontShowAgain: "Don't show again",
  serviceDetails: 'Service details',
  estimatedPriceWithValue: 'Estimated price: <0>{{price}}</0>',
  estimatedPriceWithValueString: 'Estimated price: {{price}}',
  bookingWidget: {
    domesticCleaning: 'Domestic cleaning',
    estimatedPrice: 'Estimated price',
    estimatedTime: 'Estimated time',
    hoursMinutes: '{{hour}}hrs {{min}}min',
    hours: '{{hour}}hrs',
  },
  moreXQuestions: '+{{count}} more',
  acceptingRequest: 'Accepting Request',
  rejectingRequest: 'Rejecting Request',
  items: {
    number_of_bathrooms: 'Bathrooms',
    number_of_bedrooms: 'Bedrooms',
    size: 'Unit Size',
    guest_arrives_same_day: 'Same day arrival',
    next_booking_info: 'Next booking info',
    next_guest_name: "Next guest's name",
  },
  turnoOfferAlreadyAccepted: 'This request was already accepted in Turno',
  cantAcceptInvalidTurnoOffer: 'This request is not available',
  turnoOfferAlreadyRejected: 'This request has been rejected',
};
