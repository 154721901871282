import React from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton } from '@mui/material';

import { ContentWrapper, WeekItem } from '../styles';
import { TkIcon } from '@components/index';

interface RecurrentDaysProps {
  weekOptionList: Set<number>;
  selectedWeekDay: (id: number, checked: boolean) => void;
  isWeekRulesLoading: boolean;
}

const RecurrentDays = ({
  weekOptionList,
  selectedWeekDay,
  isWeekRulesLoading,
}: RecurrentDaysProps): JSX.Element => {
  const { t } = useTranslation();

  const weekList = [
    { id: 0, value: 'su', display: 'sunday', checked: false },
    { id: 1, value: 'mo', display: 'monday', checked: false },
    { id: 2, value: 'tu', display: 'tuesday', checked: false },
    { id: 3, value: 'we', display: 'wednesday', checked: false },
    { id: 4, value: 'th', display: 'thursday', checked: false },
    { id: 5, value: 'fr', display: 'friday', checked: false },
    { id: 6, value: 'sa', display: 'saturday', checked: false },
  ];

  const generateItem = (display) =>
    t(`weekdays.${display}`, { ns: 'common' }).substring(0, 3);

  const isWeekSelected = (id: number): boolean => {
    return weekOptionList.has(id) ? true : false;
  };

  return (
    <ContentWrapper>
      {isWeekRulesLoading ? (
        <Skeleton variant="rounded" width="100%" height={35} />
      ) : (
        weekList.map((i) => (
          <WeekItem
            key={i.id}
            checked={isWeekSelected(i.id)}
            onClick={() => selectedWeekDay(i.id, !isWeekSelected(i.id))}
          >
            {isWeekSelected(i.id) ? (
              <TkIcon className="far fa-times" iconSize={28} />
            ) : (
              generateItem(i.display)
            )}
          </WeekItem>
        ))
      )}
    </ContentWrapper>
  );
};

export default RecurrentDays;
