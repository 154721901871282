import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { ModalContent, ModalActions, TextModal, SubTextModal } from './styles';
import { TkTypography, DynamicButton, TkIcon } from '@components/index';
import { useModal } from '@contexts/index';

interface MarkAsCompleteModalProps {
  action: () => void;
  type:
    | 'markAsComplete'
    | 'mandatoryChecklist'
    | 'pendingTeammate'
    | 'lastNotCompleted'
    | 'lastInProgress';
  count?: number;
  assigneesNames?: string;
}

const MarkAsCompleteModal = ({
  action,
  type,
  count = 0,
  assigneesNames = '',
}: MarkAsCompleteModalProps): JSX.Element => {
  const { handleCloseModal } = useModal();

  const { t } = useTranslation(['events']);

  const modalOptions = {
    markAsComplete: {
      title: 'modalComplete.title',
      subText: t('modalComplete.subText'),
      actionText: 'modalComplete.markAsComplete',
      modalSize: '420px',
      icon: null,
    },
    mandatoryChecklist: {
      title: 'modalMandatory.title',
      subText: t('modalMandatory.subText'),
      actionText: 'modalMandatory.goToChecklist',
      modalSize: '388px',
      icon: { name: 'fal fa-exclamation-triangle', color: '#F57846', size: 72 },
    },
    pendingTeammate: {
      title: 'modalTeammate.title',
      subText: t('modalTeammate.removeTeammateText', {
        count: count,
        name: assigneesNames,
      }),
      actionText: 'modalTeammate.removeTeammate',
      modalSize: '420px',
      icon: null,
    },
    lastNotCompleted: {
      title: 'modalTeammate.title',
      subText: t('modalTeammate.removeTeammateText', {
        count: count,
        name: assigneesNames,
      }),
      actionText: 'modalComplete.markAsComplete',
      modalSize: '420px',
      icon: null,
    },
    lastInProgress: {
      title: 'modalTeammate.title',
      subText: t('modalTeammate.removeTeammateText_plural', {
        count: count,
        name: assigneesNames,
      }),
      actionText: 'modalComplete.markAsComplete',
      modalSize: '420px',
      icon: null,
    },
  };

  const currentModal = modalOptions[type];

  return (
    <>
      <ModalContent width={currentModal.modalSize}>
        <div>
          {currentModal.icon && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TkIcon
                className={currentModal.icon.name}
                iconSize={currentModal.icon.size}
                color={currentModal.icon.color}
                width={81}
              />
            </div>
          )}
          <TextModal>
            <TkTypography
              fontFamily="Muli"
              fontWeight="bold"
              fontSize={24}
              color="#011F41"
            >
              {t(currentModal.title)}
            </TkTypography>
          </TextModal>
          <SubTextModal
            container //@ts-ignore
            justify="center"
            alignItems="center"
            marginBottom="5px"
          >
            <TkTypography
              fontFamily="Lato"
              fontWeight="normal"
              fontSize={16}
              color="#011F41"
            >
              {currentModal.subText}
            </TkTypography>
          </SubTextModal>
        </div>
      </ModalContent>
      <ModalActions>
        <Grid container>
          <Grid item xs={6}>
            <DynamicButton color="primary" onClick={handleCloseModal}>
              {t('cancel', { ns: 'common' })}
            </DynamicButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              onClick={action}
            >
              {t(currentModal.actionText, { count: count })}
            </DynamicButton>
          </Grid>
        </Grid>
      </ModalActions>
    </>
  );
};

export default MarkAsCompleteModal;
