import api from './api';
import { ApiResultsKind } from '@consts/index';
import { encodedUserTimezone } from '@helpers/TimezoneHelper';
import camelize from '@helpers/camelize';

const fetchProjectsOffers = ({
  id,
  today,
}: GetProjectOffersInput): Promise<GetProjectOffersResult> =>
  api
    .get(
      `/account/${id}/projectOffer?group_by=start_day&start_time_from=${today}&timezone=${encodedUserTimezone()}`
    )
    .then((response) => ({
      kind: ApiResultsKind.OK,
      projectOffers: response.data,
    }))
    .catch(() =>
      Promise.reject({ kind: ApiResultsKind.ERROR, projectOffers: null })
    );

const updateProjectOffers = ({
  accountId,
  ids,
  type,
}: UpdateProjectOffersInput): Promise<UpdateProjectOffersResult> =>
  api
    .post(`/projectOffer/${type}?account_id=${accountId}`, {
      project_offers: ids,
    })
    .then((response) => ({
      kind: ApiResultsKind.OK,
      projectOffers: response.data,
    }))
    .catch(() => {
      return Promise.reject({ kind: ApiResultsKind.ERROR, projectOffers: [] });
    });

const saveNewCalendlyEvent = ({
  accountId,
  uuid,
  uri,
  eventService,
  eventType,
  userId,
}: SaveNewCalendlyEventInput): Promise<BaseResult> =>
  api
    .post(`account/${accountId}/scheduledEvent`, {
      external_id: uuid,
      external_url: uri,
      scheduled_event_service: eventService,
      scheduled_event_type: eventType,
      user_id: userId,
    })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() => {
      return Promise.reject({ kind: ApiResultsKind.ERROR });
    });

const getScheduledCalls = ({
  accountId,
  scheduledEventType,
}: GetScheduledCallsInput): Promise<GetScheduledCallsResult> =>
  api
    .get(
      `/account/${accountId}/scheduledEvent${
        scheduledEventType ? `?scheduled_event_type=${scheduledEventType}` : ''
      }`
    )
    .then((response) => ({
      kind: ApiResultsKind.OK,
      scheduledCalls: camelize<IScheduledCall[]>(response.data),
    }))
    .catch(() =>
      Promise.reject({ kind: ApiResultsKind.ERROR, scheduledCalls: [] })
    );

const updateProjectOffer = ({ accountId, id, type }: UpdateProjectOfferInput) =>
  api
    .post(
      `/projectOffer/${type}?account_id=${accountId}&project_offer_id=${id}`
    )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error.message);
      return Promise.reject(null);
    });

const sendMeTheApp = async (
  phoneNumber: string,
  recaptcha: string
): Promise<SendMeTheAppResult> => {
  return await api
    .post('send-me-the-app/sms/', {
      recaptcha,
      phone_number: `+${phoneNumber}`,
    })
    .then(() => {
      return {
        kind: ApiResultsKind.OK,
        token: 'unavailableFeature:sendTheAppSuccess',
      };
    })
    .catch(() => {
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        token: 'unavailableFeature:sendTheAppError',
      });
    });
};

const createNewTeam = (
  name: string,
  ownerUserId: number
): Promise<CreateNewTeamResult> =>
  api
    .post('/account', { name: name, owner_user_id: ownerUserId })
    .then((res) => {
      return {
        kind: ApiResultsKind.OK,
        data: res.data,
        token: 'createNewTeam:success',
      };
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        data: null,
        token: 'createNewTeam:error',
      });
    });

const acceptInvite = (
  code: string,
  userId: number
): Promise<AcceptInviteResult> =>
  api
    .post(`user/${userId}/acceptInvite`, {
      invitation_token: code,
    })
    .then((res) => {
      return {
        kind: ApiResultsKind.OK,
        data: res.data,
        token: 'acceptInvite:success',
      };
    })
    .catch((e) => {
      console.error(e);
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        data: null,
        token: 'acceptInvite:error',
      });
    });

export {
  acceptInvite,
  sendMeTheApp,
  createNewTeam,
  getScheduledCalls,
  updateProjectOffer,
  updateProjectOffers,
  fetchProjectsOffers,
  saveNewCalendlyEvent,
};
