import styled from 'styled-components';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  logoContent: {
    width: 16,
    height: 16,
  },
  menuPaper: {
    maxHeight: 370,
    marginTop: 5,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  buttonProjectOffer: {
    border: 1,
    color: 'black',
  },
});

export const styleNotifications = {
  style: {
    borderRadius: '2px',
    background: '#FFFFFF',
    width: 333,
    height: 'auto',
    padding: 2,
  },
};

export const TopBarWrapper = styled.div`
  height: 64px;
`;

export const ScTkTopbarRightActions = styled.div`
  display: flex;
  align-items: center;
`;

export const ScTkTopBarUser = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
`;
