import { DateTime } from 'luxon';

import { DayType, DayTypeApi } from './types';

const FORMAT_PATTERN = 'yyyy-MM';

export const generateFormattedDate = (
  date: DateTime,
  format = FORMAT_PATTERN
): string => date.toFormat(format);

export const generateDates = (
  weekdayId: number,
  currentDate: DateTime,
  daysMap: Set<DayType>,
  checked: boolean
): Set<DayType> => {
  const daysInTheMonth = currentDate.daysInMonth;
  const dateRange = new Set<string>();
  let firstDayInTheWeekday = currentDate.set({
    weekday: weekdayId === 0 ? 7 : weekdayId,
    year: currentDate.year,
  });

  if (firstDayInTheWeekday.month !== currentDate.month) {
    firstDayInTheWeekday = firstDayInTheWeekday.plus({ days: 7 });
  }

  for (let i = 0; i <= daysInTheMonth; i += 7) {
    const date = firstDayInTheWeekday.plus({ days: i });

    if (date.month !== currentDate.month) {
      break;
    }

    dateRange.add(date.toFormat('d'));
  }

  dateRange.forEach((day) => {
    if (checked) daysMap.add(day);
    else daysMap.delete(day);
  });

  return new Set(daysMap);
};

export const generateMultipleDates = (
  weekdays: Set<number>,
  currentMonth: DateTime,
  daysMap: DayType
): Set<string> => {
  const currentDays = new Set<string>(daysMap);

  const daysInWeek = [0, 1, 2, 3, 4, 5, 6];

  daysInWeek.forEach((weekday) => {
    const hasWeekday = weekdays.has(weekday);

    generateDates(weekday, currentMonth, currentDays, hasWeekday);
  });

  return currentDays;
};

export const generateFinalDates = (
  weekdays: Set<number>,
  monthDays: Map<string, Set<DayType>>
): DayTypeApi[] => {
  const selectedDays = new Set<DayTypeApi>();

  monthDays.forEach((days, month) => {
    const monthDate = DateTime.fromFormat(month, FORMAT_PATTERN);

    days.forEach((day) => {
      const thisDay = monthDate.set({ day: Number(day) });
      const weekday = thisDay.weekday === 7 ? 0 : thisDay.weekday;

      if (weekdays.has(weekday)) return;
      selectedDays.add({
        day: thisDay.toFormat('yyyy-MM-dd'),
        is_available: false,
      });
    });
  });

  return Array.from(selectedDays);
};
