import React, { useState, useContext, useEffect } from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import { Dialog } from '@mui/material';

import ModalContext from './index';
import { modalQueueIndexData, modalQueueData } from '@recoilData/index';

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({
  children,
}: ModalProviderProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [forceCloseLocal, setForceClose] = useState(false);
  const [modalContent, setModalContent] = useState<Modal>('');
  const [modalQueueIndex, setModalQueueIndex] =
    useRecoilState(modalQueueIndexData);
  const [modalQueue, setModalQueue] = useRecoilState(modalQueueData);
  const [modalOptions, setModalOptions] = useState<ModalQueueItemOptions>({
    disableBackdropClick: false,
  });

  const resetModalQueue = useResetRecoilState(modalQueueData);
  const resetModalQueueIndex = useResetRecoilState(modalQueueIndexData);

  useEffect(() => {
    if (modalQueue.length === 0) {
      return;
    }

    if (!forceCloseLocal && modalQueueIndex < modalQueue.length) {
      const modalItem = modalQueue[modalQueueIndex];
      setModalContent(modalItem.modal);
      setModalOptions(modalItem.options);
      setOpen(true);
      return;
    }

    setOpen(false);
    setModalContent('');
    setModalOptions({
      disableBackdropClick: false,
    });
    clearModalQueue();
  }, [modalQueue, modalQueueIndex, forceCloseLocal]);

  /**
   * Verifies if the item whether or not exists in the queue
   * @param {ModalQueueItem} newModal
   * @returns boolean
   */
  const modalExistsIntoQueue = (newModal: ModalQueueItem) => {
    if (modalQueue.find((modal) => modal.modalId === newModal.modalId)) {
      return true;
    }

    return false;
  };

  /**
   * Add Modal to the Render Queue
   * @param {ModalQueueItem} newModal Modal content and Options.
   */
  const addModalToQueue = (newModal: ModalQueueItem) => {
    if (modalExistsIntoQueue(newModal)) {
      return;
    }

    setModalQueue([...modalQueue, newModal]);
  };

  /**
   * Add Modal to the Render Queue
   * @param {ModalQueueItem[]} newModalArray Modal content and Options.
   */
  const addBulkModalToQueue = (newModalArray: ModalQueueItem[]) => {
    const newModalArrayFiltered: ModalQueueItem[] = [];

    newModalArray.forEach((newModal) => {
      if (modalExistsIntoQueue(newModal)) return;

      newModalArrayFiltered.push(newModal);
    });

    setModalQueue([...modalQueue, ...newModalArrayFiltered]);
  };

  /**
   * Start Modal Render Queue from its first item if the queue is not empty.
   */
  const startModalQueue = () => {
    if (modalQueue.length === 0) {
      return;
    }

    const modalItem = modalQueue[0];
    setModalContent(modalItem.modal);
    setModalOptions(modalItem.options);
    setModalQueueIndex(1);
  };

  /**
   * Render the next item on the queue
   * when forceClose is true the Modal will close
   *
   * @param {boolean} forceClose Overrides the modal queue and closes the modal.
   */
  const resumeModalQueue = (forceClose = false): void => {
    const modalQueueIndexPlus = modalQueueIndex + 1;
    setModalQueueIndex(modalQueueIndexPlus);

    if (forceClose !== forceCloseLocal) setForceClose(forceClose);
  };

  /**
   * Remove Modal from the Render Queue using it's id
   * @param {string} modalId Modal Id to be removed from queue.
   */
  const removeModalFromQueue = (modalId: string) => {
    const filteredQueue = modalQueue.filter(
      (modal) => modal.modalId !== modalId
    );

    setModalQueue(filteredQueue);
  };

  /**
   * Clear Modal Queue
   */
  const clearModalQueue = () => {
    resetModalQueue();
    resetModalQueueIndex();
  };

  /**
   * Set Modal Content bypassing the Render Queue
   */
  const setupModal = ({
    content = '',
    disableBackdropClick = false,
  }: SetupModalParams): void => {
    if (content) {
      setModalContent(content);
      setModalOptions({
        disableBackdropClick,
      });
    }

    if (!open) {
      setOpen(true);
    }
  };

  /**
   * Close current and overrides the modal queue.
   */
  const handleCloseModal = (): void => {
    setModalContent('');
    setOpen(false);
  };

  const onCloseModal = (_event, _reason: 'backdropClick' | 'escapeKeyDown') => {
    if (modalOptions.disableBackdropClick) {
      return;
    }

    resumeModalQueue();
  };

  const hooks: ModalHook = {
    addBulkModalToQueue,
    addModalToQueue,
    clearModalQueue,
    handleCloseModal,
    removeModalFromQueue,
    setupModal,
    startModalQueue,
    resumeModalQueue,
  };

  return (
    <ModalContext.Provider value={hooks}>
      {children}
      <Dialog
        open={open}
        maxWidth={false}
        onClose={onCloseModal}
        disableEscapeKeyDown={modalOptions.disableBackdropClick}
      >
        {modalContent}
      </Dialog>
    </ModalContext.Provider>
  );
};

/**
 * Modal Provider Call Function
 */
export const useModal = (): ModalHook => useContext(ModalContext);
