import { createContext } from 'react';
import { Channel, DefaultGenerics } from 'stream-chat';

export interface IChatContext {
  isNewChannelBeingCreated: boolean;
  setCreateNewChannel: React.Dispatch<React.SetStateAction<boolean>>;
  createNewChannel: (
    members: IAssignee[],
    callback: (
      newChannel?: Channel<DefaultGenerics>,
      watchers?: {
        limit?: number;
        offset?: number;
      },
      // eslint-disable-next-line @typescript-eslint/ban-types
      event?: React.BaseSyntheticEvent<object, any, any>
    ) => void
  ) => Promise<void>;
}

export const ChatCustomContext = createContext({} as IChatContext);
