/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import {
  DeleteIconModal,
  ModalActions,
  ModalContent,
  TextModal,
} from '@components/TkModalAlert';
import { DynamicButton, TkIcon, TkTypography } from '@components/index';

export const DeleteTaskModal = ({
  handleCloseModal = () => {},
}: any): JSX.Element => {
  const { t } = useTranslation();
  return (
    <>
      <ModalContent
        style={{
          width: '388px',
          height: '212px',
        }}
      >
        <Grid container justifyContent="center">
          <DeleteIconModal
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkIcon
              className="fal fa-info-circle"
              iconSize={72}
              color="primary"
              width={81}
            />
          </DeleteIconModal>
          <TextModal
            container
            color="default"
            justifyContent="center"
            alignItems="center"
          >
            <Grid container justifyContent="center" direction="column">
              <Grid>
                <TkTypography
                  fontFamily="Lato"
                  fontWeight="normal"
                  fontSize={16}
                  textAlign="center"
                  color="#011F41"
                >
                  {t('deleteTaskFomTurno.title', { ns: 'modalAlert' })}
                </TkTypography>
              </Grid>
            </Grid>
          </TextModal>
        </Grid>
      </ModalContent>
      <ModalActions style={{ alignSelf: 'center' }}>
        <DynamicButton
          color="primary"
          variant="contained"
          style={{
            width: '142px',
            height: '36px',
          }}
          onClick={handleCloseModal}
          disableElevation
        >
          {t('ok', { ns: 'common' })}
        </DynamicButton>
      </ModalActions>
    </>
  );
};
