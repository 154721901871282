import React from 'react';
import styled from 'styled-components';

const PulsatingWrapper = styled.div`
  display: inline;
  position: relative;
  margin-right: 20px;
`;

const PulsatingCircle = styled.div`
  width: 10px;
  height: 10px;
  z-index: 2;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 5px);
  box-shadow: 0px 0px 8px #1592e5;
  background: #1f9bdd 0% 0% no-repeat padding-box;
`;

const PulsatingRing = styled.div`
  opacity: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 30px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  border: 3px solid #1f9bdd;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
  }
`;

const TkPulsatingIcon: React.FC = () => {
  return (
    <PulsatingWrapper>
      <PulsatingRing />
      <PulsatingCircle />
    </PulsatingWrapper>
  );
};

export default TkPulsatingIcon;
