import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Divider, Checkbox, CircularProgress } from '@mui/material';

import {
  ModalContent,
  ModalActions,
  ModalCalendarItem,
  ModalCalendarsWrapper,
  TkButton,
  Grid,
} from './styles';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import { TkTypography, TkLoadingBackground } from '@components/index';
import { ApiResultsKind } from '@consts/index';
import { useModal } from '@contexts/index';
import { useIntegrations } from '@hooks/index';
import { integrationsMethods } from '@services/index';

interface ISyncModal {
  accountId: number;
  handleSuccess: () => void;
  handleClose: () => void;
}

export const SyncModal: React.FC<ISyncModal> = ({
  accountId,
  handleClose,
  handleSuccess,
}) => {
  const { t } = useTranslation();
  const { loadGoogleCalendarApiData, syncToGoogleCalendar } =
    integrationsMethods;
  const { fireTError, fireTSuccess } = useSnackbar();
  const { handleDisableRemovedIntegrationNotification } = useIntegrations();
  const [loading, setLoading] = useState<boolean>(false);
  const [onSubmit, setOnSubmit] = useState<boolean>(false);
  const [calendars, setCalendars] = useState<IGoogleCalendar[]>([]);
  const [calendarRequiredError, setCalendarRequiredError] = useState(false);

  const token = (tok) => `integrationsPage:googleCalendar.syncModal.${tok}`;

  const handleSubmit = () => {
    if (loading || onSubmit || calendars.length === 0) {
      return;
    }

    const calendarsToSync = calendars.filter((calendar) => calendar.checked);
    if (calendarsToSync.length === 0) {
      setCalendarRequiredError(true);
      return;
    }

    setOnSubmit(true);
    syncToGoogleCalendar({
      accountId: accountId,
      calendars: calendarsToSync,
    })
      .then(({ kind }) => {
        setOnSubmit(false);
        if (kind === ApiResultsKind.OK) {
          handleSuccess();
          handleClose();
          fireTSuccess(token('syncSuccess'), {});
          handleDisableRemovedIntegrationNotification();
        }
      })
      .catch(() => {
        fireTError('common:defaultError', {});
      });
  };

  const handleToggleCheckbox = (event, key) => {
    setCalendarRequiredError(false);
    setCalendars(
      calendars.map((calendar) => {
        if (calendar.id === key) {
          return { ...calendar, checked: event.target.checked };
        }
        return calendar;
      })
    );
  };
  useEffect(() => {
    loading && setLoading(false);

    if (loading && calendars.length === 0) {
      handleClose();
    }
  }, [calendars]);

  useEffect(() => {
    setLoading(true);
    loadGoogleCalendarApiData({ accountId: accountId })
      .then(({ kind, calendars }) => {
        if (kind === ApiResultsKind.OK) {
          setCalendars(calendars);
        }
      })
      .catch(() => {});
  }, [accountId]);

  return (
    <>
      <ModalContent>
        <TkTypography
          fontSize={24}
          fontWeight="bold"
          fontFamily="Muli"
          textAlign="center"
        >
          {t(token('title'))}
        </TkTypography>
        <TkTypography fontWeight="bold" mt={6}>
          {t(token('selectCalendars'))}
        </TkTypography>
        {calendarRequiredError && (
          <TkTypography color="error" mt={3}>
            {t(token('calendarRequiredError'))}
          </TkTypography>
        )}
        <ModalCalendarsWrapper style={{ display: 'block' }} mt={3}>
          {loading ? (
            <TkLoadingBackground loading={loading} />
          ) : (
            calendars.map((calendar) => (
              <ModalCalendarItem
                container
                key={calendar.id}
                alignItems="start"
                className={calendarRequiredError ? 'calendarRequiredError' : ''}
              >
                <Checkbox
                  checked={calendar.checked}
                  onChange={(evt) => handleToggleCheckbox(evt, calendar.id)}
                />
                <Grid direction="column">
                  <TkTypography fontWeight="bold">
                    {calendar.title}
                  </TkTypography>
                  {calendar?.summary && (
                    <TkTypography fontSize={14}>
                      {calendar.summary}
                    </TkTypography>
                  )}
                </Grid>
              </ModalCalendarItem>
            ))
          )}
        </ModalCalendarsWrapper>
      </ModalContent>
      <Divider />
      <ModalActions>
        <Grid container>
          <Grid item xs={6}>
            <TkButton
              style={{ textTransform: 'capitalize' }}
              variant="text"
              color="primary"
              onClick={handleClose}
            >
              {t('cancel', { ns: 'common' })}
            </TkButton>
          </Grid>
          <Grid item container xs={6} justifyContent="end">
            <TkButton
              style={{ textTransform: 'capitalize' }}
              variant="contained"
              onClick={handleSubmit}
              disabled={onSubmit}
              startIcon={onSubmit && <CircularProgress size={20} />}
            >
              {t(token('syncCalendar'))}
            </TkButton>
          </Grid>
        </Grid>
      </ModalActions>
    </>
  );
};
