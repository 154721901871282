import React from 'react';

import { Grid } from '@mui/material';

import {
  TkInputEdit,
  ButtonCustom,
  TaskNameDisplay,
  TkTaskWrapperItem,
} from './styles';
import { useEditNameDisplay } from '../hooks/useEditNameDisplay';
import { TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { TkIconSelected } from '@web-ui/index';

const TkEditNameDisplay = (): JSX.Element => {
  const {
    name,
    event,
    editName,
    nameChange,
    showEditNameIcon,
    setEditName,
    setNameChange,
    applyNameChange,
    cancelNameChange,
    setShowEditNameIcon,
  } = useEditNameDisplay();
  return (
    <TkTaskWrapperItem padding="0 1rem">
      {editName ? (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <TkInputEdit
            defaultValue={name}
            disableUnderline
            onChange={(e) => setNameChange(e.target.value)}
          />
          <ButtonCustom
            color="#FFFFFF"
            style={{ marginRight: '10px' }}
            onClick={cancelNameChange}
          >
            <TkIcon
              className="fa fa-times"
              iconSize={16}
              color="primary"
              cursor="pointer"
            />
          </ButtonCustom>
          <ButtonCustom
            bgcolor="#4952a9"
            onClick={() => applyNameChange(nameChange)}
          >
            <TkIcon
              className="fa fa-check"
              iconSize={16}
              color="white"
              cursor="pointer"
            />
          </ButtonCustom>
        </Grid>
      ) : (
        <TaskNameDisplay
          container
          justifyContent="center"
          alignItems="center"
          onMouseEnter={() => setShowEditNameIcon(true)}
          onMouseLeave={() => setShowEditNameIcon(false)}
        >
          <TkTypography
            fontWeight="bold"
            fontSize={20}
            fontFamily="Muli"
            textAlign="center"
            marginTop={1}
            userselect="none"
          >
            {name}
            {showEditNameIcon && event.status === TaskStatusEnum.TODO && (
              <TkIconSelected
                className="fa fa-pencil"
                verticalAlign="middle"
                onClick={() => setEditName(true)}
              />
            )}
          </TkTypography>
        </TaskNameDisplay>
      )}
    </TkTaskWrapperItem>
  );
};

export default TkEditNameDisplay;
