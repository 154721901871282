import { selector, selectorFamily } from 'recoil';
import {
  assigneesData,
  filterAssignees,
  isAllFilterAssigneesSelected,
} from '@recoilData/index';
import { crewsData, filterCrews } from '@recoilData/atoms';
import { generateAssignee } from '@helpers/index';

const searchFilterAssigneeData = selectorFamily({
  key: 'searchFilterAssigneeData',
  get:
    (search: string) =>
    ({ get }) => {
      const assignees = get(assigneesData);
      if (!search) return assignees;
      return assignees.filter((item) => {
        return item.full_name
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
    },
});

const getFilteredAssignees = selector({
  key: 'getFilteredAssignees',
  get: ({ get }) => {
    const assignees = get(assigneesData);

    const newAssignees: IAssignee[] = [];

    assignees.forEach((assignee) => {
      const filterState = get(filterAssignees(assignee.id));
      newAssignees.push(generateAssignee(assignee, filterState));
    });

    return newAssignees;
  },
});

const resetFilteredAssignees = selector({
  key: 'resetFilteredAssignees',
  get: ({ get }) => get(getFilteredAssignees),
  set: ({ get, reset }) => {
    const assignees = get(assigneesData);
    const crews = get(crewsData);
    assignees.map((assignee) => {
      reset(filterAssignees(assignee.id));
    });

    crews.map((crew) => {
      reset(filterCrews(crew.id));
    });
  },
});

const setOnlyFilterAssignee = selector({
  key: 'setOnlyFilterAssignee',
  get: ({ get }) => get(getFilteredAssignees),
  set: ({ set, reset }, assigneeId) => {
    reset(resetFilteredAssignees);
    set(filterAssignees(assigneeId as number), true);
  },
});

const getFilteredAssigneesCount = selector({
  key: 'getFilteredAssigneesCount',
  get: ({ get }) => {
    const assignees = get(getFilteredAssignees);
    let count = 0;
    assignees.forEach((assignee) => {
      if (assignee.checked) count++;
    });
    return count;
  },
});

const selectAllFilterAssignees = selector<boolean>({
  key: 'selectAllFilterAssignees',
  get: ({ get }) => get(isAllFilterAssigneesSelected),
  set: ({ set, get }, newValue) => {
    const assignees = get(assigneesData);

    set(isAllFilterAssigneesSelected, newValue as boolean);

    assignees.map((assignee) => {
      set(filterAssignees(assignee.id), newValue as boolean);
    });
  },
});

export {
  searchFilterAssigneeData,
  getFilteredAssignees,
  selectAllFilterAssignees,
  getFilteredAssigneesCount,
  resetFilteredAssignees,
  setOnlyFilterAssignee,
};
