import { produce } from 'immer';
import { useMutation } from 'react-query';
import { useSetRecoilState } from 'recoil';

import {
  getSessionUserState,
  getUserAccountState,
  getUserSettingsState,
} from '@contexts/AuthContext/state';
import {
  SessionUser,
  SessionUserAccount,
} from '@contexts/AuthContext/types/session';
import { useAuth, useSnackbar } from '@contexts/index';
import { UpdateUserData } from '@pages/Profile/schema';
import api from '@services/api';

export const useUpdateUserProfile = () => {
  const { userInfo } = useAuth();
  const { fireTSuccess, fireTError } = useSnackbar();
  const updateSessionUser = useSetRecoilState(getSessionUserState);
  const updateAccountUser = useSetRecoilState(getUserAccountState);
  const updateUserSettings = useSetRecoilState(getUserSettingsState);

  return useMutation(
    async (updateUser: UpdateUserData) => {
      const formData = new FormData();
      formData.append('_method', 'PATCH');
      for (const [key, value] of Object.entries(updateUser).filter(
        ([, value]) => value !== undefined
      )) {
        if (key === 'full_photo_url' && value instanceof File) {
          delete updateUser.full_photo_url;
          formData.append('picture', value as File);
          continue;
        }
        formData.append(key, value as string);
      }
      formData.delete('timezone');
      const { data: user } = await api.post(
        `/user/${userInfo?.user.id}`,
        formData
      );

      const { data } = await api.post('/user-settings', {
        timezone: updateUser.timezone,
      });

      updateUserSettings(data.data);

      const account = await api.patch(`/account/${userInfo?.account.id}`, {
        name: updateUser.company_name,
      });

      return {
        user: user.data as SessionUser,
        account: account.data as SessionUserAccount,
      };
    },
    {
      onError: () => {
        fireTError('profileUpdateError', { ns: 'profile' });
      },
      onSuccess: (data) => {
        fireTSuccess('profileUpdated', { ns: 'profile' });
        updateSessionUser(data.user);
        updateAccountUser(data.account);
      },
    }
  );
};
