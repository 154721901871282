export enum AppLocationURLs {
  HOME = '/',
  CALENDAR = '/schedule',

  DASHBOARD = '/dashboard',

  // CHAT URLS
  CHAT = '/chat',

  // TEAMS URLS
  TEAM = '/team',

  //CUSTOMER URLS
  CUSTOMERS = '/customers',

  //PUBLIC ROUTES
  CALENDAR_SELECTION = '/CalendarSelection',
  CHECKLISTS = '/checklists',
  CHECKLISTS_ID = '/checklists/:id',
  INTERCOM = '/intercom',
  CALENDLY = '/calendly',
  LOGIN = '/login',
  SIGN_UP = '/signup',

  //INTEGRATIONS URLS
  INTEGRATIONS = '/integrations',

  //BOOKING PUBLIC URLS
  BOOKING_SITE_ID = '/booking-site/:id',
  BOOKING_SITE_IFRAME_ID = '/booking-site/iframe/:id',

  //BOOKING PRIVATE URLS
  BOOKING_SITE_SETTINGS = '/booking-site-settings',
  BOOKING_SITE_SETTINGS_ROUTE = '/booking-site-settings/:route',
}

export enum AppLocationURLsPageNames {
  '/' = 'Home',
  '/home' = 'Home Calendar',

  // CHAT URLS
  '/chat' = 'Chat',

  // TEAMS URLS
  '/team' = 'Team',

  //CUSTOMER URLS
  '/customers' = 'Customers',

  //PUBLIC ROUTES
  '/checklists' = 'Checklists',
  '/checklists/:id' = 'Checklist',
  '/intercom' = 'Intercom',
  '/calendly' = 'Calendly',
  '/login' = 'Login',
  '/signup' = 'Signup',

  //INTEGRATIONS URLS
  '/integrations' = 'Integrations',

  //BOOKING PUBLIC URLS
  '/booking-site/' = 'Booking Site',
  '/booking-site/iframe/' = 'Booking Site Iframe',
  '/booking-site-settings' = 'Booking Site Settings',
}

export enum RedirectTypeEnum {
  NOTIFICATION = 'NotificationRedirect',
  DEFAULT = 'DefaultRedirect',
}
