import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { TkTaskWrapperSubItemTypes } from '@components/TkDrawerComponents/TkTaskWrapperItem/types';
import { TkTaskWrapperItem, TkTypography } from '@components/index';
import { formatValueToCurrency } from '@helpers/index';

interface TkDrawerServiceDetails {
  type?: TkTaskWrapperSubItemTypes;
  price: number | string;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const TkDrawerServiceDetails = ({
  type,
  price,
  style,
  onClick,
}: TkDrawerServiceDetails): JSX.Element => {
  const { t } = useTranslation();

  const priceOffer =
    typeof price === 'number' ? formatValueToCurrency(price) : price;

  return (
    <TkTaskWrapperItem
      type={type}
      style={style}
      innerStyle={{ maxWidth: '50%' }}
      Actions={
        <TkTypography fontSize={16}>
          <Trans
            i18nKey="estimatedPriceWithValue"
            ns="offers"
            values={{
              price: priceOffer,
            }}
          >
            <strong style={{ marginLeft: 5 }} />
          </Trans>
        </TkTypography>
      }
      actionsStyle={{ maxWidth: '50%' }}
      onClick={onClick}
      icon="fa fa-list-alt"
      iconStyle={{ marginRight: 10 }}
    >
      <TkTypography fontSize={16}>
        {t('serviceDetails', { ns: 'offers' })}
      </TkTypography>
    </TkTaskWrapperItem>
  );
};

export default TkDrawerServiceDetails;
