import { ApiResultsKind } from '@consts/index';
import { Camelize } from '@helpers/index';
import {
  MemberStatus,
  ChatMemberStatusInput,
  ChatMemberStatusResult,
} from 'types/chatTypes';
import api from './api';

const fetchMemberStatus = ({
  accountId,
  memberId,
}: ChatMemberStatusInput): Promise<ChatMemberStatusResult> =>
  api
    .get(
      `account/${accountId}/get-teammates-status?assigned_user_id=${memberId}`
    )
    .then((response) => ({
      kind: ApiResultsKind.OK,
      memberStatus: Camelize<MemberStatus>(response.data),
    }))
    .catch((e) => {
      console.error(e);
      return {
        kind: ApiResultsKind.ERROR,
        memberStatus: null,
      };
    });

export { fetchMemberStatus };
