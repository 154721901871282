import app from './app';
import availability from './availability';
import blockResponsive from './blockResponsive';
import bookingWidget from './bookingWidget';
import calendar from './calendar';
import chat from './chat';
import common from './common';
import customers from './customers';
import dashboard from './dashboard';
import dates from './dates';
import days from './days';
import daysOnboarding from './daysOnboarding';
import demoCall from './demoCall';
import events from './events';
import integrationsPage from './integrationsPage';
import modalAlert from './modalAlert';
import notifications from './notifications';
import offers from './offers';
import phoneVerification from './phoneVerification';
import profile from './profile';
import teamsPage from './teamsPage';
import unavailableFeature from './unavailableFeature';
import webOnboarding from './webOnboarding';

export default {
  app,
  availability,
  blockResponsive,
  bookingWidget,
  calendar,
  chat,
  common,
  customers,
  dates,
  days,
  daysOnboarding,
  demoCall,
  events,
  integrationsPage,
  modalAlert,
  notifications,
  offers,
  phoneVerification,
  profile,
  teamsPage,
  unavailableFeature,
  webOnboarding,
  dashboard,
};
