import { RecoilRoot } from 'recoil';

import IntercomChatProvider from './IntercomChatProvider';
import ReactQueryClientProvider from './ReactQueryClientProvider';
import {
  Mui4StyleProvider,
  Mui5StyleProvider,
  StyledComponentsThemeProvider,
  GlobalStylesProvider,
} from './StyleProviders/index';
import {
  NotificationsProvider,
  AuthProvider,
  ModalProvider,
  SearchProvider,
  LoadingProvider,
  TkStyleProvider,
  TkGoogleReCaptchaContext,
  MixpanelProvider,
} from '@contexts/index';
import ReactErrorBoundary from '@errors/ReactErrorBoundary';
import { combine } from '@helpers/index';

const providers = [
  ReactErrorBoundary,
  TkGoogleReCaptchaContext,
  RecoilRoot,
  MixpanelProvider,
  TkStyleProvider,
  Mui4StyleProvider,
  Mui5StyleProvider,
  StyledComponentsThemeProvider,
  GlobalStylesProvider,
  ReactQueryClientProvider,
  NotificationsProvider,
  AuthProvider,
  IntercomChatProvider,
  ModalProvider,
  SearchProvider,
  LoadingProvider,
];

export const AppContextProvider = combine(...providers);
