export default {
  ok: 'OK',
  apply: 'Apply',
  edit: 'Edit',
  task: 'Task',
  next: 'Next',
  skip: 'Skip',
  back: 'Back',
  city: 'City',
  two_more: '2 more',
  andXMore: 'And \n {{count}} more',
  state: 'State',
  connect: 'Connect',
  delete: 'Delete',
  deleteX: 'Delete {{item}}',
  create: 'Create',
  cancel: 'Cancel',
  close: 'Close',
  contact: 'Contact',
  address: 'Address',
  publish: 'Publish',
  discard: 'Discard',
  customer: 'Customer',
  preview: 'Preview',
  loading: 'Loading',
  lets_go: "Let's Go",
  greet: 'Overriding',
  zip_code: 'Zip Code',
  location: 'Location',
  locations: 'Locations',
  previous: 'Previous',
  apt_unit: 'Apt. / Unit',
  profile_url: 'Profile URL',
  get_started: 'Get started',
  learn_more: 'Learn more',
  phone_number: 'Phone Number',
  save_changes: 'Save changes',
  send_message: 'Send a message',
  email_address: 'Email Address',
  social_networks: 'Social Networks',
  defaultError: 'Something went wrong, please try again later.',
  emailPlaceholder: 'name@email.com',
  select_all: 'Select All',
  select_all_plural: 'Unselect All',
  select_crews: 'Select Crews',
  select_teammates: 'Select Teammates',
  type_phone_number: 'Type a phone number',
  please_select_a_time: 'Please select a time',
  apple: 'Apple',
  request: 'Request',
  couldnt_find_your_address: "Couldn't find your address?",
  changes_successfully_saved: 'Changes successfully saved',
  reject: 'Reject',
  accept: 'Accept',
  notes: 'Notes',
  access_code: 'Access Code',
  apartmentNumber: 'Unit #, Building Name, etc.',
  guidance_notes: 'Guidance Notes',
  teammates: 'Teammates',
  guidance_images: 'Guidance Images',
  not_reported: 'Not Reported',
  no_notes: 'No Notes',
  no_images: 'No Images',
  no_customer: 'No Customer',
  start_by: 'Start by',
  finish_by: 'Finish by',
  undo: 'Undo',
  assign: 'Assign',
  phoneNumber: 'Phone number',
  noLocationSet: 'No Location set',
  noLocation: 'No Location',
  checklists: 'Checklist(s)',
  items: 'Items',
  sendMeTheApp: 'Text me the app!',
  logout: 'Log out',
  remindMeLater: 'Remind me later',
  enabled: 'Enabled',
  disabled: 'Disabled',
  didNotReceiveCodeResend: 'Didn’t receive a code? <0>Resend</0>',
  isNotYourNumberChangeIt: 'Is this not your number? <0>Change It</0>',
  codeResentSuccessfully: 'Code resent successfully',
  turnoverBnB: 'Turno',
  moveoutCom: 'Moveout.com',
  download: 'Download',
  downloadItem: 'Download {{item}}',
  downloading: 'Downloading',
  downloadXItems: 'Download {{count}} {{item}}',
  xItems: '{{number}} items',
  xOfYDone: '{{x}}/{{y}} done',
  image: 'Image',
  images: 'Images',
  done: 'Done',
  completed: 'Completed',
  notCompleted: 'Not Completed',
  notStarted: 'Not Started',
  unassigned: 'Unassigned',
  inProgress: 'In Progress',
  finished: 'Finished',
  started: 'Started',
  status: 'Status',
  unselect_all: 'Unselect All',
  noItems: 'No Items',
  day: 'Day',
  days: 'Day(s)',
  every: 'Every',
  weeksOn: 'Week(s) on',
  yearsIn: 'Year(s) in',
  each: 'Each',
  onThe: 'On The',
  email: 'Email',
  toDo: 'To-do',
  today: 'Today',
  yesterday: 'Yesterday',
  now: 'Now',
  update: 'Update',
  weekdays: {
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  periodInterval: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    yearly: 'Yearly',
  },
  positionInterval: {
    first: 'First',
    second: 'Second',
    third: 'Third',
    fourth: 'Fourth',
    fifth: 'Fifth',
    last: 'Last',
  },
  // easy development use
  notAvailable: 'Not Available',
  enabledBool: '$t(common:enabled)',
  enabledBool_plural: '$t(common:disabled)',
  errorDataUnavailable: 'Data is not available',
  date: 'Date',
  to: 'To',
  info: 'Info',
  dontSend: "Don't Send",
  copyLink: 'Copy link',
  linkCopied: 'URL copied to your clipboard!',
  copyCode: 'Copy code',
  codeCopied: 'Code copied to your clipboard!',
  itemsCount: '{{count}} item',
  itemsCount_plural: '{{count}} items',
  placeholders: {
    name: 'Add a name',
    address: 'Add an address',
    lastName: 'Add a last name',
    email: 'email@email.com',
    phone_number: 'Add a phone number',
    access_code: 'Add Access code if needed',
    location_alias: 'Add a name to the location',
    add_notes:
      'Add notes for any special information your employees may need to know.',
  },
  googleAddressHint: 'Please use Google’s autocomplete to insert your address',
  locationAlias: 'Location Alias',
  locationAddress: 'Location Address',
  access: 'Access',
  pleaseAddAnAddress: 'Please add an address',

  maybe_later: 'Maybe later',
  got_it: 'Got it!',
  send: 'Send',
  about: 'About',
  street_address: 'Street Address',
  you: 'You',
  message_all_teammates_assigned: 'Message all teammates assigned',
  no: 'No',
  customers: 'Customers',
  month: 'Month',
  week: 'Week',
  all: 'All',
  settings: 'Settings',
  save: 'Save',
  remove: 'Remove',
  selectACustomer: 'Select a customer',
  noTasks: 'No tasks',
  search: 'Search',
  new: 'New',
  invoice: 'Invoice',
  saved: 'Saved',
  role: 'Role',
  permissions: 'Permissions',
  name: 'Name',
  password_hint_too_small: 'Must be at least 8 characters',
};
