import styled, { css } from 'styled-components';

import { FormControl } from '@mui/material';

import { getColorFromTheme } from '@helpers/index';

export const TkFormControl = styled(FormControl)`
  ${(props) =>
    props.variant === 'outlined' &&
    css`
      height: 36px;
      overflow: hidden;
      .MuiOutlinedInput-root {
        fieldset {
          border-color: ${(props) =>
            getColorFromTheme(props.theme.palette, 'primary')};
        }
        background-color: #fff;
        color: ${(props) => getColorFromTheme(props.theme.palette, 'primary')};
        height: 36px;
      }
    `};
`;
