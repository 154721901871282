export default {
  active: 'Active',
  allDoneForToday: 'All done for today',
  askedToBeRemoved_plural: '{{name}} asked to be removed from {{count}} tasks',
  askedToBeRemoved: '{{name}} asked to be removed from a task',
  completed: 'Completed',
  customer: 'Customer',
  dontShowThisAgain: "Don't show this again",
  fullSize: 'Full size',
  getPersonalizedHelp: 'Get personalized help to get started',
  inactive: 'Inactive',
  inProgress: 'In progress',
  inviteTeammates: 'Invite teammates',
  invoice: 'Invoice',
  latestMessages: 'Latest Messages',
  mediumSize: 'Medium Size',
  members: 'Members',
  new: 'New',
  newTask: 'Next task',
  nextAvailableDay: 'Next available day',
  noDaysAvailable: 'No days available',
  noTasksForToday: 'There are no tasks for today',
  noTasksForTomorrow: 'There are no tasks for tomorrow',
  noTasksToday: 'No tasks today',
  pending: 'Pending',
  removeModule: 'Remove module',
  requests: 'Requests',
  scheduleDemoCall: 'Schedule a demo call',
  seeAllMessages: 'See all messages',
  seeAllTeammates: 'See all teammates',
  sendMessage: 'Send a message',
  status: 'Status',
  task: 'Task',
  taskNotCompleted_plural: '{{count}} tasks not completed',
  taskNotCompleted: 'Task not completed',
  taskNotStarted_plural: '{{count}} tasks not started',
  taskNotStarted: 'Task not started',
  tellMeMore: 'Tell me more',
  today: 'Today',
  todaysTeamStatus: "Today's team status",
  todo: 'To-do',
  tomorrow: 'Tomorrow',
  turnoTeaserDescription:
    'Automatically bring your Turno projects and manage them in Taskbird',
  unassignedTask_plural: '{{count}} unassigned tasks in the next 7 days',
  unassignedTask: '{{count}} unassigned task in the next 7 days',
  unavailable: 'Unavailable',
  unavailableToday: 'Unavailable today',
  welcome: 'Welcome {{name}}',
  emptyStates: {
    requireAttention:
      'It seems quiet today! The things that you should take care of will show up here.',
    teammateStatusAdminTitle: 'You are the only one here.',
    teammateStatusAdminDescription:
      "Let's invite your teammates so you can work together. Here you will see their workload for today.",
    teammateStatusActive: 'There are no active teammates today',
    teammateStatusInactive: 'There are no inactive teammates today',
    allTeammatesAvailable: 'All your teammates are available today',
    noMessagesOnChat: 'No new messages for you.',
    noTeammatesOnChat:
      "Hey! You can't chat if there is nobody else here. Let's invite your teammates.",
  },
  teammateStatus: {
    noUpcomingTasks: 'No upcoming tasks',
    dateAtTime: '{{date}} at {{time}}',
    tomorrowAt: 'Tomorrow at {{time}}',
    nextTaskStartAtTime: 'Next Task start at {{time}}',
    nextTaskStartTomorrow: 'Next Task start tomorrow at ${time}',
    nextTaskStartAtDateTime: 'Next task {{date}} at {{time}}',
    inProgressTasks: '{{amount}} tasks in progress',
    inProgressSinceTime: 'Task in progress since {{time}}',
    inProgressSinceDateTime: 'Task in progress since {{date}} at {{Time}}',
  },
};
