import React from 'react';

import {
  Container,
  ResourcesContainer,
  SocialMediaContainer,
  FooterLink,
  SocialMediaLinks,
  TkStyledIcon,
} from './styles';

import { WEB_APP_BASE_URI, MARKETING_SITE_BASE_URI } from '../../consts';

import TkTypography from '../TkTypography';
import { DateTime } from 'luxon';

const TkFooter: React.FC = () => {
  const currentYear = DateTime.now();

  return (
    <Container>
      <ResourcesContainer>
        <TkTypography
          mb={2}
          component="span"
          color="white"
          variant="body1"
          fontWeight="bold"
          fontFamily="muli"
        >
          Resources
        </TkTypography>
        <FooterLink href={`${WEB_APP_BASE_URI}/checklists/`}>
          <TkTypography component="span" color="white" variant="body2">
            Cleaning Company Checklists
          </TkTypography>
        </FooterLink>
        <FooterLink href={`${MARKETING_SITE_BASE_URI}/blog/`}>
          <TkTypography component="span" color="white" variant="body2">
            Blog
          </TkTypography>
        </FooterLink>
        <FooterLink href={`${MARKETING_SITE_BASE_URI}/privacy/`}>
          <TkTypography component="span" color="white" variant="body2">
            Privacy Policy
          </TkTypography>
        </FooterLink>
      </ResourcesContainer>
      <SocialMediaContainer>
        <TkTypography
          mb={2}
          component="span"
          color="white"
          variant="body1"
          fontWeight="bold"
          fontFamily="muli"
        >
          Follow Us
        </TkTypography>
        <SocialMediaLinks>
          <a href="https://facebook.com/TaskbirdApp">
            <TkStyledIcon className="fab fa-facebook-square" />
          </a>
          <a href="https://instagram.com/taskbirdapp">
            <TkStyledIcon className="fab fa-instagram" />
          </a>
          <a href="https://twitter.com/taskbirdapp">
            <TkStyledIcon className="fab fa-twitter" />
          </a>
        </SocialMediaLinks>
        <TkTypography mt={4} component="span" color="white" variant="body2">
          © {currentYear.year} Taskbird. All rights reserved.
        </TkTypography>
      </SocialMediaContainer>
    </Container>
  );
};

export default TkFooter;
