import unavailableFeature from './unavailableFeature';
import phoneVerification from './phoneVerification';
import integrationsPage from './integrationsPage';
import webOnboarding from './webOnboarding';
import modalAlert from './modalAlert';
import teamsPage from './teamsPage';
import demoCall from './demoCall';
import common from './common';
import dates from './dates';
import app from './app';

export default {
  unavailableFeature,
  phoneVerification,
  integrationsPage,
  webOnboarding,
  modalAlert,
  teamsPage,
  demoCall,
  common,
  dates,
  app,
};
