import React, { useContext, useState } from 'react';
import LoadingContext from './index';
import { CircularProgress } from '@material-ui/core';
import { AbsoluteBackdrop } from './styles';

export const LoadingProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleShowLoading = (toggle: boolean) => {
    setOpen(toggle);
  };

  const hooks: ILoadingHook = {
    showLoading: open,
    toggleShowLoading,
  };

  return (
    <LoadingContext.Provider value={hooks}>
      <AbsoluteBackdrop open={open}>
        <CircularProgress color="inherit" />
      </AbsoluteBackdrop>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = (): ILoadingHook => useContext(LoadingContext);
