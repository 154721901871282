import React from 'react';

import { ProjectOffer } from 'types/projectOffer.type';

import { Grid } from '@mui/material';

import { TkTaskWrapperItem } from '../styles';
import { TkTags } from '@components/index';

interface IOfferDrawerTags {
  offer: ProjectOffer;
}

const OfferDrawerTags = ({ offer }: IOfferDrawerTags): JSX.Element => {
  const guestArrivesSameDay =
    offer.projectOfferSystemNotes?.guestArrivesSameDay;

  if (!offer.platform) {
    return;
  }

  return (
    <TkTaskWrapperItem padding="0 1rem">
      <Grid container direction="row" justifyContent="center">
        <TkTags type={offer.platform} />
        {guestArrivesSameDay ? <TkTags type="guestArrivesSameDay" /> : null}
      </Grid>
    </TkTaskWrapperItem>
  );
};

export default OfferDrawerTags;
