import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Box, CircularProgress } from '@mui/material';

import { TkTaskWrapperItem as TkTaskItem } from '@components/TkDrawerComponents';
import { TkTypography } from '@components/index';
import { useProjectAdditionalInfo } from '@hooks/index';
import { getUserAccountId } from '@recoilData/index';

type TkBookingProjectAdditionalInfoItemProps = {
  selectedEvent: IEvent;
};

const TkBookingProjectAdditionalInfoItem = ({
  selectedEvent,
}: TkBookingProjectAdditionalInfoItemProps): JSX.Element => {
  const { t } = useTranslation();
  const accountId = useRecoilValue(getUserAccountId);

  const {
    data: bookingProjectAdditionalInfo,
    isLoading: isLoadingBookingProjectAdditionalInfo,
    isSuccess: isSuccessBookingProjectAdditionalInfo,
  } = useProjectAdditionalInfo(accountId, selectedEvent);

  if (isLoadingBookingProjectAdditionalInfo) {
    return <CircularProgress />;
  }

  return (
    <TkTaskItem
      Actions={
        <TkTypography fontSize={16} fontWeight="bold">
          {isSuccessBookingProjectAdditionalInfo &&
            bookingProjectAdditionalInfo?.data?.length > 1 &&
            t('moreXQuestions', {
              ns: 'offers',
              count: bookingProjectAdditionalInfo?.data?.length - 1,
            })}
        </TkTypography>
      }
      style={{ marginTop: '5px' }}
    >
      <Box display="flex" alignItems="center">
        <TkTypography
          fontSize={16}
          fontWeight="bold"
          style={{
            width: '70%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {bookingProjectAdditionalInfo?.data[0]?.question}
        </TkTypography>
        <TkTypography
          as="span"
          fontSize={16}
          fontWeight="normal"
          marginLeft="5px"
        >
          {bookingProjectAdditionalInfo?.data[0]?.answer}
        </TkTypography>
      </Box>
    </TkTaskItem>
  );
};

export default TkBookingProjectAdditionalInfoItem;
