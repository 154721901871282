import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FormControlLabel, Grid } from '@mui/material';

import {
  ModalContent,
  ModalActions,
  TextModal,
} from '@components/Drawers/TkEditTaskDrawer/components/styles';
import {
  TkTypography,
  TkIcon,
  DynamicButton,
  TkCheckBox,
} from '@components/index';
import { LocalStorage } from '@consts/index';
import { useModal } from '@contexts/index';

interface Settings {
  handleSuccessModal: () => void;
}

const TaskInPastModal = ({ handleSuccessModal }: Settings): JSX.Element => {
  const { t } = useTranslation(['events']);
  const { handleCloseModal } = useModal();
  const [checked, setChecked] = useState<boolean>(false);

  const handleCheckChange = () => {
    setChecked((prevState) => !prevState);
    localStorage.setItem(
      LocalStorage.CREATE_PAST_PROJECTS,
      (!checked).toString()
    );
  };

  return (
    <>
      <ModalContent width="440px">
        <div style={{ width: '100%' }}>
          <Grid container alignItems="center" justifyContent="center">
            <TkIcon
              iconSize={72}
              color="#1565C0"
              className="fal fa-info-circle"
            />
          </Grid>
          <TextModal>
            <TkTypography color="#011F41">
              {t('tkModalAlertTaskInPast', {
                ns: 'modalAlert',
              })}
            </TkTypography>
          </TextModal>
          <Grid container>
            <FormControlLabel
              control={
                <TkCheckBox
                  checked={checked}
                  name="check-task-in-past"
                  onChange={handleCheckChange}
                />
              }
              label={t('alerts.doNotShowAnymore', {
                ns: 'modalAlert',
              })}
            />
          </Grid>
        </div>
      </ModalContent>
      <ModalActions>
        <Grid container>
          <Grid item xs={6}>
            <DynamicButton color="primary" onClick={handleCloseModal}>
              {t('editTaskDetails')}
            </DynamicButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              onClick={handleSuccessModal}
              disableElevation
            >
              {t('ok', { ns: 'common' })}
            </DynamicButton>
          </Grid>
        </Grid>
      </ModalActions>
    </>
  );
};

export default TaskInPastModal;
