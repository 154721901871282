import React from 'react';

import { IntercomProvider } from 'react-use-intercom';

import { INTERCOM_APP_ID } from '@consts/index';

const IntercomChatProvider = ({ children }): JSX.Element => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>{children}</IntercomProvider>
  );
};

export default IntercomChatProvider;
