import React, { useEffect, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import {
  TkProjectOfferWarningModal,
  TkWebOnboarding,
  TkPhoneVerification,
} from '../components/index';
import { getProjectOfferWarningStatus } from '../helpers';
import { TkBookingGetStartModal } from '@components/TkBookingWidget/TkBookingGetStartModal';
import useKeyboardShortcut from '@components/TkBookingWidget/hooks/useKeyboardShortcut';
import { TkDemoCallModal } from '@components/index';
import { useAuth, useModal } from '@contexts/index';
import { useAccountProjectOffers } from '@features/projectOffers/hooks';
import { useUserSettings } from '@hooks/index';
import {
  showDemoCall,
  getUserAccountId,
  showProjectOfferWarning as showProjectOfferWarningRecoil,
} from '@recoilData/index';

export const useFirstLoginModals = (): void => {
  //Modal Functions
  const [isModalSet, setIsModalSet] = useState(false);
  const { addBulkModalToQueue, setupModal } = useModal();

  //Account Props
  const { userInfo } = useAuth();
  const demoCall = useRecoilValue(showDemoCall);
  const [showProjectOfferWarning, setShowProjectOfferWarning] = useRecoilState(
    showProjectOfferWarningRecoil
  );
  const accountId = useRecoilValue(getUserAccountId);
  const { userSettings, projectOfferWarning } = useUserSettings();

  //Event Related Functions
  const { projectOffers } = useAccountProjectOffers();
  const projectOffersCount = projectOffers
    ? Object.entries(projectOffers)
        ?.map((project) => project[1].map((p) => p.id))
        .flat().length
    : 0;

  //Modals useEffect
  useEffect(() => {
    const modalArray: ModalQueueItem[] = [];

    if (userSettings.show_onboarding) {
      modalArray.push({
        /** @ts-ignore */
        modal: <TkWebOnboarding />,
        modalId: 'webOnboardingModal',
        options: {
          disableBackdropClick: true,
        },
      });
    }

    if (demoCall) {
      modalArray.push({
        modalId: 'demoCallModal',
        modal: <TkDemoCallModal type="welcomeCall" />,
        options: {
          disableBackdropClick: true,
        },
      });
    }

    if (
      userInfo.user.phone_validation_required &&
      userInfo.user.phone_validated_at === null &&
      process.env.NODE_ENV === 'production'
    ) {
      modalArray.push({
        /** @ts-ignore */
        modal: <TkPhoneVerification />,
        modalId: 'phoneVerificationModal',
        options: {
          disableBackdropClick: true,
        },
      });
    }

    if (
      projectOffersCount > 0 &&
      showProjectOfferWarning &&
      !getProjectOfferWarningStatus(projectOfferWarning, Number(accountId))
    ) {
      modalArray.push({
        modal: (
          <TkProjectOfferWarningModal projectOffersCount={projectOffersCount} />
        ),
        modalId: 'projectOfferWarningModal',
        options: {
          disableBackdropClick: true,
        },
      });
    }
    if (!showProjectOfferWarning) {
      setShowProjectOfferWarning(true);
    }

    if (userSettings.show_booking_onboarding) {
      modalArray.push({
        /** @ts-ignore */
        modal: <TkBookingGetStartModal />,
        modalId: 'bookingOnboardingModal',
        options: {
          disableBackdropClick: true,
        },
      });
    }

    if (!isModalSet) addBulkModalToQueue(modalArray);

    setIsModalSet(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings]);

  //Booking Onboard Function
  const handleOpenBookingOnboarding = () => {
    setupModal({
      content: <TkBookingGetStartModal />,
    });
  };

  //Booking Onboard key bind
  const keysOnboard = ['Shift', 'O'];
  useKeyboardShortcut(keysOnboard, handleOpenBookingOnboarding);
};
