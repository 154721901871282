import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Divider, MenuItem, Menu } from '@mui/material';

import MenuNotifications from './MenuNotifications';
import {
  ScTkTopbarRightActions,
  ScTkTopBarUser,
  TopBarWrapper,
} from './styles';
import logo from '../../images/TKTopBar/Taskbird-horizontal-dark.svg';
import TkAppBar from '../TKAppbar';
import Avatar from '../TKAvatar';
import TKBellNotification from '../TKBellNotification';
import { logoContent as LogoContent } from '../TKLogoContent';
import { TkChatMessages } from '@components/index';
import { useAuth } from '@contexts/AuthContext/AuthProvider';
import { useNotifications } from '@contexts/NotificationContext/NotificationProvider';
import { useDrawer } from '@hooks/drawer';

interface TopBarMainProps extends TopbarBaseProps {
  actionableButtons?: React.ReactNode;
  children?: React.ReactNode;
}

const Topbar = ({
  children,
  openSideBar,
  actionableButtons,
}: TopBarMainProps): JSX.Element => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { logout } = useAuth();
  const {
    callDrawer,
    types: { MainDrawersTypes },
  } = useDrawer();
  const { notificationsTotalUnread } = useNotifications();

  const styleLogout = {
    style: {
      color: '#011F41',
      marginLeft: 14,
    },
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAvailability = (e) => {
    e.preventDefault();
    e.stopPropagation();

    callDrawer(
      { main: { id: MainDrawersTypes.AVAILABILITY } },
      {
        showBackdrop: true,
        backdropClickClose: true,
      }
    );
  };

  const handleMenuCloseLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleProfileMenuOpenNotification = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
  };

  const profileMenu = [
    {
      label: 'profile',
      path: '/settings/profile',
      action: () => null,
    },
    {
      label: 'myAvailability',
      path: '#',
      action: handleAvailability,
    },
    {
      label: 'tabs.team',
      path: '/settings/team',
      action: () => null,
    },
    // {
    //   label: 'tabs.notifications',
    //   path: '/settings/notifications',
    //   action: () => null,
    // },
  ];

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{ style: { minWidth: 176 } }}
      style={{ fontFamily: 'Lato' }}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 56, left: window.screen.width - 60 }}
    >
      {profileMenu.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          onClick={item.action}
          style={{ textDecoration: 'none', display: 'block', color: '#011F41' }}
        >
          <MenuItem>{t(item.label, { ns: 'profile' })}</MenuItem>
        </Link>
      ))}
      <Divider style={{ margin: '5px 10px' }} />
      <MenuItem onClick={handleMenuCloseLogout}>
        <FiLogOut color="#011F41" />
        <div style={styleLogout.style}>{t('logout', { ns: 'common' })}</div>
      </MenuItem>
    </Menu>
  );

  return (
    <TopBarWrapper>
      <TkAppBar>
        <LogoContent logo={logo} onClick={openSideBar} />
        {children}
        <ScTkTopbarRightActions>
          {actionableButtons && actionableButtons}
          <ScTkTopBarUser>
            <TKBellNotification
              openMenu={handleProfileMenuOpenNotification}
              notificationsNumber={notificationsTotalUnread}
            />
            <TkChatMessages />
            <Avatar openMenu={handleProfileMenuOpen} />
          </ScTkTopBarUser>
        </ScTkTopbarRightActions>
      </TkAppBar>

      {renderMenu}
      <MenuNotifications
        anchorElNotification={anchorElNotification}
        setAnchorElNotification={setAnchorElNotification}
        notificationsTotalUnread={notificationsTotalUnread}
      />
    </TopBarWrapper>
  );
};

export default Topbar;
