import React, { useMemo } from 'react';

import { DateTime } from 'luxon';
import DatePicker from 'react-datepicker';

import { Skeleton } from '@mui/material';

import { Circle, DatePickersWrapper, GridContainer, GridItem } from '../styles';
import { ScheduleTypeMap } from '@hooks/availability/types';

interface AvailabilityDatePickerProps {
  unavailableDates: Set<number>;
  schedule: ScheduleTypeMap;
  currentMonth: DateTime;
  selectMonth: (month: Date) => void;
  selectedUnavailableDate: (date: DateTime) => void;
  isAvailabilityLoading: boolean;
}

const AvailabilityDatePicker = ({
  unavailableDates,
  schedule,
  selectMonth,
  selectedUnavailableDate,
  isAvailabilityLoading,
  currentMonth,
}: AvailabilityDatePickerProps): JSX.Element => {
  const daysInMonth = useMemo(() => currentMonth.daysInMonth, [currentMonth]);
  const firstDayOfMonth = useMemo(
    () =>
      currentMonth.startOf('month').weekday === 7
        ? 0
        : currentMonth.startOf('month').weekday,
    [currentMonth]
  );

  const Day = ({ date }: { date: number }) => {
    const isAvailable = useMemo(() => {
      let isAvailable = false;

      unavailableDates.forEach((day) => {
        if (day === date + 1 - firstDayOfMonth) {
          isAvailable = true;
        }
      });

      return isAvailable;
    }, [date]);

    if (date < firstDayOfMonth) return <GridItem key={date} empty />;

    const day = currentMonth
      .set({
        day: Number(date + 1 - firstDayOfMonth),
      })
      .startOf('day');

    const daySchedule = schedule.get(day.toFormat('yyyy-MM-dd')) || {
      day: day.day,
      unassignedTasks: 0,
      assignedTasks: 0,
    };

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '48px',
        }}
      >
        <GridItem
          key={date}
          empty={false}
          onClick={() =>
            selectedUnavailableDate(
              DateTime.fromObject({
                day: date + 1 - firstDayOfMonth,
                month: currentMonth.month,
                year: currentMonth.year,
              })
            )
          }
          selected={isAvailable}
        >
          {date + 1 - firstDayOfMonth}
        </GridItem>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '2px',
          }}
        >
          {daySchedule.unassignedTasks > 0 && (
            <Circle type="unassignedTasks">
              {daySchedule.unassignedTasks}
            </Circle>
          )}
          {daySchedule.assignedTasks > 0 && (
            <Circle type="assignedTasks">{daySchedule.assignedTasks}</Circle>
          )}
        </div>
      </div>
    );
  };

  return (
    <DatePickersWrapper>
      {isAvailabilityLoading ? (
        <Skeleton variant="rounded" width="100%" height="400px" />
      ) : (
        <>
          <DatePicker
            onMonthChange={selectMonth}
            onChange={() => null}
            inline
            formatWeekDay={(nameOfDay) => nameOfDay.slice(0, 1)}
            disabledKeyboardNavigation
          />
          <GridContainer>
            {Array.from({ length: daysInMonth + firstDayOfMonth }, (_, i) => (
              <Day date={i} />
            ))}
          </GridContainer>
        </>
      )}
    </DatePickersWrapper>
  );
};

export default AvailabilityDatePicker;
