import { DateTime, Interval } from 'luxon';

const getLocaleTokenWeekFromDate = (date: Date) => {
  let luxonDate = DateTime.fromJSDate(date);
  const offset = date.getTimezoneOffset();

  if (offset > 0) {
    luxonDate = DateTime.fromJSDate(date).plus({ day: 1 });
  }

  const weekday = luxonDate.weekdayLong.toLowerCase();

  return { weekDayToken: `weekdays.${weekday}`, day: luxonDate.day };
};

const selectWeekFromDate = (selectedDate: DateTime, startWeekDayIsSunday: boolean): Interval => {
  const selectedDateIsSunday = selectedDate.weekday === 7;

  let startRangeDate = selectedDate.startOf('week');
  if (startWeekDayIsSunday) {
    startRangeDate = selectedDateIsSunday ? selectedDate : startRangeDate.minus({ days: 1 });
  }

  return Interval.after(startRangeDate, { days: 7 });
}

export { getLocaleTokenWeekFromDate, selectWeekFromDate };
