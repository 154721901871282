import React from 'react';

import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { themeV4 } from '@styles/theme';

const Mui4StyleProvider: React.FC = ({ children }) => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={themeV4}>{children}</ThemeProvider>
    </StylesProvider>
  );
};

export default Mui4StyleProvider;
