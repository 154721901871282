import React from 'react';

import { AppBar } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const UseStyles = makeStyles(() =>
  createStyles({
    sectionDesktop: {
      height: '100%',
      width: '100%',
      background: '#F5F6FB',
      boxShadow: 'none',
      padding: '0 24px',
      position: 'inherit',
    },
    sectionContent: {
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);

const appbar = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const Classes = UseStyles();
  return (
    <AppBar className={Classes.sectionDesktop}>
      <div className={Classes.sectionContent}>{children}</div>
    </AppBar>
  );
};

export default appbar;
