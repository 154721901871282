import api from '@services/api';
import { ApiResultsKind } from '@consts/index';
import {
  FetchProjectByIdRequest,
  FetchProjectByIdRequestResult,
} from 'types/projectMethods.type';

const fetchProjectById = ({
  projectId,
}: FetchProjectByIdRequest): Promise<FetchProjectByIdRequestResult> =>
  api
    .get(`/project/${projectId}`)
    .then((response) => ({
      kind: ApiResultsKind.OK,
      project: response.data,
    }))
    .catch((e) => {

      return {
        kind: ApiResultsKind.ERROR,
        project: null,
      };
    });

export { fetchProjectById };
