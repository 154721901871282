import React from 'react';

import { Container, LogoContent, IconContent } from './styles';
import MenuIcon from '@material-ui/icons/Menu';

interface LogoContent {
  onClick?: () => void;
  logo: string;
  color?: string;
  width?: string;
}

const logoContent = (props: LogoContent): JSX.Element => {
  return (
    <Container onClick={props.onClick} color={props.color} width={props.width}>
      <IconContent color={props.color}>
        <MenuIcon />
      </IconContent>
      <LogoContent>
        <img src={props.logo} alt="Logo" />
      </LogoContent>
    </Container>
  );
};

const logoContentDark = (props: LogoContent): JSX.Element => {
  return (
    <Container onClick={props.onClick} color={props.color} width={props.width}>
      <IconContent color="#f5f6fb">
        <MenuIcon />
      </IconContent>
      <LogoContent>
        <img src={props.logo} alt="Logo" />
      </LogoContent>
    </Container>
  );
};

export { logoContent, logoContentDark };
