import React, { useState, useContext, useMemo } from 'react';

import { useQuery } from 'react-query';

import { NotificationContext } from './NotificationContext';
import { ApiResultsKind } from '@consts/api';
import { useAuth } from '@contexts/index';
import { notificationTypesList } from '@hooks/index';
import DefaultNotificationHandler from '@hooks/notification/DefaultNotificationHandler';
import {
  INotificationItem,
  INotificationProvider,
  NotificationHandler,
  NotificationMeta,
} from '@hooks/notification/types';
import {
  fetchNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from '@services/notificationMethods';

interface NotificationsProviderProps {
  children: React.ReactNode;
}

const NotificationsProvider = ({
  children,
}: NotificationsProviderProps): JSX.Element => {
  const { signed } = useAuth();
  const [notificationsArray, setNotificationsArray] = useState<
    INotificationItem[]
  >([]);
  const NOTIFICATIONS_LIMIT = 10;
  const [currentCount, setCurrentCount] = useState(NOTIFICATIONS_LIMIT);
  const [notificationsTotal, setNotificationsTotal] = useState(50);
  const [notificationsTotalUnread, setNotificationsTotalUnread] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState<
    NotificationMeta | undefined
  >();

  const { refetch: getNotifications } = useQuery(
    ['getNotifications'],
    async () => {
      const { notifications } = await fetchNotifications({
        size: 50,
      });

      setNotificationsArray(notifications);
      setNotificationsTotalUnread(
        notifications.filter((i) => i.read_at === null).length
      );
      setNotificationsTotal(notificationsArray.length);
    },
    {
      enabled: !!signed,
      refetchInterval: 40000,
    }
  );

  const hasMoreNotifications = (currentCount, limit, total) => {
    const startIndex = currentCount + limit;
    return total === 0 || startIndex < total;
  };

  const getMoreOrAllNotifications = () => {
    if (
      hasMoreNotifications(
        currentCount,
        NOTIFICATIONS_LIMIT,
        notificationsTotal
      )
    )
      setCurrentCount((prev) => prev + NOTIFICATIONS_LIMIT);
    else setCurrentCount(notificationsTotal);
  };

  const notificationsLazyLoad = useMemo(() => {
    if (
      hasMoreNotifications(
        currentCount,
        NOTIFICATIONS_LIMIT,
        notificationsTotal
      )
    ) {
      return notificationsArray.slice(0, currentCount);
    }
    return notificationsArray;
  }, [currentCount, notificationsArray, notificationsTotal]);

  const markAsRead = async (id: string) => {
    const { kind } = await markNotificationAsRead({
      id,
    });

    if (kind === ApiResultsKind.OK) setNotificationsTotal((prev) => prev--);
  };

  const markAllAsRead = async () => {
    const { kind } = await markAllNotificationsAsRead();

    if (kind === ApiResultsKind.OK) getNotifications();
  };

  const getNotificationHandler = (
    notificationMetadata: NotificationMeta
  ): NotificationHandler => {
    const notificationParser = notificationTypesList[notificationMetadata.type]
      ? notificationTypesList[
          notificationMetadata.type ?? 'DefaultNotificationHandler'
        ]
      : DefaultNotificationHandler;

    const { getAttributes, getRoute } = notificationParser;

    return { getAttributes, getRoute };
  };

  const resetSelectedNotification = () => {
    setSelectedNotification(undefined);
  };

  const hooks: INotificationProvider = {
    selectedNotification,
    setSelectedNotification,
    loadNotifications: getNotifications,
    markAllAsRead,
    markAsRead,
    getMoreOrAllNotifications,
    notificationsArray: notificationsLazyLoad,
    notificationsTotalUnread,
    setNotificationsTotalUnread,
    getNotificationHandler,
    resetSelectedNotification,
  };

  return (
    <NotificationContext.Provider value={hooks}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotifications = (): INotificationProvider =>
  useContext(NotificationContext);

export { NotificationsProvider, useNotifications };
