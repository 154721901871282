import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Container } from './styles';
import { ModalActions, ModalContent } from '@components/TkModalAlert';
import { DynamicButton, TkTypography } from '@components/index';
import { AppLocationURLs } from '@consts/index';
import { IExternalLogin } from '@contexts/AuthContext/AuthContext';
import { useModal } from '@contexts/index';

interface ITkSocialLoginModal {
  socialPlatform: IExternalLogin;
}

const TkSocialLoginModal = ({
  socialPlatform,
}: ITkSocialLoginModal): JSX.Element => {
  const { t } = useTranslation();
  const { handleCloseModal } = useModal();

  const redirectSignUp = (): void => {
    window.location.href = AppLocationURLs.SIGN_UP;
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  return (
    <>
      <ModalContent>
        <Container direction="column">
          <TkTypography
            fontFamily="Muli"
            fontSize="24px"
            fontWeight="bold"
            textAlign="center"
          >
            <Trans i18nKey="login.socialLoginError">
              <br />
              {{ social: capitalizeFirstLetter(socialPlatform) }}
            </Trans>
          </TkTypography>
          <TkTypography
            fontFamily="Lato"
            fontSize="16px"
            marginTop="20px"
            padding="0 20px"
          >
            {t('login.doYouWantToCreateNewAccountOrTryLogin')}
          </TkTypography>
        </Container>
      </ModalContent>
      <ModalActions>
        <Container flex={1} direction="column" padding="0" alignItems="center">
          <DynamicButton
            bgcolor="primary"
            variant="contained"
            onClick={redirectSignUp}
            style={{ margin: 5, minWidth: '70%' }}
          >
            <TkTypography fontFamily="Lato" fontSize="16px">
              {t('login.createNewAccount')}
            </TkTypography>
          </DynamicButton>
          <DynamicButton
            color="primary"
            onClick={handleCloseModal}
            style={{ margin: 5 }}
          >
            <TkTypography fontFamily="Lato" fontSize="16px">
              {t('login.useDifferentMethod')}
            </TkTypography>
          </DynamicButton>
        </Container>
      </ModalActions>
    </>
  );
};

export default TkSocialLoginModal;
