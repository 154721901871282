import styled from 'styled-components';

import { Grid } from '@mui/material';

import { TkTaskWrapperSubItemTypes } from './types';

export const MainWrapper = styled(Grid)<{
  bgcolor: TkTaskWrapperSubItemTypes;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  user-select: none;

  border: 1px solid rgba(0, 0, 0, 0);
  ${(props) =>
    props.bgcolor === 'selected' &&
    'background-color: rgba(73, 82, 169, 0.1); border: 1px solid #2699fb;'}
  ${(props) =>
    props.bgcolor === 'solid' &&
    'background-color: #011F41; color: #ffffff; border: 1px solid #011F41;'}
  ${(props) =>
    props.bgcolor === 'subitem' &&
    'background-color: rgba(73, 82, 169, 0.1); border: 1px solid rgba(73, 82, 169, 0.1);'}
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
