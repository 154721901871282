import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import camelize from '@helpers/camelize';
import { AccountType, SettingsType } from '@pages/Profile/models';
import { getUserAccountId } from '@recoilData/index';
import api from '@services/api';

export const useAccountSettingsUpdate = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const queryClient = useQueryClient();

  return useMutation(
    (settings: SettingsType) => {
      return api.put(`/account/${accountId}/settings`, settings);
    },
    {
      onMutate: async (data: AccountType) => {
        await queryClient.cancelQueries({ queryKey: ['accountSettings'] });

        const previous: AccountType = queryClient.getQueryData([
          'accountSettings',
          accountId,
        ]);
        const newAccountSettings = queryClient.setQueryData(
          ['accountSettings', accountId],
          (current: AccountType) => {
            return produce(current, (draft) => {
              draft.settings = {
                ...current.settings,
                ...camelize(data),
              };
            });
          }
        );

        return { previous, newAccountSettings };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(['accountSettings', accountId]);
      },
    }
  );
};
