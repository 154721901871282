export default {
  tkModalAlertIntegrated:
    "Esta tarea es de un calendario externo las fechas no pueden ser cambiadas",
  tkModalAlertRepeat:
    "Esta tarea es una instancia de una serie de repeticiones y no puede ser movida a una nueva fecha",
  tkModalAlertTurnoverbnb:
    "Esta tarea es de Turno y el cliente no puede ser editado",
  tkModalAlertInProgress:
    "No puedes editar las fechas de las tareas en marcha o completadas",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Cualquier cambio que hagas en esta tarea no se reflejará en Turno",
  tkModalAlertTaskInPast:
    "Estás creando una tarea en el pasado.",
  alerts: {
    sendNotifications: "¿Enviar notificaciones?",
    doYouWantToNotify: "¿Quieres notificar?",
    doNotShowAnymore: "No volver a mostrar",
    doNotShowAgainAndSendAllNotifications: "No mostrar de nuevo y enviar todas las notificaciones",
  },
  taskTime: 'La hora de inicio de la tarea en Taskbird no puede ser anterior a la hora de inicio que fue programada en Turno.',
  customerAlert: {
    title: '¿Quieres ocultar el nombre de tu cliente?',
    description1: "El nombre de tu cliente no es visible para tus compañeros de equipo. Cuando ves un ícono de ojo <0></0> al lado de alguna información en los detalles de tus tareas, significa que esta información es solamente visible para los administradores.<br/><br/> Puedes elegir la información que deseas compartir con tu equipo desde tu pestaña <1>“Configuración del equipo”</1>.",
    description2: "Cuando ves un ícono de ojo <0></0> al lado de alguna información en los detalles de tus tareas, significa que esta información es solamente visible para los administradores.<br/><br/> Puedes elegir la información que deseas compartir con tu equipo desde tu pestaña <1>“Configuración del equipo”</1>.",
    warning: 'No volver a mostrar este mensaje',
    buttonEditTeamSettings: 'Ir a “Configuración del equipo”',
    skip: 'Omitir',
  },
  deleteTaskFomTurno: {
    title: "Las tareas importadas de Turno no pueden ser eliminadas en Taskbird. Por favor, solicita ser removida/o desde tu aplicación Turno.",
    ok: "OK"
  }
};
