import React from 'react';

import { ProjectOffer } from 'types/projectOffer.type';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Grid, Divider } from '@mui/material';

import { OfferDrawerTags } from '../MicroComponents';
import { ITkExtendedOfferDetailsDrawerEnum } from '../TkExtendedOffersDetailsDrawer';
import {
  TkTaskWrapperItem,
  TkTaskWrapperItemFilled,
  TkTaskWrapperSubItem,
  HorizontalDivider,
} from '../styles';
import { TkDrawerTurnoAdditionalInfo } from '@components/TkDrawerComponents/TkDrawerTurnoAdditionalInfo';
import {
  TkDrawerHeader,
  TkDrawerTaskName,
  TkDrawerTimeInfo,
  TkDrawerCustomerName,
  TkDrawerLocationDetails,
  TkDrawerPrice,
  TkDrawerChecklistListing,
  TkDrawerGuidanceNotes,
  TkDrawerGuidanceImages,
} from '@components/index';

import { getDatetimeFromDate } from '@helpers/index';
import {
  offersDetailsExtendedDrawer,
  selectedOfferFromList,
} from '@recoilData/index';

interface ContentRendersTurnoverBNBProps {
  offer: ProjectOffer;
  handleClose: () => void;
  handleBack: () => void;
}

const TurnoverBNB = ({
  offer,
  handleClose,
  handleBack,
}: ContentRendersTurnoverBNBProps) => {
  const [extendedDrawer, setExtendedDrawer] = useRecoilState(
    offersDetailsExtendedDrawer
  );
  const isOfferSelectedFromList = useRecoilValue<boolean>(
    selectedOfferFromList
  );

  const handleExtendedDrawer = (type: string) => {
    setExtendedDrawer({
      open: extendedDrawer.open && extendedDrawer.type === type ? false : true,
      type,
    });
  };

  return (
    <>
      <TkDrawerHeader
        id={offer.id}
        handlers={{
          handleClose,
          handleBack,
        }}
        dismissIcon="fa fa-times"
        showBackButton={isOfferSelectedFromList}
      />

      <TkDrawerTaskName
        name={offer.title}
        setName={() => null}
        setEdited={() => null}
        displayOnly
      />

      <OfferDrawerTags offer={offer} />

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerTimeInfo
          start={getDatetimeFromDate(offer.start, offer.location.timezone)}
          finish={getDatetimeFromDate(offer.end, offer.location.timezone)}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItemFilled>
        <TkTaskWrapperSubItem>
          <TkDrawerCustomerName customerName={offer.customerName} />
        </TkTaskWrapperSubItem>

        <Grid container style={{ padding: '0 1rem' }}>
          <HorizontalDivider />
        </Grid>

        <TkTaskWrapperSubItem>
          <TkDrawerLocationDetails location={offer.location} />
        </TkTaskWrapperSubItem>
      </TkTaskWrapperItemFilled>

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerPrice
          price={offer.price}
          paymentType={offer.projectOfferPaymentType}
          client={offer.client}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="0 1rem">
        <HorizontalDivider />
      </TkTaskWrapperItem>

      {offer.checklists.length > 0 && (
        <>
          <TkTaskWrapperItem padding="1rem">
            <TkDrawerChecklistListing
              checklists={offer.checklists}
              type="offer"
            />
          </TkTaskWrapperItem>

          <TkTaskWrapperItem padding="0 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
        </>
      )}

      <div style={{ padding: '.25rem 1rem' }}>
        <TkTaskWrapperItem
          margin="0 -1rem"
          padding="0 1rem"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleExtendedDrawer(
              ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_TURNO
            )
          }
          bgcolor={
            extendedDrawer.open &&
            extendedDrawer.type ===
              ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_TURNO
              ? 'selected'
              : ''
          }
        >
          <TkDrawerTurnoAdditionalInfo offer={offer} />
        </TkTaskWrapperItem>
        <Divider sx={{ marginTop: '.25rem' }} />
      </div>

      <TkTaskWrapperItem padding="0 1rem">
        <HorizontalDivider />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerGuidanceNotes notes={offer.notes} />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="0 1rem">
        <HorizontalDivider />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerGuidanceImages images={offer.images} />
      </TkTaskWrapperItem>
    </>
  );
};

export default React.memo(TurnoverBNB);
