import { createContext } from 'react';

import { IUserIntercom } from 'types/intercomUser.type';

import { SessionData, SessionUserAccount } from './types/session';

export interface IUserDataSocialLogin {
  firstName?: string;
  lastName?: string;
  email?: string;
  socialMediaId?: string;
  user?: object;
  socialMediaProvider?: string;
  id_token?: string;
}

export type IExternalLogin = 'APPLE' | 'GOOGLE' | 'FACEBOOK';

export enum IExternalLoginEnum {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
}

export interface IAuthReturnType {
  success: boolean;
  message: string;
  data?: any;
}

export interface ISocialMediaSignUp {
  success: boolean;
  message: string;
  errorMessage: string;
}

export interface IAuthContext {
  signed: boolean;
  error: boolean;
  userInfo: SessionData;
  userIntercom: IUserIntercom;
  setUserPhoneValidated(): void;
  login(email: string, password: string): Promise<IAuthReturnType>;
  createAccount(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptcha: string
  ): Promise<IAuthReturnType>;
  logout(): void;
  socialMediaSignUp(
    userData: IUserDataSocialLogin
  ): Promise<ISocialMediaSignUp>;
  newTeamLogin: (data) => void;
}

export const AuthContext = createContext({} as IAuthContext);
