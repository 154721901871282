import { useState } from 'react';

import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';

import {
  generateDates,
  generateFinalDates,
  generateFormattedDate,
} from './helpers';
import {
  DayType,
  MonthDaysTypeMap,
  ScheduleType,
  ScheduleTypeMap,
  UseAvailability,
  WeekdayType,
} from './types';
import { useAuth, useSnackbar } from '@contexts/index';
import {
  getAvailability,
  getAvailabilityWeekRules,
  getSchedule,
  updateAvailabilityRequest,
} from '@services/availabilityMethods';

export const useAvailability = (): UseAvailability => {
  const { userInfo } = useAuth();
  const { fireTSuccess } = useSnackbar();
  const accountId = userInfo.account.id;
  const userId = userInfo.user.id;
  const [currentMonth, setCurrentMonth] = useState(
    DateTime.now().startOf('month')
  );

  const { data: weeksApi, isLoading: isWeekRulesLoading } = useQuery(
    ['getAvailabilityWeekRules', accountId, userId],
    async () => {
      const { weekDays } = await getAvailabilityWeekRules({
        accountId,
        userId,
      });

      const currentWeeks = new Set<WeekdayType>();

      weekDays.forEach((weekday) => {
        if (!weekdays.has(weekday)) weekdays.add(weekday);
      });

      return new Set(currentWeeks);
    },
    {
      placeholderData: new Set<WeekdayType>([]),
      refetchOnWindowFocus: false,
      onError: (err) => console.error(err),
    }
  );

  const [weekdays, setWeekdays] = useState<Set<WeekdayType>>(weeksApi);
  const [weekdaysReference] = useState(weeksApi);

  const {
    data: monthDaysApi,
    isLoading: isAvailabilityLoading,
    isFetched,
  } = useQuery(
    ['getAvailabilityDays', accountId, userId, currentMonth],
    async () => {
      const { days } = await getAvailability({
        accountId,
        currentMonth: generateFormattedDate(currentMonth, 'yyyy-MM-dd'),
        userId,
      });

      const currentMonMap = monthDays.get(generateFormattedDate(currentMonth));

      Object.keys(days).forEach((day) => {
        const thisDay = DateTime.fromISO(day);

        if (thisDay.month !== currentMonth.month) return;

        const formattedThisDay = thisDay.toFormat('d');
        const weekday = thisDay.weekday === 7 ? 0 : thisDay.weekday;

        if (weekdays.has(weekday) && !weekdaysReference.has(weekday)) {
          currentMonMap.add(formattedThisDay);
          return;
        }
        if (
          (!weekdays.has(weekday) && weekdaysReference.has(weekday)) ||
          days[day].isAvailable
        ) {
          currentMonMap.delete(formattedThisDay);
          return;
        }

        currentMonMap.add(formattedThisDay);
      });

      return new Map([
        [generateFormattedDate(currentMonth), currentMonMap],
      ]) as MonthDaysTypeMap;
    },
    {
      placeholderData: new Map<string, Set<DayType>>([
        [generateFormattedDate(currentMonth), new Set<DayType>()],
      ]),
      refetchOnWindowFocus: false,
      onError: (err) => console.error(err),
    }
  );

  const [monthDays, setMonthDays] = useState<MonthDaysTypeMap>(monthDaysApi);

  const { data: scheduleMonth } = useQuery(
    ['getScheduleMonth', accountId, userId, currentMonth, monthDays],
    async () => {
      const { schedule: scheduleApi } = await getSchedule({
        accountId,
        currentMonth: generateFormattedDate(currentMonth, 'yyyy-MM-dd'),
        userId,
      });

      Object.keys(scheduleApi).forEach((day) => {
        const thisDay = DateTime.fromISO(day);

        if (thisDay.month !== currentMonth.month) return;

        schedule.set(thisDay.toFormat('yyyy-MM-dd'), {
          day: thisDay.day,
          assignedTasks: scheduleApi[day].assignedTasks,
          unassignedTasks: scheduleApi[day].unassignedTasks,
        });
      });

      return new Map([]) as ScheduleTypeMap;
    },
    {
      placeholderData: new Map<string, ScheduleType>([]),
      enabled: isFetched,
      refetchOnWindowFocus: false,
      onError: (err) => console.error(err),
    }
  );

  const [schedule, setSchedule] = useState<ScheduleTypeMap>(scheduleMonth);

  const { mutate: updateAvailability, isLoading: isAvailabilityUpdating } =
    useMutation(
      async () => {
        await updateAvailabilityRequest({
          accountId,
          updatePackage: {
            weekdaysToDisable: Array.from(weekdays),
            weekdaysToEnable: Array.from({ length: 7 }, (_, i) => i).filter(
              (day) => !weekdays.has(day)
            ),
            specificDaysRules: generateFinalDates(weekdays, monthDays),
            userId,
            accountId,
          },
        });
      },
      {
        onSuccess: () => {
          fireTSuccess('availabilityUpdated', { ns: 'availability' });
        },
        onError: (error, variables, context) => {
          console.error(error, variables, context);
        },
      }
    );

  const selectWeek = (weekday: number, checked: boolean) => {
    const weakWeekdays = new Set(weekdays);

    if (weakWeekdays.has(weekday)) {
      weakWeekdays.delete(weekday);
    } else {
      weakWeekdays.add(weekday);
    }

    setWeekdays(weakWeekdays);

    const rangeDate = generateDates(
      weekday,
      currentMonth,
      monthDays.get(generateFormattedDate(currentMonth)) as Set<DayType>,
      checked
    );

    setMonthDays(
      (prev) =>
        new Map([...prev, [generateFormattedDate(currentMonth), rangeDate]])
    );
  };

  const selectDate = (date: DateTime) => {
    const newDate = date.startOf('day');
    const weekdayDate = newDate.weekday === 7 ? 0 : newDate.weekday;

    const currentDays = new Set<DayType>(
      monthDays.get(generateFormattedDate(newDate))
    );

    const formattedDate = newDate.toFormat('d');

    if (currentDays.has(formattedDate)) {
      currentDays.delete(formattedDate);

      if (weekdays.has(weekdayDate)) {
        weekdays.delete(weekdayDate);

        setWeekdays(new Set(weekdays));
      }
    } else {
      currentDays.add(formattedDate);
    }

    setMonthDays(
      (prev) =>
        new Map([...prev, [generateFormattedDate(currentMonth), currentDays]])
    );
  };

  const selectMonth = (month: Date) => {
    const newMonth = DateTime.fromJSDate(month).startOf('month');
    const formattedDate = generateFormattedDate(newMonth);

    setCurrentMonth(newMonth);

    if (monthDays.get(formattedDate)) return;

    setMonthDays(
      (prev) => new Map([...prev, [formattedDate, new Set<DayType>()]])
    );
  };

  const getAvailableDates = (
    dates: Set<DayType>,
    currentMonth: DateTime
  ): Set<number> => {
    const availableDates = new Set<number>();
    if (dates.size === 0) return availableDates;

    dates.forEach((date) => {
      availableDates.add(
        currentMonth
          .set({
            day: Number(date),
          })
          .startOf('day').day
      );
    });

    return availableDates;
  };

  const clearAvailability = () => {
    setMonthDays(new Map([[generateFormattedDate(currentMonth), new Set()]]));
    setWeekdays(new Set());
    setSchedule(new Map());
  };

  return {
    currentMonth,
    schedule,
    unavailableWeekOptions: weekdays,
    unavailableDates: getAvailableDates(
      monthDays.get(generateFormattedDate(currentMonth)) as Set<DayType>,
      currentMonth
    ),

    selectDate,
    selectMonth,
    selectWeek,

    updateAvailability,
    clearAvailability,

    loading: {
      isAvailabilityLoading,
      isWeekRulesLoading: isWeekRulesLoading,
      isAvailabilityUpdating,
    },
  };
};
