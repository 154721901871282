import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  AvailabilityDatePickerCustom as AvailabilityDatePicker,
  RecurrentDays,
} from './components/index';
import { TitleWrapper } from './styles';
import { TkTaskActions, TkTaskWrapper } from '../styles';
import {
  DynamicButton,
  TkTaskWrapperItem,
  TkTypography,
} from '@components/index';
import { useAvailability, useDrawer } from '@hooks/index';

const TkAvailabilityDrawer = (): JSX.Element => {
  const {
    unavailableDates,
    selectDate,
    unavailableWeekOptions,
    selectWeek,
    selectMonth,
    loading,
    schedule,
    updateAvailability,
    currentMonth,
  } = useAvailability();
  const { closeDrawers } = useDrawer();
  const { t } = useTranslation(['availability']);

  const closeDrawer = () => {
    closeDrawers();
  };

  return (
    <>
      <TkTaskWrapper>
        <TitleWrapper>
          <TkTypography
            fontFamily="Muli"
            fontSize="18px"
            fontWeight="bold"
            lineHeight="28px"
          >
            {t('title')}
          </TkTypography>
        </TitleWrapper>
        <TkTaskWrapperItem
          icon="far fa-calendar-times"
          iconStyle={{
            marginRight: 10,
            iconSize: 18,
          }}
          style={{
            margin: '10px 0 10px',
          }}
        >
          <TkTypography fontSize="16px" fontWeight="bold" lineHeight="24px">
            {t('warning')}
          </TkTypography>
        </TkTaskWrapperItem>

        <TkTaskWrapperItem
          style={{
            margin: '15px 0',
          }}
        >
          <TkTypography fontSize="16px" fontWeight="bold" lineHeight="22px">
            {t('recurrentDays')}
          </TkTypography>
        </TkTaskWrapperItem>
        <RecurrentDays
          weekOptionList={unavailableWeekOptions}
          selectedWeekDay={selectWeek}
          isWeekRulesLoading={loading.isWeekRulesLoading}
        />

        <TkTaskWrapperItem
          style={{
            margin: '25px 0 15px',
          }}
        >
          <TkTypography fontSize="16px" fontWeight="bold" lineHeight="22px">
            {t('specificDates')}
          </TkTypography>
        </TkTaskWrapperItem>

        <AvailabilityDatePicker
          schedule={schedule}
          currentMonth={currentMonth}
          unavailableDates={unavailableDates}
          selectMonth={selectMonth}
          selectedUnavailableDate={selectDate}
          isAvailabilityLoading={loading.isAvailabilityLoading}
        />
      </TkTaskWrapper>
      <TkTaskActions>
        <DynamicButton color="primary" onClick={closeDrawer}>
          {t('cancel', { ns: 'common' })}
        </DynamicButton>
        <DynamicButton
          variant="contained"
          color="primary"
          onClick={updateAvailability}
          loading={loading.isAvailabilityUpdating}
        >
          {t('update', { ns: 'common' })}
        </DynamicButton>
      </TkTaskActions>
    </>
  );
};

export default TkAvailabilityDrawer;
