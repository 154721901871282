import React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, CircularProgress } from '@mui/material';

import DynamicButton from '@components/DynamicButton';
import TkIcon from '@components/TkIcon';
import { getHoverColor } from '@helpers/index';
import {
  PaletteProps, //eslint-disable-line
  SpacingProps, //eslint-disable-line
} from '@material-ui/system';

const StyledBrandButton = styled(DynamicButton)<PaletteProps & SpacingProps>`
  display: flex;
  flex: 1;
  height: ${(props) => (props.height ? props.height : '40px')};
  width: 100%;
  min-width: 120px;
  border-radius: 4px;
  font-size: 14px;
  p {
    font-weight: bold;
  }
  font-family: 'Lato';

  @media (max-height: 720px) {
    min-width: 100px;
  }
`;

const StyledFacebookButton = styled(StyledBrandButton)<
  PaletteProps & SpacingProps
>`
  background: #577bc0;
  &:hover {
    background: ${(props) => getHoverColor('#577BC0')};
  }
`;

const StyledAppleButton = styled(StyledBrandButton)<
  PaletteProps & SpacingProps
>`
  background: #000000;
  &:hover {
    background: ${(props) => getHoverColor('#000000')};
  }
`;

const StyledGoogleButton = styled(StyledBrandButton)<
  PaletteProps & SpacingProps
>`
  background: #d94233;
  &:hover {
    background: ${(props) => getHoverColor('#D94233')};
  }
`;

export const FacebookButton: React.FC = (props) => {
  return (
    <StyledFacebookButton
      startIcon={<TkIcon className="fab fa-facebook" color="white" />}
      disableElevation
    >
      Facebook
    </StyledFacebookButton>
  );
};

export const AppleButton: React.FC = (props) => {
  return (
    <StyledAppleButton
      startIcon={<TkIcon className="fab fa-apple" color="white" />}
      disableElevation
    >
      Apple
    </StyledAppleButton>
  );
};

export const GoogleButton: React.FC = (props) => {
  return (
    <StyledGoogleButton
      startIcon={<TkIcon className="fab fa-google" color="white" />}
      disableElevation
    >
      Google
    </StyledGoogleButton>
  );
};

export const AuthButton = ({
  type,
  loading,
}: {
  type: string;
  loading?: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <StyledBrandButton
      variant="contained"
      bgcolor="primary"
      disableElevation
      height="48px"
      type={type}
      disabled={!!loading}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {loading ? (
          <CircularProgress color="inherit" size="1.5rem" />
        ) : (
          t('login.logIn')
        )}
      </Box>
    </StyledBrandButton>
  );
};

export const CreateAccountButton = ({ ...props }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledBrandButton
      variant="contained"
      bgcolor="primary"
      disableElevation
      {...props}
    >
      {props.isLoading ? (
        <CircularProgress color="inherit" size="1.5rem" />
      ) : (
        t('login.createAccount')
      )}
    </StyledBrandButton>
  );
};

export const TextMeButton = styled(StyledBrandButton)<
  PaletteProps & SpacingProps
>`
  height: 36px;
  width: 151px;
  p {
    font-weight: 400;
  }
`;
